import Recruit from '@/views/layouts/SubLayouts/Recruit.vue';
//*********** Hiring */
import Hiring from '@/views/Hiring/Index.vue';
import Candidate from '@/views/Hiring/Candidate/Index.vue';
import CandidateOverview from '@/views/Hiring/Candidate/Overview';
import HiringRequests from '@/views/Hiring/HiringRequests/Index.vue';
import Job from '@/views/Hiring/Job/Index.vue';
import AsmBasePlan from '@/views/Hiring/CandidatePlan/Index.vue';

export default [
    {
      path: 'recruit',
      name: 'Recruit',
      component: Recruit,
      meta: { 
        breadcrumbs: [
            {
            text: 'Trang chủ',
            disabled: false,
            to: {
                name: "Home",
                }
            },
        ],
        icon:{
            icon: "mdi-plus-thick",
            to: ""
        },
      },
      redirect: {name : 'CandidateOverview'},
      children:[
        {
            path: 'overview',
            name: 'CandidateOverview',
            component: CandidateOverview,
            meta:{
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Tổng quan',
                disabled: true,
                to: {
                    name: "CandidateOverview",
                    }
                },
            ],
            }
        },
        {
            path: 'candidate',
            name: 'Candidate',
            component: Candidate,
            meta:{
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Danh sach ứng tuyển',
                disabled: true,
                to: {
                    name: "OvertimeBooking",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
            }
        },
        {
            path: 'jobs',
            name: 'Job',
            component: Job,
            meta: {
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Quản lí công việc',
                disabled: true,
                to: {
                    name: "job",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
            }
        },
        {
            path: 'hiringrequests',
            name: 'HiringRequests',
            component: HiringRequests,
            meta: {
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Yêu cầu tuyển dụng',
                disabled: true,
                to: {
                    name: "hiringrequests",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
            }
        },
        
        {
            path: 'asm-base-plan',
            name: 'AsmBasePlan',
            component: AsmBasePlan,
            meta: {
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Tiêu chí tuyển dụng theo asm',
                disabled: true,
                to: {
                    name: "hiringrequests",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
            }
        },
      ]
    },
    
  ]
