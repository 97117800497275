
import EventBus from '@/components/services/EventBus.js';
import http from '@/components/services/http-common';
import UploadService from "@/components/services/UploadService";
import moment from 'moment/moment';
import AssignmentDialog from '@/views/HR/Assignment/Create';
import WorkHistoryDialog from '../WorkHistoryDialog';
import LeaveEnrollHistoryDialog from '../LeaveEnrollHistory';
import LeaveEnrollDialog from '@/views/HR/LeaveEnroll/Create';
import CreateContractDialog from '@/views/HR/Contract/Create';
import CreateUserDialog from '@/views/HR/User/Create';
import EmployeeTypeDetail from '../EmployeeTypeDetail';

import ListContractDialog from '../ListContract';
export default {
    components:{
        "assignment-dialog": AssignmentDialog,
        "work-history-dialog": WorkHistoryDialog,
        "leaveEnroll-history-dialog": LeaveEnrollHistoryDialog,
        "leave-enroll-dialog":LeaveEnrollDialog,
        "create-contract-dialog": CreateContractDialog,
        "create-user-dialog": CreateUserDialog,
        "list-contract-dialog": ListContractDialog,
        EmployeeTypeDetail
    },
    props:["value", "item"],
    watch:{
        statuses: {
            handler(value){
                this.statusConvert(value);
            }
        },
        dialog: {
            handler(value){
                this.fetchCity();
                this.fetchEducationLevel();
                this.fetchUniversity();
                this.fetchAcademicRank();
                this.fetchEducationMajor();
                this.fetchEthinicity();
                this.fetchReligion();
                this.fetchOrigins();
                this.fetchArea();
                this.fetchBank();
                if(value)
                {
                    if(this.isEdit)
                    {
                        this.fetch().then(()=>{
                            this.fetchDDistrict(this.form.domicileCityCode, true);
                            this.fetchDistrict(this.form.cityCode, true);
                            this.fetchDWard(this.form.domicileDistrictCode, true);
                            this.fetchWard(this.form.districtCode, true);
                        })
                        this.fetchStatusDetail();
                        this.fetchPromoteDetail();
                        this.fetchRotationDetail();
                    }
                }
                else{
                    this.close();
                }
            }
        },
        
        "form.isVerified": {
            handler(val){
                this.globalIsVerified = val;
            }
        },

        // idPlaceCombine:{
        //     handler(val) {
        //         this.form.iDplace = val;
        //     }
        // }
    },
    computed: {
        avatar(){
            if(this.form.avatar)
                return (this.form.avatar.includes("http")?"": process.env.VUE_APP_BASE_URL) + this.form.avatar.replace('wwwroot/', '');
            else
                return '/sample.png';
        },
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        },
        // idPlaceCombine() {
        //     return `${this.idPlaceAuth} - ${this.idPlaceLoc}`;
        // },
        idPlaces() {
            let fixedData = [ "Cục Cảnh sát ĐKQL cư trú và DLQG về dân cư", "Cục Cảnh sát quản lý hành chính về trật tự xã hội"];
            let cityData = this.cities.map(x=>{return x.name});
            return fixedData.concat(cityData);
        }
        
    },
    data() {
        return {
            ROOT_URL: "employees/",
            fetchEmployeeType: false,

            statuses: "O",
            panels:[0,1,2,3, 4],
            isValid: false,
            departmentSearch: null,
            departments: [],
            employeeSearch: null,
            employees: [],
            marriages:[
                {text: "Kết hôn", value: "KH"},
                {text: "Độc thân", value: "DT"},
                {text: "Chưa có thông tin", value: ""},
            ],
            dCities:[],
            dDistricts:[],
            cities:[],
            districts:[],
            wards:[],
            dWards:[],

            educationLevels:[],
            universities:[],
            academicRanks:[],
            educationMajors:[],
            educationStatues: [
                {text: "Tốt nghiệp", value: true},
                {text: "Chưa tốt nghiệp", value: false},
            ],
            ethnicities:[],
            religions:[],
            origins:[],
            banks:[],
            areas:[],
            
            
            genders:[
                {text: "Nam", value: true},
                {text: "Nữ", value: false},
            ],
            globalIsVerified: false,

            idPlaceAuth:null,
            idPlaceLoc:null,
            statusText: "",
            form: {
                status: 1,
                avatar: '',
                //tab1
                name: '',
                gender: null,
                birthDay: moment().format("YYYY-MM-DD"),
                phoneNumber: '',
                mail: '',
                hireDate: moment().format("YYYY-MM-DD"),
                isVerified:false,
                // tab2
                iDnumber: '',
                iDdate: moment("1975-04-30").format("YYYY-MM-DD"),
                iDplace: '',
                marriage: '',
                bankCode:'',
                bankNumber: '',
                ethnicityCode: null,
                religionCode: null,
                originCode: null,
                domicileCityCode: null,
                domicileDistrictCode: null,
                domicileWardCode: null,
                domicileAddress:null,
                cityCode:null,
                districtCode: null,
                wardCode:null,
                address:"",

                // tab3
                educationLevelCode: null,
                universityCode: null,
                academicRankCode: null,
                educationMajorCode: null,
                educationStatus: true,

                departmentTypeName:"",
                departmentName:"",
                teamName:"",
                positionName:"",
                positionStateName:"",
                shiftName:"",

                //tab4
                areaCode:null,
                bfoAccount:"",
                attachmentLink:"",
                employeeType:'',

                //tab5
                isQuit: false,
                isSuspend: false,
                isPregnant: false,
                notAccept: false,
                quitDate: moment("2100-01-01").format("YYYY-MM-DD"),
                endQuitDate: moment("2100-01-01").format("YYYY-MM-DD"),
                quitNote:"",
                employeeReturn:"",
            },
            defaultForm:{
                status:1,
                avatar: '',
                //tab1
                name: '',
                gender: null,
                birthDay: moment().format("YYYY-MM-DD"),
                phoneNumber: '',
                mail: '',
                hireDate: moment().format("YYYY-MM-DD"),
                isVerified:false,
                // tab2
                iDnumber: '',
                iDdate: moment("2100-01-01").format("YYYY-MM-DD"),
                iDplace: '',
                marriage: '',
                bankCode:'',
                bankNumber: '',
                ethnicityCode: null,
                religionCode: null,
                originCode: null,
                domicileCityCode: null,
                domicileDistrictCode: null,
                domicileWardCode: null,
                domicileAddress:null,
                cityCode:null,
                districtCode: null,
                wardCode:null,
                address:"",
                seniority:"",
                originSeniority:"",

                // tab3
                educationLevelCode: null,
                universityCode: null,
                academicRankCode: null,
                educationMajorCode: null,
                educationStatus: true,
                
                departmentTypeName:"",
                departmentName:"",
                positionName:"",
                positionStateName:"",
                shiftName:"",
                teamName:"",

                //tab4
                areaCode:null,
                bfoAccount:"",
                attachmentLink:"",
                employeeType:'',

                //tab5
                isQuit: false,
                isSuspend: false,
                isPregnant: false,
                notAccept: false,
                quitDate: moment("2100-01-01").format("YYYY-MM-DD"),
                endQuitDate: moment("2100-01-01").format("YYYY-MM-DD"),
                quitNote:"",
                employeeReturn:"",
            },

            //assignment Dialog
            assignmentDialog: false,
            workHistoryDialog:false,
            leaveEnrollHistoryDialog:false,
            leaveEnrollDialog:false,
            createContractDialog:false,
            createUserDialog:false,

            listContractDialog:false,
            employee: null,
            statusHistory:[],
            statusHistoryHeaders: [
                { text: 'Mã', value: 'code', width: "7rem"},
                { text: 'Trạng thái', value: 'status', width: "7rem"},
                { text: 'Ngày nghỉ', value: 'date', width: "7rem"},
                { text: 'Ngày bắt đầu làm lại', value: 'endDate', width: "7rem"},
                { text: '', value: 'actions', width: "7rem"},
            ],

            updateStatusHistoryDialog: false,
            deleteStatusHistoryDialog: false,
            UpdateStatusHistoryObj: {
                code: "", 
                status: 0,
                date: null,
                endDate: null,
            },
            DeleteStatusHistoryObj: {},
            LOCK: true,

            statusAction: [],
            statusActionHeaders: [
                { text: 'Thao tác', value: 'action', width: "7rem"},
                { text: 'Mã NV', value: 'updatedBy', width: "7rem"},
                { text: 'Thời gian', value: 'updatedAt', width: "7rem"},
            ],

            detailTab: 0,
            detailItems: ["Trạng thái làm việc", "Vị trí/ca/cột", "Điều chuyển", "Phân loại nhân viên"],

            statusTableHeaders: [
                { text: '', value: 'actions', sortable: false, width: "5rem"},
                { text: 'Mã', value: 'code', sortable: false, width: "7rem"},
                { text: 'Trạng thái', value: 'status', sortable: false, width: "12rem"},
                { text: 'Từ ngày', value: 'fromDate', sortable: false, width: "10rem"},
                { text: 'Đến ngày', value: 'toDate', sortable: false, width: "10rem"},
                { text: 'Nguồn', value: 'source', sortable: false, width: "7rem"},
                { text: 'Tạo vào', value: 'createdAt', sortable: false, width: "7rem"},
                { text: 'Tạo bởi', value: 'createdBy', sortable: false, width: "7rem"},
                { text: 'Sửa vào', value: 'updatedAt', sortable: false, width: "7rem"},
                { text: 'Sửa bởi', value: 'updatedBy', sortable: false, width: "7rem"},
            ],
            statusTableItems: [
            ],
            statusEdit:"",
            statusType:[
                {text: "Hoạt động", value: 1},
                {text: "Tạm nghỉ", value: 2},
                {text: "Thai sản", value: 3},
                {text: "Nghỉ việc", value: 4},
                {text: "Không nhận việc", value: 6}
            ],
            addStatus: false,

            employeeStatusDetailLoading: false,
            employeeStatusDetailForm: {
                status:1,
                fromDate: null,
                toDate:null
            },

            employeeStatusDetailEditForm: {
                status:1,
                fromDate: null,
                toDate:null
            },
            employeeStatusDetailEditFormValid: false,
            deleteStatusDetailCfm: false,

            //section promotion
            promoteTableHeaders: [
                { text: '', value: 'actions', sortable: false, width: "5rem"},
                { text: 'Mã', value: 'id', sortable: false, width: "7rem"},
                { text: 'Vị trí', value: 'positionName', sortable: false, width: "12rem"},
                { text: 'Chức danh', value: 'positionStateName', sortable: false, width: "12rem"},
                { text: 'Ca làm việc', value: 'shiftName', sortable: false, width: "12rem"},
                { text: 'Từ ngày', value: 'promoteDateFrom', sortable: false, width: "10rem"},
                { text: 'Đến ngày', value: 'promoteDateTo', sortable: false, width: "10rem"},
                { text: 'Nguồn', value: 'source', sortable: false, width: "7rem"},
                { text: 'Tạo vào', value: 'createdAt', sortable: false, width: "7rem"},
                { text: 'Tạo bởi', value: 'createdBy', sortable: false, width: "7rem"},
                { text: 'Sửa vào', value: 'updatedAt', sortable: false, width: "7rem"},
                { text: 'Sửa bởi', value: 'updatedBy', sortable: false, width: "7rem"}
            ],
            promoteTableItems: [
            ],
            promoteEdit:"",
            promoteAdd: false,

            employeePromoteDetailLoading: false,
            promoteDetailData:{
                positions:[],
                positionStates:[],
                shifts:[]
            },
            employeePromoteDetailForm: {
                positionCode:"",
                positionStateCode:"",
                shiftCode:"",
                fromDate: null,
                toDate:null
            },

            employeePromoteDetailEditForm: {
                positionCode:"",
                positionStateCode:"",
                shiftCode:"",
                fromDate: null,
                toDate:null
            },
            employeePromoteDetailEditFormValid: false,
            deletePromoteDetailCfm: false,

            //section rotataion
            rotationLoading: false,
            departmentTypes: [],
            teams:[],

            rotationTableHeaders: [
                { text: '', value: 'actions', sortable: false, width: "5rem"},
                { text: 'Mã', value: 'id', sortable: false, width: "7rem"},
                { text: 'Khối', value: 'departmentTypeName', sortable: false, width: "12rem"},
                { text: 'Phòng/Chi nhánh', value: 'departmentName', sortable: false, width: "12rem"},
                { text: 'Từ', value: 'rotationDateFrom', sortable: false, width: "12rem"},
                { text: 'Đến', value: 'rotationDateTo', sortable: false, width: "10rem"},
                { text: 'Tạo vào', value: 'createdAt', sortable: false, width: "10rem"},
                { text: 'Tạo bởi', value: 'createdBy', sortable: false, width: "7rem"},
                { text: 'Cập nhật', value: 'updatedAt', sortable: false, width: "7rem"},
                { text: 'Cập nhật bởi', value: 'updatedBy', sortable: false, width: "7rem"},
            ],
            rotationTableItems: [
            ],
            rotationEdit:"",
            rotationAdd: false,
            rotationUpdate: false,

            employeeRotationDetailLoading: false,
            rotationDetailData:{
                positions:[],
                positionStates:[],
                shifts:[]
            },
            employeeRotationDetailForm: {
                id:"",
                departmentTypeCode:"",
                departmentCode:"",
                teamCode:"",
                rotationDateFrom:"",
                rotationDateTo: ""
            },

            employeeRotationDetailEditForm: {
                code: "",
                departmentTypeCode:"",
                departmentCode:"",
                teamCode:"",
                rotationDateFrom:"",
                rotationDateTo: ""
            },
            employeeRotationDetailEditFormValid: false,
            deleteRotationDetailCfm: false,

            dateValidRules: [
                v => !!v || 'Bạn nhập dữ liệu này',
                v => moment(v, 'DD/MM/YYYY', true).isValid() || 'Định dạng ngày không đúng',
            ],
        }
    },
    methods: {

        chooseAvatar(){
        this.$refs.avatarInput.$refs.input.click()
        },
        
        uploadAvatar(files) {
            let progress = 0;
            UploadService.upload(files, (event) => {
                progress = Math.round((100 * event.loaded) / event.total);
            }, "employees/avatar")
            .then((response) => {
                this.form.avatar = response.data.path;
            })
        },
        fetchEthinicity(val) {
            return http.get("ethnicities").then(res => {
                this.ethnicities = res.data;
            });
        },
        
        fetchReligion(val) {
            return http.get("religions").then(res => {
                this.religions = res.data;
            });
        },
        fetchOrigins(val) {
            return http.get("origins").then(res => {
                this.origins = res.data;
            });
        },
        fetchCity(){
            return http.get('cities/fetch').then(res => {
                this.cities = res.data;
                this.dCities = res.data;
            });
        },
        
        fetchBank(){
            return http.get('banks').then(res => {
                this.banks = res.data;
            });
        },
        fetchDDistrict(val, init =false){
            if(!init){
                this.dDistricts=[];
                this.form.domicileDistrictCode=null;
            }
            return http.get('cities/fetch-district/'+val).then(res => {
                this.dDistricts = res.data;
            });
        },
        fetchDistrict(val, init =false){
            if(!init){
                this.districts=[];
                this.form.districtCode=null;
                this.wards=[];
                this.form.wardCode=null;
            }
            return http.get('cities/fetch-district/'+val).then(res => {
                this.districts = res.data;
            });
        },
        fetchWard(val, init =false){
            if(!init){
                this.wards=[];
                this.form.wardCode=null;
            }
            if(val == null)
                return;
            return http.get('districts/fetch-ward/'+val).then(res => {
                this.wards = res.data;
            });
        },
        fetchDWard(val, init =false){
            if(!init){
                this.dWards=[];
                this.form.domicileWardCode=null;
            }
            if(val == null)
                return;
            return http.get('districts/fetch-ward/'+val).then(res => {
                this.dWards = res.data;
            });
        },
        fetchEducationLevel(){
            return http.get('educationLevels/fetch').then(res=>{
                this.educationLevels = res.data;
            })
        },
        fetchUniversity(){
            return http.get('universities/fetch').then(res=>{
                this.universities = res.data;
            })
        },
        fetchEducationMajor(){
            return http.get('educationMajors/fetch').then(res=>{
                this.educationMajors = res.data;
            })
        },
        fetchAcademicRank(){
            return http.get('academicRanks/fetch').then(res=>{
                this.academicRanks = res.data;
            })
        },
        fetchArea() {
            return http.get("areas/fetch").then(res => {
                this.areas = res.data;
            })
        },
        fetch(){
            return http.get(this.ROOT_URL+this.item.code).then(res => {
                this.form = Object.assign({}, res.data.employee);
                this.statusHistory = res.data.status_history;
                this.statusAction = res.data.status_action;
                this.statusText = this.form.status;
                switch(this.form.status) {
                    case "Hoạt Động": {
                        this.form.status = "1"
                        break;
                    }
                    case "Tạm Nghỉ": {
                        this.form.status = "2"
                        break;
                    }
                    case "Thai Sản": {
                        this.form.status = "3"
                        break;
                    }
                    case "Nghỉ Việc": {
                        this.form.status = "4"
                        break;
                    }
                    case "Ẩn": {
                        this.form.status = "5"
                        break;
                    }
                    
                    case "Cần xác thực": {
                        this.form.status = ""
                        break;
                    }
                }
                // this.splitIdPlace(res.data.employee.iDplace);
            }).then(() => {
                this.fetchRotationDetail()
                this.fetchPromoteDetail()
                this.fetchStatusDetail()
            })
        },

        editStatusHistory(item) {
            this.updateStatusHistoryDialog=!this.updateStatusHistoryDialog;
            let FETCH_URL = `employees/status-history-get/${item.code}`
            
            return http.get(FETCH_URL).then(res => {
                let obj = {
                    code : res.data.item.code,
                    status : res.data.item.status,
                    date : res.data.item.date,
                    endDate : res.data.item.endDate
                };
                this.UpdateStatusHistoryObj = Object.assign({}, obj);
            });
        },

        confDeleteStatusHistory(item)
        {
            this.deleteStatusHistoryDialog=true;
            this.DeleteStatusHistoryObj = item;
        },

        deleteStatusHistory() {
            this.deleteStatusHistoryDialog=!this.deleteStatusHistoryDialog;
            let FETCH_URL = `employees/status-history-delete/${this.DeleteStatusHistoryObj.code}`
            return http.delete(FETCH_URL,);
        },

        updateStatusHistory() {
            let FETCH_URL = `employees/status-history-update/${this.UpdateStatusHistoryObj.code}`
            return http.put(FETCH_URL, this.UpdateStatusHistoryObj);
        },
        saveData() {
            if(!this.LOCK)
            {
                this.$refs.form.validate();
                if(this.isValid)
                {
                    if(!this.isEdit){
                        return http.post(this.ROOT_URL, this.form).then(res =>{
                            this.$emit("fetch");
                            // this.dialog = false;
                        })
                    }else {
                        return http.put(this.ROOT_URL+ this.item.code, this.form).then(res =>{
                            this.$emit("fetch");
                            // this.dialog = false;
                            this.LOCK = true;
                        })
                    }
                }else{
                    this.panels= [0,1,2,3, 4];
                }
            }else {
                let notif = {
                    message: "Bạn chưa mở khóa dữ liệu",
                    icon: 'error',
                    toast:  false,
                }
                EventBus.$emit('hasError', notif);
                // this.$eventBus.$emit('hasError', 'i hear you');
            }
        },
        close(){
            this.panels=[0,1,2,3, 4],
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
            this.form.code = null;
            this.idPlaceAuth ="";
            this.idPlaceLoc ="";
            this.LOCK = true;
        },

        //employeeStatusDetail
        
        // setStatusHistoryDetailDeleteCfm(item){
        //     this.employeeStatusDetailEditForm.code = item.code;
        //     this.deleteStatusDetailCfm = true;
        // },

        setStatusDetailForm(item){
            this.employeeStatusDetailEditForm.code = item.code;
            this.employeeStatusDetailEditForm.status = item.status;
            this.employeeStatusDetailEditForm.fromDate = item.fromDate;
            this.employeeStatusDetailEditForm.toDate = item.toDate;
        },

        fetchStatusDetail () {
            return http.get("EmployeeStatusDetails", {
                params:{
                    employeeCode: this.item.code
                }
            }).then(res => {
                this.statusTableItems = res.data;
            });
        },
        
        openCreateStatusDialog(){
            this.addStatus = !this.addStatus;
        },
        postStatusDetail() {
            this.employeeStatusDetailLoading = true;
            let postDate = {
                status:1,
                fromDate: null,
                toDate:null
            };

            postDate.fromDate = moment(this.employeeStatusDetailForm.fromDate, "DD/MM/YYYY").format("YYYY-MM-DD");
            postDate.toDate = moment(this.employeeStatusDetailForm.toDate, "DD/MM/YYYY").format("YYYY-MM-DD");
            postDate.status = this.employeeStatusDetailForm.status;

            return http.post("EmployeeStatusDetails/"+this.item.code, postDate).then(res => {
                this.employeeStatusDetailLoading = false;
                this.fetchStatusDetail();
            }).finally(()=> {
                this.employeeStatusDetailLoading = false;
                this.addStatus = false;
            });
        },

        setDefaultToDate()
        {
            if(this.employeeStatusDetailForm.status == 3){
                if(!!this.employeeStatusDetailForm.fromDate) {
                    this.employeeStatusDetailForm.toDate = moment(this.employeeStatusDetailForm.fromDate, "DD/MM/YYYY").add(6, "months").format("DD/MM/YYYY")
                }
            }
        },
        
        putStatusDetail(item) {
            //convert date
            let putDate = {
                status:1,
                fromDate: null,
                toDate:null
            };
            this.employeeStatusDetailLoading = true;

            putDate.fromDate = moment(this.employeeStatusDetailEditForm.fromDate, "DD/MM/YYYY").format("YYYY-MM-DD");
            putDate.toDate = moment(this.employeeStatusDetailEditForm.toDate, "DD/MM/YYYY").format("YYYY-MM-DD");
            putDate.status = this.employeeStatusDetailEditForm.status;

            //validate
            let valid = true;
            for (var i = 0; i < this.dateValidRules.length; i++) {
                if(this.dateValidRules[i](this.employeeStatusDetailEditForm.toDate) != true)
                    valid = valid && false;
            }
            for (var i = 0; i < this.dateValidRules.length; i++) {
                if(this.dateValidRules[i](this.employeeStatusDetailEditForm.fromDate) != true)
                    valid = valid && false;
            }
            valid = valid && !!this.employeeStatusDetailEditForm.fromDate && !!this.employeeStatusDetailEditForm.toDate && !!this.employeeStatusDetailEditForm.status;

            if(valid)
            {
                return http.put("EmployeeStatusDetails/"+item.code, putDate).then(res => {
                    this.fetchStatusDetail();
                    this.statusEdit = false;
                }).finally(() => {
                    this.employeeStatusDetailLoading = false;
                });
            }else {
                this.employeeStatusDetailLoading = false;
            }
        },
        
        deleteStatusDetail() {
            this.employeeStatusDetailLoading = true;
            return http.delete("EmployeeStatusDetails/"+this.employeeStatusDetailEditForm.code).then(res => {
                this.fetchStatusDetail();
            }).finally(() => {
                this.deleteStatusDetailCfm = false;
                this.employeeStatusDetailLoading = false;
                this.employeeStatusDetailEditForm.code = "";
                this.employeeStatusDetailEditForm.status = "";
                this.employeeStatusDetailEditForm.fromDate = "";
                this.employeeStatusDetailEditForm.toDate = "";
            });
        },

        //promote details
        setPromoteDetailForm(item){
            return http.get("employeePromoteDetails/"+item.id).then(res => {
                var item = res.data.data;
                this.employeePromoteDetailForm.id = item.id;
                this.employeePromoteDetailForm.positionCode = item.positionCode;
                this.employeePromoteDetailForm.positionStateCode = item.positionStateCode;
                this.employeePromoteDetailForm.shiftCode = item.shiftCode;
                this.employeePromoteDetailForm.fromDate = moment(item.promoteDateFrom).format("DD/MM/YYYY");
                this.employeePromoteDetailForm.toDate = moment( item.promoteDateTo).format("DD/MM/YYYY");
                
                this.promoteDetailData.positionStates = res.data.states;
                this.promoteEdit = true;
                console.log(item);
            });
            // this.positionStateCode = 
            // this.shiftCode = 
            // this.fromDate = 
            // this.toDate = 
        },
        resetEmployeePromoteDetailForm() {
            this.employeePromoteDetailForm.id = "";
            this.employeePromoteDetailForm.shiftCode = "";
            this.employeePromoteDetailForm.positionStateCode = "";
            this.employeePromoteDetailForm.positionCode = "";
            this.employeePromoteDetailForm.fromDate = "";
            this.employeePromoteDetailForm.toDate = "";
        },

        openCreatePromoteDialog(){
            // fetch data filter
            this.resetEmployeePromoteDetailForm();
            this.fetchPosition();
            this.fetchShift();
            this.promoteAdd = true;
            this.promoteEdit = false;
        },

        fetchPosition(){
            return http.get("positions/fetch").then(res => {
                this.promoteDetailData.positions = res.data;
            });
        },
        fetchPositionState(){
            if(this.employeePromoteDetailForm.positionCode)
            {
                return http.get("positions/fetch-position-state/" + this.employeePromoteDetailForm.positionCode).then(res => {
                    this.promoteDetailData.positionStates = res.data;
                });
            }
        },
        fetchShift(){
            return http.get("shifts").then(res => {
                this.promoteDetailData.shifts = res.data;
            });
        },
        
        fetchPromoteDetail() {
            return http.get("EmployeePromoteDetails", {
                params:{
                    employeeCode: this.item.code
                }
            }).then(res => {
                this.employeePromoteDetailLoading = false;
                this.promoteTableItems = res.data;
            });
        },

        fetchPromoteDetailItem(item) {
            this.promoteAdd = true;
            if(item){
                
            } else{

            }
        },

        postPromoteDetail() {

            if(this.promoteEdit){
                this.employeePromoteDetailLoading = true;
                let postDate = {
                    positionCode:"",
                    positionStateCode:"",
                    shiftCode:"",
                    fromDate: null,
                    toDate:null
                };
                postDate.fromDate = moment(this.employeePromoteDetailForm.fromDate, "DD/MM/YYYY").format("YYYY-MM-DD");
                postDate.toDate = moment(this.employeePromoteDetailForm.toDate, "DD/MM/YYYY").format("YYYY-MM-DD");
                postDate.positionCode = this.employeePromoteDetailForm.positionCode;
                postDate.positionStateCode = this.employeePromoteDetailForm.positionStateCode;
                postDate.shiftCode = this.employeePromoteDetailForm.shiftCode;
                
                return http.put("EmployeePromoteDetails/"+this.employeePromoteDetailForm.id, postDate).then(res => {
                    this.fetchPromoteDetail();
                    this.employeePromoteDetailLoading = false;
                    this.promoteEdit = false;
                    this.promoteAdd = false;
                    this.employeePromoteDetailForm.id = "";
                    this.employeePromoteDetailForm.shiftCode = "";
                    this.employeePromoteDetailForm.positionStateCode = "";
                    this.employeePromoteDetailForm.positionCode = "";
                    this.employeePromoteDetailForm.fromDate = "";
                    this.employeePromoteDetailForm.toDate = "";
                }).finally(()=> {
                });
            }else {
                this.employeePromoteDetailLoading = true;
                let postDate = {
                    positionCode:"",
                    positionStateCode:"",
                    shiftCode:"",
                    fromDate: null,
                    toDate:null
                };
                postDate.fromDate = moment(this.employeePromoteDetailForm.fromDate, "DD/MM/YYYY").format("YYYY-MM-DD");
                postDate.toDate = moment(this.employeePromoteDetailForm.toDate, "DD/MM/YYYY").format("YYYY-MM-DD");
                postDate.positionCode = this.employeePromoteDetailForm.positionCode;
                postDate.positionStateCode = this.employeePromoteDetailForm.positionStateCode;
                postDate.shiftCode = this.employeePromoteDetailForm.shiftCode;
                return http.post("EmployeePromoteDetails/"+this.item.code, postDate).then(res => {
                    this.fetchPromoteDetail();
                    this.promoteAdd = false;
                    this.promoteEdit = false;
                    this.employeePromoteDetailLoading = false;
                });
            }
        },
        
        // putPromoteDetail(item) {
        //     let putDate = {
        //         status:1,
        //         fromDate: null,
        //         toDate:null
        //     };
        //     this.employeeStatusDetailLoading = true;

        //     putDate.fromDate = moment(this.employeeStatusDetailEditForm.fromDate, "DD/MM/YYYY").format("YYYY-MM-DD");
        //     putDate.toDate = moment(this.employeeStatusDetailEditForm.toDate, "DD/MM/YYYY").format("YYYY-MM-DD");
        //     putDate.status = this.employeeStatusDetailEditForm.status;

        //     //validate
        //     let valid = true;
        //     for (var i = 0; i < this.dateValidRules.length; i++) {
        //         if(this.dateValidRules[i](this.employeeStatusDetailEditForm.toDate) != true)
        //             valid = valid && false;
        //     }
        //     for (var i = 0; i < this.dateValidRules.length; i++) {
        //         if(this.dateValidRules[i](this.employeeStatusDetailEditForm.fromDate) != true)
        //             valid = valid && false;
        //     }
        //     valid = valid && !!this.employeeStatusDetailEditForm.fromDate && !!this.employeeStatusDetailEditForm.toDate && !!this.employeeStatusDetailEditForm.status;

        //     if(valid)
        //     {
        //         return http.put("EmployeeStatusDetails/"+item.code, putDate).then(res => {
        //             this.fetchPromoteDetail();
        //             this.statusEdit = false;
        //         }).finally(() => {
        //             this.employeeStatusDetailLoading = false;
        //         });
        //     }else {
        //         this.employeeStatusDetailLoading = false;
        //     }
        // },
        
        putPromoteDetail(item) {
            let putDate = {
                departmentTypeCode:"",
                departmentCode:"",
                teamCode:"",
                rotationDateFrom: null,
                rotationDateTo:null
            };
            this.employeeRotationDetailLoading = true;

            putDate.rotationDateFrom = moment(this.employeeRotationDetailForm.rotationDateFrom, "DD/MM/YYYY").format("YYYY-MM-DD");
            putDate.rotationDateTo = moment(this.employeeRotationDetailForm.rotationDateTo, "DD/MM/YYYY").format("YYYY-MM-DD");
            putDate.departmentTypeCode = this.employeeRotationDetailForm.departmentTypeCode;
            putDate.departmentCode = this.employeeRotationDetailForm.departmentCode;
            putDate.teamCode = this.employeeRotationDetailForm.teamCode;

            //validate
            let valid = true;
            for (var i = 0; i < this.dateValidRules.length; i++) {
                if(this.dateValidRules[i](this.employeeStatusDetailEditForm.toDate) != true)
                    valid = valid && false;
            }

            for (var i = 0; i < this.dateValidRules.length; i++) {
                if(this.dateValidRules[i](this.employeeStatusDetailEditForm.fromDate) != true)
                    valid = valid && false;
            }
            valid = valid && !!this.employeeStatusDetailEditForm.fromDate && !!this.employeeStatusDetailEditForm.toDate && !!this.employeeStatusDetailEditForm.status;

            this.employeeRotationDetailLoading = true;

            return http.put("employeePromoteDetails/" + this.employeePromoteDetailEditForm.id, putDate).then(res => {
                this.fetchPromoteDetail();
            }).finally(()=> {
                this.employeePromoteDetailLoading = false;
                this.promoteAdd = false;
            });
        },
        
        deletePromoteDetail() {
            this.employeeStatusDetailLoading = true;
            return http.delete("EmployeePromoteDetails/"+this.employeePromoteDetailForm.id).then(res => {
                this.fetchPromoteDetail();
            }).finally(() => {
                this.deletePromoteDetailCfm = false;
                this.employeePromoteDetailLoading = false;
                this.employeePromoteDetailForm.id = "";
            });
        },
        
        //section rotation
        setRotationDetailForm(item){
            this.employeeRotationDetailEditForm.code = item.id;
            // this.employeePromoteDetailEditForm.departmentTypeCode = item.departmentTypeCode,
            // this.employeePromoteDetailEditForm.departmentCode = item.departmentCode,
            // this.employeePromoteDetailEditForm.teamCode = item.teamCode,
            // this.employeePromoteDetailEditForm.rotationDateFrom = item.rotationDateFrom,
            // this.employeePromoteDetailEditForm.rotationDateFrom = item.rotationDateTo
        },
        openCreateRotationDialog(){
            this.fetchDepartmentType_rt();
            // fetch data filter
            this.rotationAdd = true;
            this.rotationUpdate = false;
            this.employeeRotationDetailForm.id = "";
            this.employeeRotationDetailForm.departmentTypeCode = "";
            this.employeeRotationDetailForm.departmentCode = "";
            this.employeeRotationDetailForm.teamCode = "";
            this.employeeRotationDetailForm.rotationDateFrom = "";
            this.employeeRotationDetailForm.rotationDateTo = "";
        },
        openUpdateRotationDialog(item){
            this.fetchDepartmentType_rt();
            this.rotationAdd = true;
            this.rotationUpdate = true;
            // fetch data filter
            return http.get("EmployeeRotationDetails/fetch-update/"+ item.id).then(res=> {
                this.departments = res.data.selects.departments;
                this.departmentTypes = res.data.selects.departmentTypes;
                this.teams = res.data.selects.teams;
                this.rotationLoading = false;
                console.log(res.data);
                this.employeeRotationDetailForm = Object.assign({}, res.data.data);
            });
        },

        fetchRotationDetail() {
            return http.get("EmployeeRotationDetails", {
                params:{
                    employeeCode: this.item.code
                }
            }).then(res => {
                this.rotationTableItems = res.data;
            });
        },

        fetchRotationDetailItem(item) {
            this.rotationAdd = true;
            if(item){
                
            } else{

            }
        },

        postRotationDetail() {
            this.employeeRotationDetailLoading = true;
            let postDate = {
                departmentTypeCode:"",
                departmentCode:"",
                teamCode:"",
                rotationDateFrom: null,
                rotationDateTo:null
            };
            postDate.rotationDateFrom = moment(this.employeeRotationDetailForm.rotationDateFrom, "DD/MM/YYYY").format("YYYY-MM-DD");
            postDate.rotationDateTo = moment(this.employeeRotationDetailForm.rotationDateTo, "DD/MM/YYYY").format("YYYY-MM-DD");
            postDate.departmentTypeCode = this.employeeRotationDetailForm.departmentTypeCode;
            postDate.departmentCode = this.employeeRotationDetailForm.departmentCode;
            postDate.teamCode = this.employeeRotationDetailForm.teamCode;

            return http.post("EmployeeRotationDetails/"+this.item.code, postDate).then(res => {
                this.fetchRotationDetail();
                this.employeeRotationDetailLoading = false;
                this.rotationAdd = false;
                console.log("sdasda");
            });
        },
        
        putRotationDetail(item) {
            
            let putDate = {
                departmentTypeCode:"",
                departmentCode:"",
                teamCode:"",
                rotationDateFrom: null,
                rotationDateTo:null
            };
            this.employeeRotationDetailLoading = true;

            putDate.rotationDateFrom = moment(this.employeeRotationDetailForm.rotationDateFrom, "DD/MM/YYYY").format("YYYY-MM-DD");
            putDate.rotationDateTo = moment(this.employeeRotationDetailForm.rotationDateTo, "DD/MM/YYYY").format("YYYY-MM-DD");
            putDate.departmentTypeCode = this.employeeRotationDetailForm.departmentTypeCode;
            putDate.departmentCode = this.employeeRotationDetailForm.departmentCode;
            putDate.teamCode = this.employeeRotationDetailForm.teamCode;

            //validate
            let valid = true;
            for (var i = 0; i < this.dateValidRules.length; i++) {
                if(this.dateValidRules[i](this.employeeStatusDetailEditForm.toDate) != true)
                    valid = valid && false;
            }

            for (var i = 0; i < this.dateValidRules.length; i++) {
                if(this.dateValidRules[i](this.employeeStatusDetailEditForm.fromDate) != true)
                    valid = valid && false;
            }
            valid = valid && !!this.employeeStatusDetailEditForm.fromDate && !!this.employeeStatusDetailEditForm.toDate && !!this.employeeStatusDetailEditForm.status;

            this.employeeRotationDetailLoading = true;

            return http.put("EmployeeRotationDetails/" + this.employeeRotationDetailForm.id, putDate).then(res => {
                this.fetchRotationDetail();
            }).finally(()=> {
                this.employeeRotationDetailLoading = false;
                this.rotationAdd = false;
            });
        },
        
        deleteRotationDetail() {
            this.employeeRotationDetailLoading = true;
            return http.delete("EmployeeRotationDetails/"+this.employeeRotationDetailEditForm.code).then(res => {
                this.fetchRotationDetail();
            }).finally(() => {
                this.deleteRotationDetailCfm = false;
                this.employeeRotationDetailLoading = false;
                this.employeeRotationDetailEditForm.code = "";
            });
        },
        fetchDepartmentType_rt() {
            return http.get("departmentTypes").then(res=>{
                this.departmentTypes = res.data.items;
            });
        },
        fetchDepartment_rt(){
            if(this.employeeRotationDetailForm.departmentTypeCode){
                return http.get("departmentTypes/departments/"+this.employeeRotationDetailForm.departmentTypeCode).then(res=>{
                    this.departments = res.data;
                });
            }
        },
        fetchTeam_rt(){
            if(this.employeeRotationDetailForm.departmentTypeCode){
                return http.get("departments/teams/"+this.employeeRotationDetailForm.departmentCode).then(res=>{
                    this.teams = res.data;
                });
            }
        },
        getSuggestStartDayHelper(){
            console.log(this.item.code);
            return http.get("EmployeeRotationDetails/suggest-start-day?employeeCode="+this.item.code).then(res => {
                this.employeeRotationDetailForm.rotationDateFrom = res.data;
            });
        }
    },
    created() {
    }
    
}