import moment from 'moment';

class Helper {
    formatStatus(value) {
        let result = {};
        switch(value) {
            case 0: {
                result.text = "Chờ duyệt";
                result.color = "warning"
                break;
            }
            case 1: {
                result.text = "Đã duyệt"
                result.color = "success"
                break;
            }
            case 2: {
                result.text = "Từ chối"
                result.color = "error"
                break;
            }
            
            case "NEW": {
                result.text = "Mới"
                result.color = "primary"
                break;
            }
            case "PEND": {
                result.text = "Chờ"
                result.color = "warning"
                break;
            }
            case "PASS": {
                result.text = "Đạt"
                result.color = "success"
                break;
            }
            case "FAIL": {
                result.text = "Loại"
                result.color = "error"
                break;
            }
            
            case true: {
                result.text = "Xác nhận"
                result.color = "success"
                break;
            }
            case false: {
                result.text = "Chưa xác nhận"
                result.color = "error"
                break;
            }
            default:{
                result.text = "Chưa có phiếu"
                break;
            }
        }
        return result;
    }
    
    formatDate(val){
        return moment(val).format("YYYY-MM-DD")
    }
}

export default new Helper();