 <template>
  <div id="layout">
    <!--The Side Menu component go here -->
    <v-snackbar class="message"
      :timeout="3000" v-model="showAlert"
      absolute right
      top :vertical="messages" :color="color"
    >
        <v-icon dark >
          mdi-alert
        </v-icon>
      {{message}}
    </v-snackbar>
    <side-bar ref="sideBar" :items="items" :valid-sections="validSections"></side-bar>
    
    <!--V-app bar -->
    <v-app-bar color="teal darken-4"  dark app :extension-height="extensionHeight" elevate-on-scroll
    style="background: linear-gradient(45deg, rgb(0,77,64), rgb(4, 58, 49)) !important"
    >
      
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
        ></v-img>
      </template>
      <v-app-bar-nav-icon @click.stop="openDrawer"></v-app-bar-nav-icon>
<!-- 
      <v-container class="hidden-sm-and-down ml-16" style="max-width:300px">
        <v-autocomplete light hide-details="" clearable :items="modules" solo dense append-icon="mdi-magnify" rounded></v-autocomplete>
      </v-container> -->
        <v-breadcrumbs :items="breadcrumbs" divider="/" large class="py-0" >
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                :to="item.to"
                :disabled="item.disabled"
                exact
                >
                <span v-bind:class="{' orange--text text--lighten-2': !item.disabled }"> <b>{{ item.text.toUpperCase() }}</b> </span>
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
      <v-spacer></v-spacer>

      <!-- <v-btn class="mr-2" to="/login">Login</v-btn> -->
      <!-- <v-menu open-on-hover bottom offset-y left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined dark class="mx-2"
            v-bind="attrs" v-on="on" >
              <v-icon left>mdi-package-variant-closed</v-icon>
              Hạng mục
          </v-btn>
        </template>

        <v-list>
          <v-list-item :to="{name:'Home'}" v-if="userClaim.includes('module:base')">
            <v-list-item-icon>
              <v-icon large color="amber">mdi-account-tie</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium" > Nhân viên </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{name:'EmployeeOverview'}" v-if="userClaim.includes('module:employment')">
            <v-list-item-icon>
              <v-icon large color="pink">mdi-finance</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium" > Quản lý nhân sự </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item :to="{name:'MonthlyTimeSheet'}" v-if="userClaim.includes('module:salary')">
            <v-list-item-icon>
              <v-icon large color="light-green">mdi-scale-balance</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium" > Giờ công và lương </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item :to="{name:'CandidateOverview'}" v-if="userClaim.includes('module:recruit')">
            <v-list-item-icon>
              <v-icon large color="cyan">mdi-handshake</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium" > Tuyển dụng  </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item :to="{name:'City'}" v-if="userClaim.includes('module:sysconfig')">
            <v-list-item-icon>
              <v-icon large color="grey">mdi-cogs</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium" > Cài đặt </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item :to="{name:'ItSection'}" v-if="userClaim.includes('module:it')">
            <v-list-item-icon>
              <v-icon large color="teal">mdi-laptop</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium" > Phân hệ IT </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <v-list-item :to="{name:'MktSection'}" v-if="userClaim.includes('module:mkt')">
            <v-list-item-icon>
              <v-icon large color="teal">mdi-pen</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium" > Phân hệ MKT </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
    </v-menu> -->
      <!-- <v-btn icon color="white"  class="mx-2" @click="extensionHeight= extensionHeight==0?67:0">
        <v-icon large>mdi-package-variant-closed</v-icon>
      </v-btn> -->
      
      <nav class="mx-1">
        <!-- <div id="notification-bell">
            <v-icon>mdi-bell</v-icon>
            <span id="unseen-badge"></span>
        </div> -->
        <div>
            <!-- <v-icon>mdi-bell</v-icon> -->
            <v-menu close-on-click
              v-model="notifMenu"
              :close-on-content-click="false"
              
              offset-y offset-overflow
            >
              <template v-slot:activator="{ on, attrs }">
                
                <v-badge color="red darken-4" overlap :content="notifications.filter(x=>x.status==2).length.toString()">
                  <v-btn
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on" icon
                    @click="getNotif"
                  >
                    <v-icon>mdi-bell</v-icon>
                  </v-btn>
                </v-badge>
              </template>

              <v-card style="z-index:999; height: 500px;"  width="450px">
                <!-- <v-list width="250px">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title><b>Thông báo</b> </v-list-item-title>
                      <v-list-item-subtitle><b>Danh sách thông báo</b></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list> -->

                <!-- <v-divider></v-divider> -->

                <v-list>
                  <v-list-item v-for="(item, index) in notifications" :key="index" @click="readNotif(item.id)" three-line>
                    <v-list-item-avatar>
                      <v-icon color="info" size="16">mdi-information</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title><b>{{item.title}}</b></v-list-item-title>
                      <v-list-item-subtitle ><i><small>{{diffToHuman(item.dateSent)}}</small></i></v-list-item-subtitle>
                      <v-list-item-subtitle style="-webkit-line-clamp: 3 !important;">{{item.body}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <!-- <v-list-item-action-text>{{diffToHuman(item.dateSent)}}</v-list-item-action-text> -->
                        
                        <v-icon
                          v-if="item.status==2"
                          color="red lighten-1"
                        >
                          mdi-new-box
                        </v-icon>

                        <v-icon
                          v-else
                          color="green darken-3"
                        >
                          mdi-check
                        </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
        </div>
      </nav>
      <nav class="mx-1">
        <v-btn icon @click="logout"><v-icon>mdi-logout</v-icon></v-btn>
      </nav>
      <!-- <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar color="black" size="32" v-bind="attrs" v-on="on">
            <img
              :src="$baseURL+user.avatar"
              alt="avatar" >
          </v-avatar>
        </template>
        <v-list>
          <v-list-item @click="$router.push({name:'Profile'})">
            <v-list-item-title>Hồ sơ</v-list-item-title>
            <v-list-item-icon>
              <v-icon color="orange"> mdi-book-account </v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item  @click="logout">
            <v-list-item-title>Đăng xuất</v-list-item-title>
            <v-list-item-icon>
              <v-icon color="orange"> mdi-logout</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <!-- <div class="ml-2 d-none d-md-block">Xin chào {{user.employeeName}}</div> -->
      <!-- <v-btn @click="logout" icon>
        <v-icon>mdi-logout</v-icon>
      </v-btn> -->
      <!-- <template v-slot:extension>
        <v-tabs icons-and-text fixed-tabs align-with-title>
          <v-tabs-slider color="amber"></v-tabs-slider>
          <v-tab  active-class="" :to="{name:'Home'}" v-if="userClaim.includes('module:base')">Nhân viên 
            <v-sheet class="rounded pa-1" color="orange">
              <v-icon dark>mdi-account-tie</v-icon>
            </v-sheet>
          </v-tab>
          <v-tab active-class="" :to="{name:'EmployeeOverview'}" v-if="userClaim.includes('module:employment')">Quản lý nhân sự 
            <v-sheet class="rounded pa-1" color="pink">
              <v-icon dark>mdi-finance</v-icon>
            </v-sheet>
          </v-tab>
          <v-tab  active-class="" :to="{name:'MonthlyTimeSheet'}" v-if="userClaim.includes('module:salary')">Giờ công và lương 
            <v-sheet class="rounded pa-1" color="light-green" dark>
              <v-icon dark>mdi-scale-balance</v-icon>
            </v-sheet>
          </v-tab>
          <v-tab  active-class="" :to="{name:'CandidateOverview'}" v-if="userClaim.includes('module:recruit')">Tuyển dụng 
            <v-sheet class="rounded pa-1" color="cyan">
              <v-icon dark>mdi-handshake</v-icon>
            </v-sheet>
          </v-tab>
          <v-tab  active-class="" :to="{name:'City'}" v-if="userClaim.includes('module:sysconfig')">Cài đặt <v-icon large color="white ">mdi-cogs</v-icon></v-tab>
        </v-tabs>
      </template> -->
    </v-app-bar>
    <router-view @updateSidebar="updateSidebar"></router-view>
    
      <v-footer dark padless >
        <v-card flat tile class="teal darken-4  text-center"  width="100%">
          <v-card-text>
            <v-btn class="mx-4 " style="background-color: white;" icon >
              <v-icon size="24px" color="blue darken-2">
                mdi-facebook
              </v-icon>
            </v-btn>
            <v-btn class="mx-4 " icon >
              <v-icon size="24px">
                mdi-instagram
              </v-icon>
            </v-btn>
            <v-btn class="mx-4 " icon >
              <v-icon size="24px">
                mdi-link
              </v-icon>
            </v-btn>
          </v-card-text>

          <v-card-text class=" pt-0">
            Được thành lập từ năm 1997, Hệ thống nhà thuốc Trung Sơn là chuỗi nhà thuốc được tin dùng và yêu thích nhất khu vực Mekong và TP. Hồ Chí Minh
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="">
            {{ new Date().getFullYear() }} — <strong>TRUNGSON PHARMA</strong>
          </v-card-text>
        </v-card>
      </v-footer>
      
      <v-alert type="info" v-show="hasMessage" dark centered style="width:400px; position:absolute; top:calc(100vh - 85px); left:10px; z-index:999">
        <h4>{{notifObj.title}}</h4>
        <div>{{notifObj.body}}</div>
      </v-alert>
  </div>
</template>

<script>
// import axios from "axios"; // importing the axios (a HTTP library) to connects the app with the News API
import MainContent from "@/components/MainContent.vue"; // import the Main Content component
import SideBar from "@/components/SideBar.vue"; // import the SideMenu component
import router from "@/router/index"
import EventBus  from "@/components/services/EventBus";
import http from '@/components/services/http-common';
import { getMessaging, getToken, onMessage  } from "firebase/messaging";

import moment from 'moment';

export default {
  name: "App",
  components: {
    MainContent,
    SideBar,
  },
  computed: {
    userClaim() {
      return localStorage.getItem("userClaim");
    },
    breadcrumbs() {
      let validBreadCrumbs = [];
      if(typeof(this.$route.meta)=="object"){
        validBreadCrumbs= this.$route.meta.breadcrumbs;
      }
      else
        validBreadCrumbs = this.$route.meta(this.$route).breadcrumbs;
      return validBreadCrumbs ? validBreadCrumbs.filter(x=>!x.disabled) : []
    },
    height () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 200;
        case 'sm': return 300;
        case 'md': return 500;
        case 'lg': return 600;
        case 'xl': return 800;
      }
    },
    user () {
      let user = localStorage.getItem("user");
      user = JSON.parse(user);
      return user;
    },
    validSections() {
      const sections = [
        {icon:'mdi-account-tie', title: 'Nhân viên', claims:'', to: {name:'Home'}, color:'amber lighten-2', href: '/admin/base'},
        {icon:'mdi-finance', title: 'Quản lý nhân sự', claims:'module:employment', to: {name:'EmployeeOverview'}, color:'pink', href: '/admin/employment'},
        {icon:'mdi-scale-balance', title: 'Giờ công và lương', claims:'module:salary', to: {name:'MonthlyTimeSheet'}, color:'light-green', href: '/admin/salaryEnrollment'},
        {icon:'mdi-handshake', title: 'Tuyển dụng', claims:'module:recruit', to: {name:'CandidateOverview'}, color:'cyan', href:'/admin/recruit'},
        {icon:'mdi-cogs', title: 'Cài đặt', claims:'module:sysconfig', to: {name:'City'}, color:'grey', href:'/admin/systemConfig'},
        {icon:'mdi-laptop', title: 'Phân hệ IT', claims:'module:it', to: {name:'ItSection'}, color:'green', href:'/admin/it-section'},
        {icon:'mdi-school', title: 'Phân hệ LD', claims:'module:it', to: {name:'LaDSection'}, color:'pink lighten-4', href:'/admin/lad-section'},
        {icon:'mdi-pen', title: 'Phân hệ MKT', claims:'module:mkt', to: {name:'MktSection'}, color:'yellow', href:'/admin/mkt-section'},
        {icon:'mdi-flare', title: 'Phân hệ OC', claims:'module:oc', to: {name:'ItSection'}, color:'red', href:'/admin/oc-section'},
        {icon:'mdi-check-decagram', title: 'Phân hệ CM', claims:'module:cm', to: {name:'CmSection'}, color:'orange', href:'/admin/cm-section'},
        {icon:'mdi-truck-fast-outline', title: 'Phân hệ kho', claims:'module:wh', to: {name:'WareHouseSection'}, color:'white', href:'/admin/warehouse-section'},
      ]
      let validSections = sections.filter(x=> {
        return this.userClaim.includes(x.claims)
      })
      return validSections
    }
    // auth () {
    //   let token = localStorage.getItem("token");
    //   if(token == "undefined" || token == null)
    //     return false;
    //   else
    //     return true;
    // }
  },
  methods: {
    diffToHuman(value){
        let date = moment(value, "YYYY-MM-DD hh:mm:ss").locale("vi");
        return moment(date).fromNow();
    },
    updateSidebar(value){
      this.items= value;
    },
    logout () {
      // localStorage.setItem("token", "");
      localStorage.removeItem("CaBToken")
      localStorage.removeItem("user")
      localStorage.removeItem("token")
      router.push({ name: 'login'});
    },
    openDrawer () {
      this.$refs.sideBar.drawer = !this.$refs.sideBar.drawer;
    },
    errorsHandler (e){
      //**SWEET ALERT
      console.log("errors")
    },

    errorHandler (e) {
      console.log(e)
      this.$Swal.fire({
        title: "Thông báo",
        text: e.message,
        icon: 'error',
        toast: e.toast ?? false,
        timer: e.timer != undefined ? (e.timer ?? 3000) : undefined,
        position: e.toast ? 'top-end' : 'center',
        showConfirmButton: e.showConfirmButton? e.showConfirmButton : false,
      });
    },
    
    messageHandler (e){
      //**SWEET ALERT
      if(e.message)
      {
        this.$Swal.fire(
        {
          title: "Thông báo",
          text: `${e.message} ${e.warning?e.warning:""}`,
          icon: e.warning? 'warning' : 'success',
          toast: e.toast ?? false,
          timer: 3000,
          position: e.toast ? 'top' : 'center',
          showConfirmButton: false,
        });
      }
    },
    messagesHandler (e) {
      
    },
    hasNotificationHandler(e) {
      this.hasMessage = true;
      this.notifObj.title = e.questionId;
      this.notifObj.body = e.score;
      let that =this;
      setTimeout(()=> {
        that.hasMessage = false;
      }, 5000);
    },

    readNotif(id) {
      return http.get(`BaseNotifications/${id}/read`).then(() => this.getNotif());
    },

    getNotif() {
      return http.get("BaseNotifications").then(res => {
        this.notifications = res.data;
      });
    },

    setUrl (breadcrumbs) {
      this.breadCrumbData = breadcrumbs;
    }
  },
  data() {
    return {
      extensionHeight:67,
      items:[], //sidebar-items
      breadCrumbData: [],
      drawer: false,
      articles: [],
      errors: [],
      showAlert:false,
      errorMessages:[],
      message: "",
      messages: null,
      color: "success",
      modules: [
        {text: 'Xác nhận nghỉ phép', value: "ConfirmLeave"}
      ],
      hasMessage: false,
      notifications: [],
      notifMenu:false,
      notifObj: {
        title:"",
        body:""
      }
    };
  },
  created() {
    EventBus.$on('hasError', this.errorHandler);
    EventBus.$on('hasErrors', this.errorsHandler);
    EventBus.$on('hasMessage', this.messageHandler);
    EventBus.$on('hasMessages', this.messagesHandler);
    console.log(this.$route.meta);

    // init notif
    this.getNotif();


    // this.$notificationHub.$on('hasNotification', this.hasNotificationHandler)
  },
  mounted() {
    getToken(this.$messaging, { vapidKey: 'BOOxWYnHBC1LhJ1n3VFjdS0vEVosMTzRfO-uSp7_RNK2MHO9fjI3TzAs_klvXsH_gxoxW8V__k9EDtFjz4_2W7U' })
      .then((currentToken) => {
    if (currentToken) {
      console.log('client token', currentToken)
      http.get('FCM/set-token', {
        params: {
          token: currentToken,
          userName: this.user.userName
        }
      }).then(() => { console.log('token set to server') })
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    })
  },
  updated(){
    if(this.user) {
      // this.$notificationHub.notificationSubscribe(this.user.employeeCode);
    }
  },
  destroyed() {
    EventBus.$off('hasError', this.errorHandler);
    EventBus.$off('hasErrors', this.errorsHandler);
    EventBus.$off('hasMessage', this.messageHandler);
    EventBus.$off('hasMessages', this.messagesHandler);

    // if(this.user) {
    //   this.$notificationHub.notificationUnSubscribe(this.user.employeeCode);
    // }
  }
};

</script>


<style scoped>
#layout{
  background-color: #f6f7ff;
}
::v-deep figure.image{
  border: 1px dashed rgba(0, 0, 0, .2) !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
::v-deep figure.image img{
  width: 100% !important;
}
::v-deep table > tbody > tr.filter>td {
    padding-right: 1px !important;
    padding-left: 1px !important;
}

/* disable :hover */
::v-deep .active-no-hover:hover {
}

/* CUSTOM TABLE STYLE */
/* ::v-deep .v-data-table tbody
tr:hover:not(.v-data-table__expanded__content) {
    background: white !important;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
::v-deep .v-data-table td {
    border-bottom: none !important;
} */
</style>
<style>
  /* .v-application a {
    color: #009688 !important;
  }
  .v-application a :disabled{
    color: black !important;
  } */
  .message {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 203;
  }
  
</style>