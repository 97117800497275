import Employment from '@/views/layouts/SubLayouts/Employment.vue';

//******CONTENT */
import ContentCategory from '@/views/Content/Category/Index.vue';
import Content from '@/views/Content/Content/Index.vue';

//*********** HR */
import HR from '@/views/HR/Index.vue';
import Employee from '../pages/Employee';
import Assignment from '../pages/Assignment';
import LeaveEnroll from '../pages/LeaveEnroll';
import EmployeeOverview from '@/views/HR/Overview/Index.vue';
import HrEmployeeOverview from '@/views/HR/Overview/Hr.vue';
import Schedule from '@/views/HR/Schedule/Index.vue';
import User from '@/views/HR/User/Index.vue';
import Role from '@/views/HR/Role/Index.vue';
import Insurance from '@/views/HR/Insurance/Index.vue';
import Contract from '@/views/HR/Contract/Index.vue';
import EmployeeIssue from '@/views/HR/EmployeeIssue/Index.vue';

//*********** Confirm */
import Attendance from '@/views/SalaryAndWork/Attendance/Index.vue';
import Confirm from '@/views/Confirm/Index.vue'
import ConfirmLongLeaveRequest from '@/views/Confirm/LongLeave/Index.vue';
import ConfirmShortRequest from '@/views/Confirm/ShortLeave/Index.vue';
import ConfirmOvertime from '@/views/Confirm/Overtime/Index.vue';
import ConfirmLeave from '@/views/Confirm/Leave/Index.vue';
import ConfirmQuit from '@/views/Confirm/Quit/Index.vue';
import ConfirmBusiness from '@/views/Confirm/Business/Index.vue';
import ConfirmOvertimeBooking from '@/views/Confirm/OvertimeBooking/Index.vue';

//****Image */
import ImageReport from '@/views/HR/ImageReport/Index.vue';

//timesheet
import TimeSheet from '@/views/SalaryAndWork/TimeSheet/Index.vue';
import ReviewTimesheet from '@/views/HR/ReviewTimesheet/Index.vue';
import Feedback from '@/views/HR/Feedback/Index.vue';

import QrLink from '@/views/HR/QrLink/Index.vue';
// import TimeSheet2 from '@/views/SalaryAndWork/TimeSheet/Index2.vue';

import BenefitManager from '@/views/HR/BenefitManager/Index.vue';
import CurrentEmployee from '@/views/HR/EmployeeActive/Index.vue';

import VideoSurvey from '@/views/HR/VideoSurvey/Index.vue';

import EmployeeRotationRequest from '@/views/Request/EmployeeRotation/Index.vue'

import ConfirmRotationRequest from '@/views/Confirm/EmployeeRotation/Index.vue'
import Survey from '@/views/HR/Survey/Index'
import SurveyReport from '@/views/HR/Survey/Report'

import AsmConfig from '@/views/HR/AsmConfig/Index'

import EmployeeReport from '@/views/HR/EmployeeReport/Index'
import ContractCategory from '@/views/HR/ContractCategory/Index'
import Contractv2 from '@/views/HR/Contractv2/Index'
import Contractv2Pending from '@/views/HR/Contractv2/Pending'
import Contractv2Ready from '@/views/HR/Contractv2/Ready'
import Contractv2Return from '@/views/HR/Contractv2/Return'
import Contractv2Complete from '@/views/HR/Contractv2/Complete'
import Contractv2Resign from '@/views/HR/Contractv2/Resign'

import OrganizationMap from '@/views/HR/OrganizationMap/Index'
import OrganizationMapCreate from '@/views/HR/OrganizationMap/Create'


var isHr = localStorage.getItem('userClaim')?.includes('hr:dashboard')

export default [
    {
      path: 'employment',
      name: 'Employment',
      component: Employment,
      meta: { 
        breadcrumbs: [
            {
            text: 'Trang chủ',
            disabled: false,
            to: {
                name: "Home",
                }
            },
        ],
        icon:{
            icon: "mdi-plus-thick",
            to: ""
        },
      },
      redirect: {name: 'EmployeeOverview'},
      children:[
        
        {
          path: 'employee-report',
          name: 'EmployeeReport',
          component: EmployeeReport,
        },
        {
          path: 'survey',
          name: 'Survey',
          component: Survey,
        },
        {
          path: 'survey/report/:id',
          name: 'SurveyReport',
          component: SurveyReport,
          meta:{
            breadcrumbs: [
                {
                text: 'Danh sách khảo sát',
                to: {
                    name: "Survey",
                    }
                },
              ],
          }
        },
        //*********** XET DUYET ******* */
        {
          path:"Confirm",
          component: Confirm,
          children:[
            {
              path: 'rotation-request',
              name: 'ConfirmRotationRequest',
              component: ConfirmRotationRequest
            },
            {
              path: 'confirm-overtime-booking',
              name: 'ConfirmOvertimeBooking',
              component: ConfirmOvertimeBooking,
              meta:{
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Xác nhận giới hạn tăng ca',
                    disabled: true,
                    to: {
                        name: "ConfirmOvertime",
                        }
                    },
                  ],
              }
            },
            {
              path: 'confirm-short-leave',
              name: 'ConfirmShortRequest',
              component: ConfirmShortRequest
            },
            {
              path:'confirm-overtime',
              name: 'ConfirmOvertime',
              component: ConfirmOvertime,
              meta:{
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Xác nhận tăng ca',
                    disabled: true,
                    to: {
                        name: "ConfirmOvertime",
                        }
                    },
                  ],
              }
            },
            {
              path:'confirm-leave',
              name: 'ConfirmLeave',
              component: ConfirmLeave,
              meta:{
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Xác nhận nghỉ phép',
                    disabled: true,
                    to: {
                        name: "ConfirmLeave",
                        }
                    },
                  ],
              }
            },
            
            {
              path:'confirm-quit',
              name: 'ConfirmQuit',
              component: ConfirmQuit,
              meta:{
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Xác nhận nghỉ phép',
                    disabled: true,
                    to: {
                        name: "ConfirmQuit",
                        }
                    },
                  ],
              }
            },
            {
              path:'confirm-business',
              name: 'ConfirmBusiness',
              component: ConfirmBusiness,
              meta:{
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Xác nhận công tác',
                    disabled: true,
                    to: {
                        name: "ConfirmBusiness",
                        }
                    },
                  ],
              }
            },
            {
              path: 'confirm-long-leave',
              name: 'ConfirmLongLeaveRequest',
              component: ConfirmLongLeaveRequest,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Duyệt nghỉ dài hạn',
                    disabled: true,
                    to: {
                        name: "LongLeaveConfirm",
                        }
                    },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                  },
              },
            },
          ]

        },

        {
          path:"employee-rotation-request",
          name:"EmployeeRotationRequest",
          component: EmployeeRotationRequest,
        },
        ////*********** QRLINK ***********
        {
          path:"qr-link",
          name:"qrLink",
          component: QrLink,
          meta: { 
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Sinh mã QR',
                disabled: true,
                to: {
                    name: "qrLink",
                    }
                },
            ],
          }
        },
        
        ////*********** ORG MAP ***********
        {
          path:"organization-map",
          name:"OrganizationMap",
          component: OrganizationMap,
        },
        {
          path:"organization-map/create",
          name:"OrganizationMapCreate",
          component: OrganizationMapCreate,
        },
        {
          path:"organization-map/edit/:id",
          name:"OrganizationMapCreateEdit",
          component: OrganizationMapCreate,
        },
        
        {
          path: 'hr-employee-overview',
          name: 'HrEmployeeOverview',
          component: HrEmployeeOverview,
          meta: { 
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Ngày công',
                disabled: true,
                to: {
                    name: "Timesheet",
                    }
                },
            ],
          }
        },
        
        {
          path: 'employee-overview',
          name: 'EmployeeOverview',
          // component: isHr ? HrEmployeeOverview: EmployeeOverview,
          component: EmployeeOverview,
          meta: { 
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Ngày công',
                disabled: true,
                to: {
                    name: "Timesheet",
                    }
                },
            ],
          }
        },
        {
          path: 'timesheet',
          name: 'HrTimeSheet',
          component: TimeSheet,
          meta: { 
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Ngày công',
                disabled: true,
                to: {
                    name: "Timesheet",
                    }
                },
            ],
          }
        },
        {
          path: 'attendances',
          name: 'Attendance',
          component: Attendance,
          meta: { 
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Danh sách chấm công',
                disabled: true,
                to: {
                    name: "Attendance",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
          }
        },
        //********** HR */
        {
          path: "HR",
          component: HR,
          children:[
            {
              path: 'feedback',
              name: 'Feedback',
              component: Feedback,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Phản hồi',
                    disabled: true,
                    to: {
                        name: "Feedback",
                        }
                    },
                ],
              }
            },
            
            {
              path: 'image-report',
              name: 'ImageReport',
              component: ImageReport,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Báo cáo hình ảnh',
                    disabled: true,
                    to: {
                        name: "ImageReport",
                        }
                    },
                ],
              }
            },

            {
              path: 'review-timesheet',
              name: 'ReviewTimesheet',
              component: ReviewTimesheet,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Ngày công',
                    disabled: true,
                    to: {
                        name: "ReviewTimesheet",
                        }
                    },
                ],
              }
            },
            

            {
              path: 'schedule',
              alias: ["hr/schedule"],
              name: 'Schedule',
              component: Schedule,
              meta:{
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Sắp ca làm việc',
                  disabled: true
                  },
                ],
              }
            },
            ...Employee,
            ...Assignment,
            {
              path: 'users',
              name: 'User',
              component: User,
              meta:{
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Quản lí tài khoản ',
                  disabled: true,
                  to: {
                      name: "User",
                      }
                  },
                ],
              }
            },
            {
              path: 'roles',
              name: 'Role',
              component: Role,
              meta:{
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Quản lí phân quyền ',
                  disabled: true,
                  to: {
                      name: "role",
                      }
                  },
                ],
              }
            },
            {
              path: 'asm-config',
              name: 'AsmConfig',
              component: AsmConfig,
            },
            // {
            //   path: 'insurances',
            //   name: 'Insurance',
            //   component: Insurance,
            //   meta:{
            //     breadcrumbs: [
            //       {
            //       text: 'Trang chủ',
            //       disabled: false,
            //       to: {
            //           name: "Home",
            //           }
            //       },
            //       {
            //       text: 'Quản lí bảo hiểm ',
            //       disabled: true,
            //       to: {
            //           name: "Insurance",
            //           }
            //       },
            //     ],
            //   }
            // },
            // {
            //   path: 'contracts',
            //   name: 'Contract',
            //   component: Contract,
            //   meta: {
            //     breadcrumbs: [
            //         {
            //         text: 'Trang chủ',
            //         disabled: false,
            //         to: {
            //             name: "Home",
            //             }
            //         },
            //         {
            //         text: 'Quản lí hợp đồng ',
            //         disabled: true,
            //         to: {
            //             name: "Contract",
            //             }
            //         },
            //     ],
            //     icon:{
            //         icon: "mdi-plus-thick",
            //         to: ""
            //     },
            //   }
            // },
            // {
            //   path: 'employee-issue',
            //   name: 'EmployeeIssue',
            //   component: EmployeeIssue,
            //   meta: { 
            //     breadcrumbs: [
            //         {
            //         text: 'Trang chủ',
            //         disabled: false,
            //         to: {
            //             name: "Home",
            //             }
            //         },
            //         {
            //         text: 'Cấp phát nhân viên',
            //         disabled: true,
            //         to: {
            //             name: "EmployeeIssue",
            //             }
            //         },
            //     ],
            //     icon:{
            //         icon: "mdi-plus-thick",
            //         to: ""
            //     },
            //   }
            // },
            
            {
              path: 'current-employee',
              name: 'CurrentEmployee',
              component: CurrentEmployee,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'DS nhân viên hoạt động',
                    disabled: true,
                    to: {
                        name: "CurrentEmployee",
                        }
                    },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },
            // ...LeaveEnroll,
            // ...Salary,
            // ...Allowance,
          ]
        },
        
        //*********** CONTENT ********* */
        {
          path:"content",
          component: Confirm,
          children:[
            {
              path: 'category',
              name: 'Category',
              component: ContentCategory,
              meta:{
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Danh mục',
                  disabled: true,
                  to: {
                      name: "Category",
                      }
                  },
                ],
                  
                icon:{
                  icon: "mdi-plus-thick",
                  to: ""
                },
              }
            },
            {
              path: 'content',
              name: 'Content',
              component: Content,
              meta:{
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Bài viết',
                  disabled: true,
                  to: {
                      name: "Content",
                      }
                  },
                ],
                  
                icon:{
                  icon: "mdi-plus-thick",
                  to: ""
                },
              }
            },
          ]
        },

        {
          path: "HR",
          component: HR,
          children:[
            {
              path: 'feedback',
              name: 'Feedback',
              component: Feedback,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Phản hồi',
                    disabled: true,
                    to: {
                        name: "Feedback",
                        }
                    },
                ],
              }
            },
            
            {
              path: 'image-report',
              name: 'ImageReport',
              component: ImageReport,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Báo cáo hình ảnh',
                    disabled: true,
                    to: {
                        name: "ImageReport",
                        }
                    },
                ],
              }
            },
            {
              path: 'timesheet',
              name: 'HrTimeSheet',
              component: TimeSheet,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Ngày công',
                    disabled: true,
                    to: {
                        name: "Timesheet",
                        }
                    },
                ],
              }
            },

            {
              path: 'review-timesheet',
              name: 'ReviewTimesheet',
              component: ReviewTimesheet,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Ngày công',
                    disabled: true,
                    to: {
                        name: "ReviewTimesheet",
                        }
                    },
                ],
              }
            },
            
            {
              path: 'attendances',
              name: 'Attendance',
              component: Attendance,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Danh sách chấm công',
                    disabled: true,
                    to: {
                        name: "Attendance",
                        }
                    },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },

            {
              path: 'employee-overview',
              name: 'EmployeeOverview',
              component: EmployeeOverview,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Ngày công',
                    disabled: true,
                    to: {
                        name: "Timesheet",
                        }
                    },
                ],
              }
            },
            {
              path: 'schedule',
              alias: ["hr/schedule"],
              name: 'Schedule',
              component: Schedule,
              meta:{
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Sắp ca làm việc',
                  disabled: true
                  },
                ],
              }
            },
            ...Employee,
            ...Assignment,
            {
              path: 'users',
              name: 'User',
              component: User,
              meta:{
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Quản lí tài khoản ',
                  disabled: true,
                  to: {
                      name: "User",
                      }
                  },
                ],
              }
            },
            {
              path: 'roles',
              name: 'Role',
              component: Role,
              meta:{
                breadcrumbs: [
                  {
                  text: 'Trang chủ',
                  disabled: false,
                  to: {
                      name: "Home",
                      }
                  },
                  {
                  text: 'Quản lí phân quyền ',
                  disabled: true,
                  to: {
                      name: "role",
                      }
                  },
                ],
              }
            },

            {
              path: 'video-survey',
              name: 'videoSurvey',
              component: VideoSurvey,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'Quan sát videos',
                    disabled: true,
                    to: {
                        name: "VideoSurvey",
                        }
                    },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },

            {
              path: 'contract-category',
              name: 'ContractCategory',
              component: ContractCategory,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                ],
              }
            },
            
            {
              path: 'current-employee',
              name: 'CurrentEmployee',
              component: CurrentEmployee,
              meta: { 
                breadcrumbs: [
                    {
                    text: 'Trang chủ',
                    disabled: false,
                    to: {
                        name: "Home",
                        }
                    },
                    {
                    text: 'DS nhân viên hoạt động',
                    disabled: true,
                    to: {
                        name: "CurrentEmployee",
                        }
                    },
                ],
                icon:{
                    icon: "mdi-plus-thick",
                    to: ""
                },
              }
            },
            // ...Salary,
            // ...Allowance,
          ]
        },
        
        //*************** BENEFITS */
        {
          path: 'benefits/insurances',
          name: 'Insurance',
          component: Insurance,
          meta:{
            breadcrumbs: [
              {
              text: 'Trang chủ',
              disabled: false,
              to: {
                  name: "Home",
                  }
              },
              {
              text: 'Quản lí bảo hiểm ',
              disabled: true,
              to: {
                  name: "Insurance",
                  }
              },
            ],
          }
        },
        {
          path: 'benefits/contracts',
          name: 'Contract',
          component: Contract,
          meta: {
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
          }
        },
        {
          path: 'benefits/contracts-v2/new',
          name: 'Contractv2',
          component: Contractv2,
          meta: {
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
          }
        },
        {
          path: 'benefits/contracts-v2/pending',
          name: 'Contractv2Pending',
          component: Contractv2Pending,
          meta: {
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
          }
        },
        {
          path: 'benefits/contracts-v2/ready',
          name: 'Contractv2Ready',
          component: Contractv2Ready,
          meta: {
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
          }
        },
        {
          path: 'benefits/contracts-v2/return',
          name: 'Contractv2Return',
          component: Contractv2Return,
          meta: {
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
          }
        },
        {
          path: 'benefits/contracts-v2/complete',
          name: 'Contractv2Complete',
          component: Contractv2Complete,
          meta: {
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
          }
        },
        {
          path: 'benefits/contracts-v2/resign',
          name: 'Contractv2Resign',
          component: Contractv2Resign,
          meta: {
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
          }
        },
        {
          path: 'benefits/benefit-manager',
          name: 'BenefitManager',
          component: BenefitManager,
          meta: { 
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Cấp phát nhân viên',
                disabled: true,
                to: {
                    name: "EmployeeIssue",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
          }
        },
        
        {
          path: 'benefits/employee-issue',
          name: 'EmployeeIssue',
          component: EmployeeIssue,
          meta: { 
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Cấp phát nhân viên',
                disabled: true,
                to: {
                    name: "EmployeeIssue",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
          }
        },
        
        ...LeaveEnroll,
      ]
    },
    
  ]
