
import http from '@/components/services/http-common';
import CreateButton from '@/components/Crud/CreateButton';
import DataFilter from '@/components/DataFilter';
import CreateDialog from '../Create.vue';
import CreateDialog2 from '../Create2.vue';
import * as moment from "moment/moment"

export default {
    name: "Attendance",
    components: {
        CreateButton,
        'data-filter': DataFilter,
        'create-dialog': CreateDialog,
        'create-dialog2': CreateDialog2,

    },
    props: ["breadcrumbs", "icon"],
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        dialogYearlyExport(val)
        {
            if(val){

            }
            else{
                this.dialogYearlyExportFilter.employeeCodes = [],
                this.dialogYearlyExportFilter.departmentCodes = []
            }
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        // filters: {
        //     handler (val) {
        //         this.tableParams.page=1;
        //         this.fetchData();
        //     },
        //     deep: true,
        // },

    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data () {
        return {
            ROOT_URL: "leaveEnrollments/",

            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                deparmentCode: null,
                amountType: "",
            },
            amountTypes: [{text: "Được cấp", value:"gt"}, {text: "Sử dụng", value:"lt"},{text: "Tất cả", value:""}],
            departmentSearch:null,
            departments: [],
            leavePlans:[],
            rowCount: 0,

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:["DepartmentTypeCode"],
                
                from: undefined,
                to: undefined,
                fromOns: [
                ],
                fromOn: "",
            },
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,

            dialog: false, item: null,
            dialogDelete: false,
            targetItem: undefined,
            exportPrompt: false,
            headers: [
                { text: 'Thao tác', value: 'actions', width: '7rem'},
                // { text: 'Mã', align: 'start', value: 'code', width: 200},
                { text: 'Mã nhân viên', align: 'start', value: 'employeeCode', width: 100},
                { text: 'Nhân viên', align: 'start', value: 'employeeName', width: 200},
                { text: 'Phòng', align: 'start', value: 'department', width: 200},
                { text: 'Phép', align: 'start', value: 'leavePlanName', width: 200},
                { text: 'Số lượng', align: 'start', value: 'total', width: 200},

                // { text: 'Hiện tại', value: 'isCurrent', width: 200},
                { text: 'Tạo vào', value: 'createdAt', width: 200 },
                { text: 'Thời hạn từ', value: 'validFrom', width: 200},
                { text: 'Thời hạn đến', value: 'validTo', width: 200},
            ],

            dialogYearlyExport: false,
            dialogYearlyExportFilter: {
                employees:[],
                departments:[],
            },
            dialogYearlyExportFilterData: {
                employeeCodes:[],
                departmentCodes:[],
            },
            dialogReportExport: false,
            dialogReportExportData: {
                departmentCode: "",
                employeeCode:"" 
            },
            
            monthlyDialog: false,
            monthlyForm: {
                month: ""
            }
        }
    },
    methods: {
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchDepartment() {
            return http.get("departments/fetch", {
                params: {
                    search: this.departmentSearch
                }
            }).then(res => {
                this.departments = res.data;
            })
        },
        fetchLeavePlan() {
            return http.get("leavePlans").then(res => {
                this.leavePlans = res.data;
                console.log(res.data)
            })
        },
        fetchData () {
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                this.isLoading = false;
                this.items = res.data.results;
                this.rowCount = res.data.rowCount;
            })
            .catch(err => {

            })
        },
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            console.log(this.targetItem);
            return http.delete(this.ROOT_URL+this.targetItem.employeeCode+"/"+this.targetItem.leavePlanCode).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
        monthlyDistribute() {
            return http.get("leaveEnrollments/monthly-distribute", {
                params: this.monthlyForm
            }).then(r => {this.monthlyDialog=false})
        },
        reportExport() {
            return http.get("leaveEnrollments/report-export", {
                // params: this.exportWorkingFilter,
                responseType: 'blob',
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `DANH_SACH_PHEP.xlsx`);
                document.body.appendChild(link);
                link.click();
            });
        }
    },
    created () {
        this.fetchData();
        this.fetchLeavePlan();
    }
}