<template>
    <v-btn @click="$emit('click')" icon class="rounded mx-1" large color="teal"><v-icon>mdi-import</v-icon></v-btn>
</template>
<script>
export default {
    props:['on', 'value'],
    mounted(){
    }
}
</script>
