<template>
    <div class="User">
        <!-- <myBreadcrumbs :urls="JSON.stringify(breadcrumbs)" :icon="icon" >
        </myBreadcrumbs> -->
        <v-card class="mb-5">
            <v-card-title>
                Lịch sử 
            </v-card-title>
            <v-card-text>
                <v-data-table :headers="headerLogs" :items="logs">
                    <template v-slot:[`item.updatedAt`]="{ item }">
                        {{ formatDate(item.updatedAt, true) }}
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <create-dialog ref="createDialog" v-model="dialog"></create-dialog>

        <!-- Datatable -->
        <v-card>
            <v-card-title>
            <v-spacer></v-spacer>
            <create-button @click="openDialog"></create-button>
            </v-card-title>
            <v-data-table height="600px" :mobile-breakpoint="0"
            :headers="headers"
            :items="items"
            :page.sync="tableParams.page"
            :sort-by.sync="tableParams.sortBy"
            :sort-desc.sync="tableParams.sortDesc"
            :server-items-length="rowCount"
            >
                <template v-slot:[`body.prepend`]="" >
                    <tr class="filter">
                        <td></td>
                        <td>
                            <v-text-field v-model="filters.employeeCode" label="Nhập để tìm kiếm" dense outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field v-model="filters.employeeName" label="Nhập để tìm kiếm" dense outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-autocomplete v-model="filters.departmentCode" :items="departments" item-value="code" item-text="name" clearable label="Nhập để tìm kiếm" dense outlined hide-details></v-autocomplete>
                        </td>
                        <td>
                            <v-text-field v-model="filters.code" label="Nhập để tìm kiếm" dense outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field v-model="filters.userName" label="Nhập để tìm kiếm" dense outlined hide-details></v-text-field>
                        </td>
                        <td colspan="100%"></td>
                    </tr>
                </template>
                <template v-slot:[`item.userRoles`]="{ item }">
                    <v-chip v-for="role in item.userRoles" :key="role.roleCode" color="light-green" dark class="ma-2 caption font-weight-bold" label>
                        {{role.roleName}}
                    </v-chip>
                </template>
                <template v-slot:[`item.isActive`]="{ item }">
                    <v-icon v-if="item.isActive" color="success">mdi-check</v-icon>
                    <v-icon v-else color="error">mdi-close</v-icon>
                </template>
                <template v-slot:[`item.usedDevice`]="{ item }">
                    
                    <v-tooltip left eager v-if="!!item.usedDevice">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="black" dark  v-if="item.usedDevice.length>16" @click="clearDevice(item)"
                            v-bind="attrs" v-on="on" icon>
                                <v-icon>mdi-apple</v-icon>
                            </v-btn>
                            <v-btn color="light-green" dark v-else @click="clearDevice(item)"
                            v-bind="attrs" v-on="on" icon>
                                <v-icon>mdi-android</v-icon>
                            </v-btn>
                        </template>
                    <span>{{item.usedDevice}}</span>
                    </v-tooltip>
                </template>
                <template v-slot:[`item.departmentName`]="{ item }">
                    <v-chip class="ma-2 caption font-weight-bold" color="orange" label text-color="white" >
                        <v-icon left>
                            mdi-store
                        </v-icon>
                        {{item.departmentName}}
                    </v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <edit-button @click="editItem(item)"></edit-button>
                    <delete-button @click="deleteItem(item)"></delete-button>
                </template>
            </v-data-table>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>

import CreateButton from '@/components/Crud/CreateButton';
import createDialog from './Create'
import http from '@/components/services/http-common';
import ApiService from '@/components/services/ApiServices';
import * as moment from "moment/moment"

const ClearDeviceURL = "users/clear-device/"

export default {
    name: "User-index",
    components: {
        CreateButton,
        "create-dialog": createDialog
    },
    computed:{
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    watch: {
        options: {
        handler (val) {
            this.fetchData();
        },
        deep: true,
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler: _.debounce(function(){
                    this.tableParams.page=1;
                    this.fetchData();
                }, 500),
            deep: true,
        },
    },
    methods: {
        
        formatDate(value, hasTime = false) {
            if(hasTime == false)
                return moment(value).format("DD/MM/YYYY");
            else 
                return moment(value).format("DD/MM/YYYY hh:mm:ss");
        },
        openDialog(){
            this.dialog = true;
        },
        fetchData() {
            return http.get('users',{
                params: this.params
            }).then(res => {
                this.items = res.data.results;
                this.rowCount = res.data.rowCount;
            }).catch(err => {

            })
        },
        fetchDepartment(){
            ApiService.fetchDepartment().then(res => {
                this.departments = res.data;
            })
        },

        fetchLogs() {
            return http.get("users/fetch/log").then(res => {
                this.logs = res.data.logs
            })
        },

        cfmDeleteItem() {
            http.delete("users/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                console.log(err);
            });
        },
        editItem(item) {
            this.$refs.createDialog.isUpdate = true;
            this.$refs.createDialog.item = item;
            this.$refs.createDialog.fetchUpdate(item.code);
            this.$refs.createDialog.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        clearDevice(item)
        {
            console.log("111")
            return http.get(ClearDeviceURL+item.code).then(res => {
                this.fetchData();
            })
        }
    },
    mounted () {
        this.fetchDepartment()
        this.fetchData()
        this.fetchLogs()
    },
    data() {
        return {
            search: "",
            totalItem: 0,
            isLoading: true,
            dialogDelete: false,
            dialog: false,
            // options: {},
            items: [],
            createDialog: false,
            
            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            departments:[],
            filters:{
                employeeCode:null,
                employeeName:null,
                code:null,
                userName:null,
            },
            rowCount: 0,
            
            headers: [
                { text: 'Thao tác', value: 'actions', sortable: false, width: '7rem'},
                { text: 'Mã nhân viên', value: 'employeeCode', width:150 },
                { text: 'Tên nhân viên', value: 'employeeName', width:200 },
                { text: 'Phòng', value: 'departmentName', width:200 },
                { text: 'Mã tài khoản', value: 'code',width: 150 },
                { text: 'Tên tài khoản', align: 'start', sortable: false, value: 'userName', width:200},
                { text: 'Kích hoạt', value: 'isActive', width:150 },
                { text: 'Phân quyền', value: 'userRoles', sortable: false},
                { text: 'Thiết bị đang sử dụng', value: 'usedDevice', sortable: false},
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            logs: [],
            headerLogs: [
                // { text: 'Thao tác', value: 'resource', sortable: false, width: '7rem'},
                { text: 'Hành động', value: 'action', width:150 },
                { text: 'Mã tài khoản', value: 'code', width:200 },
                { text: 'Cập nhật bởi', value: 'updatedBy', width:200 },
                { text: 'Vào lúc', value: 'updatedAt', width:200 },
            ]
        }
    }
}
</script>

<style lang="stylus" scoped>

</style>