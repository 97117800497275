<template>
    <v-tooltip bottom color="primary" z-index="3">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" small @click="$emit('click')" class="ma-1" icon>
            <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span> Chi tiết</span>
    </v-tooltip>
</template>
<script>
export default {
    
}
</script>