<template>
    <div class="time-sheet">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)"></myBreadCrumb> -->
        <v-card class="mb-6">
            <v-card-title>
                Danh sách giờ công
                <v-spacer></v-spacer>

                <v-btn small color="black" :to="{name: 'TimeSheet2'}" class="mx-2" outlined><v-icon>mdi-view-list</v-icon></v-btn>

                <v-btn color="green" small outlined class="mx-2" @click="exportPrompt=true"> <v-icon left>mdi-microsoft-excel</v-icon> Xuất excel </v-btn>

                <v-btn color="warning" small @click="fetchData" outlined> <v-icon left>mdi-refresh</v-icon>Refresh</v-btn>
                
                <v-card-text>
                    <v-row>
                        <v-col cols="6" md="2">
                            <v-text-field type="date" v-model="params.from" hide-details label="Từ ngày" outlined persistent-placeholder></v-text-field>
                        </v-col>
                        <v-col cols="6" md="2">
                            <v-text-field type="date" v-model="params.to" hide-details label="Đến ngày" outlined persistent-placeholder></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2">
                            <v-select :items="[
                                {text: 'Mã NS', value:'EmployeeCode'},
                                {text: 'Tên NS', value:'EmployeeName'},
                            ]" label="Chọn" outlined hide-details persistent-placeholder v-model="params.searchAttrs[0]"></v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field hide-details persistent-placeholder outlined label="Tìm kiếm" v-model="params.searchs[0]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-autocomplete :items="filter.FilterAttrs[0].items" item-text="name" item-value="code" label="Phòng ban"
                            hide-details outlined persistent-placeholder v-model="params.searchs[1]" clearable @change="nullFix">
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-autocomplete :items="filter.FilterAttrs[1].items" item-text="name" item-value="code" label="Chức danh"
                            hide-details outlined persistent-placeholder v-model="params.searchs[2]" clearable @change="nullFix">
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card-title>
            <v-card-actions>
                <v-btn small color="teal darken-4" block dark @click="fetchData(true)"><v-icon left>mdi-magnify</v-icon> Tìm kiếm</v-btn>
            </v-card-actions>
        </v-card>
        <v-card>
            <v-card-title> 
                <!-- <span class="d-none d-md-inline ">Danh sách giờ công</span><v-spacer></v-spacer> -->
                <!-- <refresh-button @click="fetchData"></refresh-button> -->
                <!-- <v-btn icon @click="mTimesheetDialog=!mTimesheetDialog"><v-icon>mdi-calendar-check-outline</v-icon></v-btn> -->
                <v-dialog v-model="mTimesheetDialog" max-width="600">
                    <v-card>
                        <v-card-title>Bảng công tháng</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <month-picker mTimesheet.month label="ssss"></month-picker>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <!-- <excel-button @click="exportPrompt=true"></excel-button> -->
                <export-prompt v-model="exportPrompt" url="Timesheets" fileName="BANG_CHAM_CONG">
                </export-prompt>
                <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData(true)">
                </data-filter> -->
                <!-- <v-btn small color="grey lighten-5" :to="{name: 'TimeSheet2'}" class="mx-1"><v-icon>mdi-view-list</v-icon></v-btn> -->
            </v-card-title>
            <v-data-table
            fixed-header
            height="62vh"
            :mobile-breakpoint="0"
            :headers="combinedHeaders"
            :items="mappedTableData"
            :options.sync="options"
            :server-items-length="totalItem"
            :footer-props="{'items-per-page-options': [15, 30, 50, 100]}"
            >
            
                <template v-slot:[`item.name`]="{ item }">
                    {{item.name}}
                    <h5 :class="`font-weight-bold ${item.sum < item.totalHour ? 'error--text':'sucess--text'} `">{{item.sum}}/{{item.totalHour}}</h5>
                </template>
                <template v-for="combinedHeader in combinedHeaders.slice(2)"  v-slot:[`item.${combinedHeader.value}`]="{ item }">
                    <div :key="combinedHeader.value" @click="openDetail(item[combinedHeader.value]?item[combinedHeader.value].code:null, combinedHeader.value, item.code)">
                        <v-hover v-slot="{ hover }" >
                            <v-alert :class=" `mx-auto my-auto text-center elevation-${hover ? 3 : 0}`" style="cursor:pointer;"  v-if="item[combinedHeader.value]" 
                                outlined dense text :color="item[combinedHeader.value].isLock? 'secondary': item[combinedHeader.value].isUnder?'orange': 'primary'" width="100px">
                                <v-badge color="error" content="chưa XN" overlap :value="!item[combinedHeader.value].isConfirmed">
                                    <div class="caption">
                                        <span class="green--text">{{ (item[combinedHeader.value].totalSalaryTime) + "h"}}</span> | 
                                        <span class="cyan--text">{{ (item[combinedHeader.value].totalOverTime) + "h"}}</span> |
                                        <span class="pink--text">{{ item[combinedHeader.value].totalLeaveTime + "h"}}</span>  
                                        </div>
                                    <p class="orange--text">{{ item[combinedHeader.value].symbol}}</p>
                                </v-badge>
                            </v-alert>
                            <v-alert :class=" `mx-auto my-auto text-center elevation-${hover ? 3 : 0}`" style="cursor:pointer;" width="100px" v-else
                                        outlined dense
                                        color="success">
                                <v-icon>mdi-plus</v-icon>
                            </v-alert>
                        </v-hover>
                    </div>
                </template>
            </v-data-table>

            <!-- ************** TIMESHEET DETAIL *************** -->
            <v-dialog v-model="detailDialog" scrollable width="650px" @click:outside="closeDetail">
                <v-card>
                    <v-card-title>Chi tiết giờ công <v-spacer></v-spacer> <v-icon @click="openDetail">mdi-reload</v-icon>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <!-- ENROLLMENT -->
                            <v-subheader class="pl-0">Chấm công:</v-subheader>
                            <v-data-table :items="detailItem.enrollments" dense :headers='[
                                                                            { text: "Giờ checkin", value: "checkinTime"},
                                                                            { text: "Giờ checkout", value: "checkoutTime"},
                                                                            { text: "IP checkin", value: "ipIn"},
                                                                            { text: "IP checkout", value: "ipOut"},
                                                                            { text: "Trễ", value: "late"},
                                                                            { text: "Sớm", value: "early"}
                                                                        ]'>
                                <template v-slot:[`footer.prepend`]>
                                    Số giờ ghi nhận: <span class="ml-2 font-weight-bold">{{Math.round(detailItem.workTime * 100) / 100}}</span>
                                </template>
                            </v-data-table>
                            <v-row>
                                <v-col cols="12">
                                    <v-subheader :class="(detailItem.isConfirmed?'success': 'error')+'--text'" ><h3>{{detailItem.isConfirmed ? 'Xác nhận' : 'Chưa xác nhận'}}</h3></v-subheader>
                                    <!-- <v-switch v-model="detailItem.isConfirmed" label="Xác nhận" hide-details></v-switch> -->
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field label="Tổng giờ đã làm" type="number" v-model="detailItem.actualWorkTime" min="0" :disabled="false" hide-details outlined>
                                        <template v-slot:[`append`]="">
                                            <v-icon color="blue">mdi-clock</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn small color="primary" class="my-3 mx-2" @click="acceptTimesheet">Xác nhận</v-btn>
                                    <v-btn small outlined color="error" class="my-3 mx-2" @click="cancelTimesheet">Hủy xác nhận</v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <small class="error--text">Các trường mang dấu * không thể thay đổi</small>
                                    <v-simple-table dense>
                                        <template v-slot:default>
                                        <thead>
                                            <tr>
                                            <th class="text-left">
                                                Loại
                                            </th>
                                            <th class="text-left">
                                                Số giờ
                                            </th>
                                            <th class="text-left" colspan="2">
                                                Cơm
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="success--text">Làm việc</td>
                                                <td>{{detailItem.validActualTime}}</td>
                                                <td>{{detailItem.lunch}}</td>
                                                <td rowspan="2">{{detailItem.lunch + detailItem.overtimeLunch}}</td>
                                            </tr>
                                            <tr>
                                                <td class="orange--text">Tăng ca</td>
                                                <td>{{detailItem.overtime}}</td>
                                                <td>{{detailItem.overtimeLunch}}</td>
                                            </tr>
                                            <!-- <tr
                                            v-for="item in desserts"
                                            :key="item.name"
                                            >
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.calories }}</td>
                                            </tr> -->
                                        </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                                <!-- <v-col cols="6">
                                    <v-text-field label="Giờ làm việc *" type="number" readonly v-model="detailItem.validActualTime" min=0 color="error" hide-details outlined dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field label="Cơm *" type="number" readonly v-model="detailItem.lunch" min=0 color="error" hide-details outlined dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field label="Tăng ca *" type="number" readonly v-model="detailItem.overtime" min=0 color="error" hide-details outlined dense>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field label="Cơm tăng ca *" type="number" readonly v-model="detailItem.overtimeLunch" min=0 color="error" hide-details outlined dense>
                                    </v-text-field>
                                </v-col> -->
                                <v-col cols="12">
                                    <v-text-field label="Nghỉ phép *" type="number" readonly v-model="detailItem.leaveTime" min=0 color="error">
                                        <template v-slot:[`append`]="">
                                            <v-icon color="pink">mdi-umbrella-beach</v-icon>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-subheader>Phiếu tăng ca:</v-subheader>
                                <v-col cols="12" class="py-0">
                                    <v-chip class="ma-2"  color="error" text-color="white" @click="overtimeOpen"  v-if="!!overtimeCode">
                                        <v-avatar left>
                                            <v-icon>mdi-av-timer</v-icon>
                                        </v-avatar>
                                        Phiếu làm thêm
                                    </v-chip>
                                    <v-chip class="ma-2" color="error" text-color="white" @click="overtimeOpen"  v-else>
                                        <v-avatar left>
                                            <v-icon>mdi-plus</v-icon>
                                        </v-avatar>
                                        Phiếu làm thêm
                                    </v-chip>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea label="Ghi chú" v-model="detailItem.note" outlined ></v-textarea>
                                </v-col>
                                <v-subheader class="font-weight-bold" v-if="detailItem.isConfirmed">
                                    Lần cuối xác nhận
                                </v-subheader>
                                <v-col cols="6" v-if="detailItem.isConfirmed">
                                    <v-text-field label="Người xác nhận" type="text" readonly v-model="detailItem.acceptedBy" min=0 color="error">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6" v-if="detailItem.isConfirmed">
                                    <v-text-field label="Thời gian xác nhận" type="text" readonly v-model="detailItem.acceptedAt" min=0 color="error">
                                    </v-text-field>
                                </v-col>
                                <div class="d-block" style="width: 100%;">
                                    <v-divider></v-divider>
                                </div>
                                <v-subheader class="font-weight-bold">
                                    Lịch sử xác nhận <span class="green--text ml-1">mới</span>
                                </v-subheader>
                                <v-col cols="12">
                                    <v-simple-table dense>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Hành động
                                                    </th>
                                                    <th class="text-left">
                                                        Người thao tác
                                                    </th>
                                                    <th class="text-left">
                                                        Ngày thao tác
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, key) in confirmHistory" :key="key">
                                                    <td>{{ item.action == 'CONFIRM' ? 'Xác nhận' : 'Bỏ xác nhận' }}</td>
                                                    <td>{{ item.updatedBy }}</td>
                                                    <td>{{ formatDateTime(item.updatedAt) }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeDetail">Thoát</v-btn>
                        <v-btn text @click="saveData" class="mr-2">OK</v-btn>
                        
                        <v-dialog max-width="600" >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="error" v-bind="attrs" v-on="on" >
                                    Xóa
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-card>
                                    <v-toolbar color="primary" dark flat>
                                        Xóa giờ công
                                    </v-toolbar>
                                    <v-card-actions class="justify-end">
                                        <v-spacer></v-spacer>
                                        <v-btn text @click="deleteItem(), dialog.value = false " >Xóa công</v-btn>
                                        <v-btn text @click="dialog.value = false" >Hủy</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <create-dialog v-model="overtimeDialog" @CloseDialog="overtimeClose" @fetchData="fetchData"></create-dialog>
        </v-card>
    </div>
</template>

<script>

import myBreadCrumb from '@/components/BreadCrumb';
import moment from 'moment/moment';
import http from '@/components/services/http-common';
import DatePicker from '@/components/DatePicker';
import DataFilter from '@/components/DataFilter';
import EventBus  from "@/components/services/EventBus";
import Create from '@/views/Request/Overtime/Create';
// import * as moment from "moment/moment"

export default {
    name: "TimeSheet",
    components: {
        myBreadCrumb,
        "create-dialog": Create,
        "date-picker": DatePicker,
        "data-filter": DataFilter
    },
    computed: {
        combinedHeaders() {
            let dateHeaders = [];

            let from = new Date(this.params.from);
            let to = new Date(this.params.to);
            while(from.getTime() <= to.getTime())
            {
                var d = from.getDay();
                d = d == 0 ? "CN" : "T" + String(d + 1);
                var dd = String(from.getDate()).padStart(2, '0');
                var mm = String(from.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = from.getFullYear();

                dateHeaders.push({
                    text: `${dd}/${mm}/${yyyy} - ${d}`,
                    value: `${dd}${mm}${yyyy}`,
                    align: "center",
                    sortable:false,
                    cellClass: 'small-cell'
                });
                from.setDate(from.getDate() + 1);
            }
            return this.fixedHeaders.concat(dateHeaders.reverse());
        },
        mappedTableData () {
            var result = this.tableData.map( e => {
                let Obj = {};
                Obj.name = e.name;
                Obj.code = e.code;
                Obj.sum = e.sum;
                Obj.totalHour = e.totalHour;
                e.timesheets.forEach(i => {
                    Obj[i.workDateStr] = {
                        isLock: i.isLock,
                        isUnder: i.isUnder,
                        code: i.code,
                        symbol: i.symbol,
                        isConfirmed: i.isConfirmed,
                        totalTime: i.totalTime,
                        totalSalaryTime: i.totalSalaryTime,
                        totalHolidaySalaryTime: i.totalHolidaySalaryTime,
                        totalExtendedTime: i.totalExtendedTime,
                        totalLeaveTime: i.totalLeaveTime,
                        totalOverTime: i.totalOverTime,
                        totalHolidayOverTime: i.totalHolidayOverTime,
                        validActualTime: i.validActualTime,
                        };
                });
                return Obj;
            })
            return result;
        },
    },
    watch: {
        options: {
        handler (val) {
            this.fetchData();
        },
        deep: true,

        },
        detailDialog (val) {
            if (val)
            {
                return val;
            }
            else
                this.closeDetail();
        },
    },
    data () {
        return {
            TIMESHEET_URL: "timesheets",
            exportPrompt: false,
            mTimesheetDialog:false,
            //FILTER
            params: {
                sortBy: [],
                sortDesc: [],
                page: "",
                itemsPerPage: "",
                searchs: ["", ""],
                searchAttrs: ['EmployeeCode', 'DepartmentCode', 'PositionStateCode'],
                underOnly:false,

                fromOn: "",
                from: moment().startOf("month").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
            },

            filter: {
                menu: false,
                search: "",
                searchAttr: "EmployeeName",
                department: "",
                underOnly: false,

                from: moment().startOf("month").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
                fromOns: [
                    {text: "Ngày làm việc", value: "workDate"}
                ],
                fromOn: "workDate",

                searchAttrs: [
                    {text: "Tên nhân viên", value: "EmployeeName"},
                    {text: "MSNV", value: "employeeCode"},
                ],
                FilterAttrs: [
                    {text: "Phòng", items: [] },
                    {text: "Chức danh", items: [] }
                ],
                FilterOn:["DepartmentCode"],

            },
            
            //DETAIL ITEM
            detailItem: {
                employeeCode:null,
                code: "",
                shiftHour:0,
                actualWorkTime: 0,
                holidayActualTime: 0,
                overtime: 0,
                holidayOvertime: 0,
                leaveTime: 0,
                isConfirmed: false,
                lunch:0,
                overtimeLunch:0,
                late: 0,
                early: 0,
                enrollments: [],
                workDateStr:"",
                validActualTime:0,
                note:"",
                acceptedBy:"",
                acceptedAt:"",
            },
            defaultDetailItem: {
                employeeCode:null,
                code: "",
                actualWorkTime: 0,
                holidayActualTime: 0,
                overtime: 0,
                holidayOvertime: 0,
                leaveTime: 0,
                isConfirmed: false,
                lunch:0,
                overtimeLunch:0,
                late: 0,
                early: 0,
                enrollments: [],
                workDateStr:"",
                validActualTime:0,
                note:"",
                acceptedBy:"",
                acceptedAt:"",
            },
            detailDialog: false,
            overtimeCode:"",
            overtimeDialog:{
                state: false,
                timesheet:true,
                item:{
                    adminEmployeeCode:""
                }
            },
            confirmHistory: [],

            // DATATABLE
            options: {},
            totalItem: 0,
            filters: {
                FromDate: moment().startOf("month").format("YYYY-MM-DD"),
                ToDate: moment().endOf("month").format("YYYY-MM-DD"),
                DepartmentCode: "",
            },
            Departments: [],
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Danh sách giờ công',
                disabled: true
                },
            ],
            
            headers: [],
            fixedHeaders: [
                { text: 'Mã nhân viên', value: 'code', width: "150px", sortable: false, width:"110px"},
                { text: 'Tên nhân viên', align: 'start', sortable: false, value: 'name', width: "150px", divider: true, },
            ],

            tableData: [],

        }
    },
    methods: {
        nullFix(value) {
            if(value == null)
                this.params.searchs[1]=''
            
        },
        getConfirmHistory(itemCode) {
            return http.get('timesheets/confirm-history/'+itemCode).then(r => {
                return r.data
            })
        },
        updateTimesheetExport () {
        },
        overtimeOpen() {
            this.overtimeDialog.state=true;
            if(this.detailItem)
            {
                this.overtimeDialog.timesheet= true;
                this.overtimeDialog.item.adminEmployeeCode = this.detailItem.employeeCode;
                this.overtimeDialog.item.date = moment(this.detailItem.workDate, "YYYY-MM-DD").format("YYYY-MM-DD");
                if(!!this.overtimeCode)
                {
                    this.overtimeDialog.item.code=this.overtimeCode
                }
            }
        },
        overtimeClose()
        {
            this.overtimeDialog.state=false;

        },
        formatTime(value) {
            return moment(value).format("HH:mm:ss");
        },
        formatDateTime(value) {
            return moment(value).format("DD/MM/YY HH:mm:ss");
        },
        initDateRange() {
            var curr = new Date(); // get current date
            var dayms = (24*60*60*1000) *  curr.getDay();
            var lastms = (24*60*60*1000) *  6;

            var first = moment().startOf("month").format("YYYY-MM-DD"); // First day is the day of the month - the day of the week
            var last = moment().endOf("month").format("YYYY-MM-DD"); // last day is the first day + 6
            // var firstdayStr = first.toISOString().slice(0, 10);
            // var lastdayStr = last.toISOString().slice(0, 10);
            this.filter.from = first;
            this.filter.to = last;
            this.params.from = first;
            this.params.to = last;
        },
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },
        // DETAIL DIALOG
        openDetail(itemCode, dateCode, employeeCode) {
            this.detailItem.workDateStr = dateCode;
            this.detailItem.employeeCode = employeeCode;
            if(itemCode)
            {
                http.get(this.TIMESHEET_URL +"/"+ itemCode)
                .then((res) => {
                    let data= res.data;
                    this.detailItem = Object.assign({}, data.item);
                    this.detailItem.enrollments = data.enrollments;
                    this.detailItem.workDateStr = dateCode;
                    this.overtimeCode = res.data.phieu_lam_them_str;
                    
                })
                .catch((err) => {
                    console.log(err)
                })
                this.getConfirmHistory(itemCode).then(d => {
                    console.log(d)
                    this.confirmHistory = d.data
                })
            }
            this.detailDialog = true;
        },

        closeDetail () {
            this.detailItem = Object.assign({}, this.defaultDetailItem);
            this.detailDialog = false;
        },
        deleteItem(){
            return http.delete(this.TIMESHEET_URL+"/"+this.detailItem.code).then(res=>{
                this.fetchData();
            })
        },

        // DATALIST
        initDates (val) {
        },

        // CREATE DATA 
        acceptTimesheet() {
            this.detailItem.isConfirmed = true;
            this.saveData()
        },
        cancelTimesheet() {
            this.detailItem.isConfirmed = false;
            this.saveData()
        },

        saveData () {
            let isEdit = !!this.detailItem.code;
            if(isEdit)
            {
                delete this.detailItem.enrollments;
                return http.put(this.TIMESHEET_URL + "/" + this.detailItem.code, this.detailItem)
                .then(res => {
                    this.fetchData();
                    let eventObj = {
                        color: "success",
                        message: "Lưu dữ liệu thành công",
                        toast: true,
                    };
                    this.messageToast(eventObj);
                })
                .catch(err => { console.log(err)
                });
            }
            else
            {
                let form = {
                    employeeCode: this.detailItem.employeeCode,
                    workDate: moment(this.detailItem.workDateStr, "DDMMYYYY").format("YYYY-MM-DD"),
                    isConfirmed: this.detailItem.isConfirmed,
                    actualWorkTime: this.detailItem.actualWorkTime,
                    overtime: this.detailItem.overtime,
                    leaveTime: this.detailItem.leaveTime,
                    holidayOvertime: this.detailItem.holidayOvertime,
                    holidayActualTime: this.detailItem.holidayActualTime,
                    note: this.detailItem.note
                }
                return http.post(this.TIMESHEET_URL, form).then(res => {
                    this.fetchData();
                    let eventObj = {
                        color: "success",
                        message: "Lưu dữ liệu thành công",
                        toast: true,
                    };
                    this.messageToast(eventObj);
                });
            }
        },

        // ********* FETCH SECTION ************
        fetchData (search = false) {
            if(search)
                this.options.page=1;
            const {  page, itemsPerPage } = this.options;
            this.params =  { ...this.params, 
                                sortBy: this.options.sortBy, //array
                                sortDesc: this.options.sortDesc, //array
                                page: this.options.page,
                                pageSize: this.options.itemsPerPage,
                                };

            return http.get('timesheets', {
                params: this.params
            }).then(res => {
                this.totalItem = res.data.totalItem;
                this.initDates();
                this.tableData = res.data.items;
                console.log(this.tableData)
            })
            .catch(err => {
                console.log(err);
            })
        },
        fetchDepartment(){
            return http.get("departments?dropDown=1")
            .then(res => {
                this.filter.FilterAttrs[0]={text: "Phòng", items: res.data };
            })
            .catch(err => {
                console.log(err)
            });
        },

        fetchPositionState() {
            return http.get("positionStates")
            .then(res => {
                this.filter.FilterAttrs[1]={text: "Chức danh", items: res.data };
            })
            .catch(err => {
                console.log(err)
            });
        }

    },
    created () {
        this.fetchDepartment();
        this.fetchPositionState()
        // this.initDates();
        this.initDateRange();
        this.fetchData();
    }
}
</script>

<style scoped>
    tbody tr:hover {
        background-color: transparent !important;
    }
    
    @media screen and (min-width: 600px) {
        ::v-deep table > thead > tr > th:nth-child(1),
        ::v-deep table > tbody > tr > td:nth-child(1) {
            position: sticky !important; 
            position: -webkit-sticky !important; 
            left: 0;
            /* margin-left: -100px !important; */
            z-index: 3;
            background: white;
        }

        ::v-deep table > thead > tr > th:nth-child(2),
        ::v-deep table > tbody > tr > td:nth-child(2){
            position: sticky !important; 
            position: -webkit-sticky !important; 
            left: 110px !important; 
            z-index: 3;
            background: white;
        }
    }
    
    ::v-deep table > thead > tr > th:nth-child(1) {
        z-index: 4;
    }
    ::v-deep table > thead > tr > th:nth-child(2) {
        z-index: 4;
    }
    
    .small-cell {
        width: 20px !important;
        min-width: 0px !important;
    }
    
    @media screen and (max-width: 600px) {
        ::v-deep table{
            zoom: 0.6;
        }
    }
</style>