<template>
    <v-tooltip bottom color="error">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" @click="$emit('click')" class="ma-1" color="error" icon>
            <v-icon small>mdi-trash-can</v-icon> 
        </v-btn>
      </template>
      <span> Xóa</span>
    </v-tooltip>
</template>
<script>
export default {
    
}
</script>