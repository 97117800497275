import OcSection from '@/views/layouts/SubLayouts/OcSection.vue';
import OcPropertyRequest from '@/views/Request/PropertyRequest/Index.vue';
import OcPropertyRequestConfirm from '@/views/Request/PropertyRequestConfirm/Index.vue';
import OcProperty from '@/views/Setting/Property/Index';
import OcEmployeeReport from '@/views/OcSection/EmployeeReport/Index';
import OcSupport from '@/views/OcSection/Support/Index';

import OcEmployeeReportCategory from '@/views/OcSection/EmployeeReportCategory/Index';
//*********** Hiring */
// import UserList from '@/views/ItSection/pages/User/UserList';
// import ItScheduleList from '@/views/ItSection/pages/ItSchedule/ItScheduleList';

export default [
    {
      path: 'oc-section',
      name: 'OcSection',
      component: OcSection,
      children:[
        // {
        //     path: 'image-report-category',
        //     name: 'ImageReportCategory',
        //     component: ImageReportCategory,
        // },
        
        {
          path: 'employee-report-category',
          name: 'OcEmployeeReportCategory',
          component: OcEmployeeReportCategory,
          meta: {
            breadcrumbs: [
              {
              text: 'Trang chủ',
              disabled: false,
              to: {
                  name: "Home",
                  }
              }
          ],
          }
        },
        {
          path: 'employee-report',
          name: 'OcEmployeeReport',
          component: OcEmployeeReport,
          meta: {
            breadcrumbs: [
              {
              text: 'Trang chủ',
              disabled: false,
              to: {
                  name: "Home",
                  }
              }
          ],
          }
        },
        
        {
          path: 'property-request',
          name: 'OcPropertyRequest',
          component: OcPropertyRequest,
          meta: {
            breadcrumbs: [
              {
              text: 'Trang chủ',
              disabled: false,
              to: {
                  name: "Home",
                  }
              }
          ],
          }
        },
        {
          path: 'properties',
          name: 'OcProperty',
          component: OcProperty,
          meta: { 
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },
          }
        },
        {
          path: 'property-request-confirm',
          name: 'OcPropertyRequestConfirm',
          component: OcPropertyRequestConfirm,
          meta: {
            breadcrumbs: [
              {
              text: 'Trang chủ',
              disabled: false,
              to: {
                  name: "Home",
                  }
              },
          ],
          }
        },

        {
          path: 'oc-support',
          name: 'OcSupport',
          component: OcSupport,
        }
      ]
    },
    
  ]
