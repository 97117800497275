<template>
    <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="inDate"
    transition="scale-transition"
    :offset-y="true"
    min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
            v-model="inDate" :dense="dense" :outlined="outlined"
            label="Chọn ngày"
            :prepend-icon="icon"
            readonly v-bind="attrs" v-on="on" hide-details
            ></v-text-field>
        </template>
        <v-date-picker v-model="inDate" no-title scrollable range locale="vi" color="teal" :readonly="readonly">
            <v-spacer></v-spacer>
            <v-btn text color="secondary" @click="menu = false" >
                Cancel
            </v-btn>
            <v-btn text color="orange"
            @click="apply" >
            OK
            </v-btn>
        </v-date-picker>
    </v-menu>
</template>
<script>
import * as moment from "moment/moment";
export default {
    name: "DatePickerRange",
    props:["value", "icon", "dense", "outlined", "readonly"],
    data() {
        return {
            menu:false,
            inDate: [],
        }
    },
    methods:{
        apply(){
            this.$refs.menu.save(this.inDate);
            this.$emit("input", this.inDate);
        }
    },
    created(){
        this.inDate = this.value
    }
}
</script>