import Employee from '@/views/HR/Employee/Index.vue';
import EmployeeResource from '@/views/HR/EmployeeResource/Index.vue';
import MonthlyEmployee from '@/views/HR/MonthlyEmployee/Index.vue';



export default [
    {
      path: 'employees',
      name: 'Employee',
      component: Employee,
      meta: { 
        breadcrumbs: [
            {
            text: 'Trang chủ',
            disabled: false,
            to: {
                name: "Home",
                }
            },
            {
            text: 'Danh sách nhân viên',
            disabled: true,
            to: {
                name: "Employee",
                }
            }
        ],
        icon:{
            icon: "mdi-plus-thick",
            to: ""
        },
      }
    },
    
    {
      path: 'monthly-employee',
      name: 'MonthlyEmployee',
      component: MonthlyEmployee,
      meta: { 
        breadcrumbs: [
            {
            text: 'Trang chủ',
            disabled: false,
            to: {
                name: "Home",
                }
            },
            {
            text: 'Danh sách nhân viên',
            disabled: true,
            to: {
                name: "Employee",
                }
            }
        ],
        icon:{
            icon: "mdi-plus-thick",
            to: ""
        },
      }
    },

    {
      path: 'employee-resource',
      name: 'EmployeeResource',
      component: EmployeeResource,
      meta: { 
        breadcrumbs: [
            {
            text: 'Trang chủ',
            disabled: false,
            to: {
                name: "Home",
                }
            },
            {
            text: 'Danh sách nhân viên',
            disabled: true,
            to: {
                name: "Employee",
                }
            }
        ],
        icon:{
            icon: "mdi-plus-thick",
            to: ""
        },
      }
    },
    
    // {
    //   path: 'employees/create',
    //   alias: ["hr/employees/create"],
    //   name: 'EmployeeCreate',
    //   component: EmployeeCreate,
    //   props: { 
    //     breadcrumbs: [
    //         {
    //         text: 'Trang chủ',
    //         disabled: false,
    //         to: {
    //             name: "Home",
    //             }
    //         },
    //         {
    //         text: 'Danh sách nhân viên',
    //         disabled: false,
    //         to: {
    //             name: "Employee",
    //             }
    //         },
    //         {
    //         text: 'Tạo mới nhân viên',
    //         disabled: true
    //         }
    //     ],
    //     icon:{
    //         icon: "mdi-content-save",
    //         text: "Lưu",
    //     },
    //   }
    // },
    // {
    //   path: 'employees/edit/:id',
    //   alias: ["hr/employees/edit/:id"],
    //   name: 'EmployeeEdit',
    //   component: EmployeeCreate,
    //   props: { 
    //     breadcrumbs: [
    //         {
    //         text: 'Trang chủ',
    //         disabled: false,
    //         to: {
    //             name: "Home",
    //             }
    //         },
    //         {
    //         text: 'Danh sách nhân viên',
    //         disabled: false,
    //         to: {
    //             name: "Employee",
    //             }
    //         },
    //         {
    //         text: 'Cập nhật nhân viên',
    //         disabled: true
    //         }
    //     ],
    //     icon:{
    //         icon: "mdi-content-save",
    //         text: "Lưu",
    //     },
    //   }
    // },
  ]
