<template>
    <div class="create-insurance">
        <v-dialog :value="value.state" @click:outside="close" @keydown.esc="close" max-width="80%">
             <v-card>
                <v-card-title>
                    {{ value.item === undefined ? "Tạo mới " : "Cập nhật "}} bảo hiểm
                </v-card-title>
                <v-form @submit.prevent="saveData" v-model="valid" ref="form">
                    <v-card-text>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                    clearable
                                    v-model="form.insuranceNumber"
                                    label="Số sổ BHXH"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-autocomplete v-model="form.employeeCode" :search-input="employeeSearch" @update:search-input="fetchEmployee" label="Nhân viên *" 
                                    hide-no-data :items="employees" item-text="name" item-value="code" :disabled="isUpdate">
                                        <template v-slot:item="data">
                                            <strong>{{ data.item.code }}: </strong> {{` ${data.item.name} - ${data.item.department}`}}
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                    clearable
                                    v-model="form.healthInsuranceNumber"
                                    label="Số thẻ BHYT"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <date-picker v-model="form.healthInsuranceDate" title="Hạn BHYT">
                                    </date-picker>
                                </v-col>
                                
                                <v-col cols="12" md="6">
                                    <date-picker v-model="form.registerDate" title="Ngày nộp sổ BHXH">
                                    </date-picker>
                                </v-col>
                                <v-col cols="12" md="6" v-if="form.status">
                                    <date-picker v-model="form.withdrawDate" title="Ngày trả sổ BHXH">
                                    </date-picker>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-switch label="Đã trả sổ BHXH" v-model="form.status">
                                    </v-switch>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="form.attachmentLink" label="Link file đính kèm">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="form.payAmount" label="Tiền đóng">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model="form.description"
                                        solo outlined flat
                                        label="Ghi chú/mổ tả"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn dark
                            color="blue darken-1"
                            type="submit"
                        >
                            Lưu
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-form>
             </v-card>
        </v-dialog>
        <user-dialog v-model="employeeDialog" @CloseDialog="employeeDialog = false" @rowSelected="rowHandler">
        </user-dialog>
    </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import http from '@/components/services/http-common';
import UserDialog from '../User/EmployeeDialog';
import EventBus  from "@/components/services/EventBus";
import ApiServices from '@/components/services/ApiServices';

export default {
    name: "CreateInsurance",
    components: {
        "date-picker": DatePicker,
        "user-dialog": UserDialog,
    },
    computed:{

    },
    watch: {
        value: {
            handler(val){
                console.log(val)
                if(val.item != undefined)
                    this.fetchData();
                if(val.state)
                    return val || this.close();
            }
        },
    },

    props: ["value"],
    data () {
        return {
            //URL
            ROOT_URL: "insurances",

            //DÂTA
            valid: false,
            ShiftObject: "",
            formData: {

            },
            form: {
                code: "AUTO",
                employeeName: "",
                employeeCode: "",
                healthInsuranceNumber: "",
                healthInsuranceDate: new Date().toISOString().substring(0,10),
                registerDate: new Date().toISOString().substring(0,10),
                withdrawDate: new Date().toISOString().substring(0,10),
                attachmentLink: "",
                description: "",
                payAmount: "",
            },

            defaultForm: {
                code: "AUTO",
                employeeName: "",
                healthInsuranceNumber: "",
                healthInsuranceDate: new Date().toISOString().substring(0,10),
                registerDate: new Date().toISOString().substring(0,10),
                withdrawDate: new Date().toISOString().substring(0,10),
                attachmentLink: "",
                description: "",
                payAmount: "",
            },

            //EMPLOYEE DIALOG
            employeeDialog: false,
            selectedField: "",
        }
    },
    methods: {
        
        fetchEmployee(val){
            ApiServices.fetchEmployee(val).then(res=>{
                this.employees = res.data;
            })
        },
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },

        formatTime(value) {
            return moment(value).format("HH:mm:ss");
        },

        close () {
            this.$emit("CloseDialog");
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        
        fetchData () {
            return http.get(this.ROOT_URL + "/" + this.value.item.code)
            .then(res => {
                //fetch result
                let item = res.data.item;
                let additional = res.data.additional;
                this.form = Object.assign(item, additional);
                this.form.registerDate = this.form.registerDate.toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}).substr(0, 10);
                this.form.withdrawDate = this.form.withdrawDate.toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}).substr(0, 10);
                this.form.healthInsuranceDate = this.form.healthInsuranceDate.toLocaleString("en-US", {timeZone: "Asia/Ho_Chi_Minh"}).substr(0, 10);
            })
            .catch(err => {
                console.log(err);
            });
        },
        saveData() {
            let  isValid = this.$refs.form.validate();
            console.log(isValid);
            if(isValid)
            {
                if(!this.value.item)
                {
                    http.post(this.ROOT_URL, this.form)
                    .then(res => {
                        let eventObj = {
                            color: "success",
                            message: "Tạo mới thành công",
                            toast: true,
                        };
                        this.messageToast(eventObj);
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                    });
                } else {
                    http.put(this.ROOT_URL + "/" + this.value.item.code, this.form)
                    .then(res => {
                        let eventObj = {
                            color: "success",
                            message: "Cập nhật thành công",
                            toast: true,
                        };
                        this.messageToast(eventObj);
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                        throw "Đã có lỗi xảy ra"
                    });
                }
                this.close();
            }
        },
        
        //Employee Dialog JS
        rowHandler (e) {
            console.log(e);
            this.form.employeeCode = e.code;
            this.form.employeeName = e.name;
        }
    },
    created() {
        this.fetchEmployee();
    }
}
</script>