<template>
    <div class="overview">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)"></myBreadCrumb> -->
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        Số người đang làm việc
                    </v-card-title>
                    
                    <chart ref="online" label="Biểu đồ số người hiện đang làm việc" class="mx-auto"
                    :chartData="chartOnlineEmployee" :ratio="this.$vuetify.breakpoint.name=='xs'?1:3"
                    chartType="line"
                    :scales="
                    {
                        yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero:true,
                            min: 0,
                            max: 100
                        }
                        }]
                    }
                    "
                    >
                    </chart>
                </v-card>
            </v-col>
            <!-- TOTAL EMP REPORT -->
            <v-col cols="12">
                <v-card>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-autocomplete color="orange" dense outlined :items='[{text: "Theo tháng", value: "MONTH"}]'  label="Chọn phòng" hide-no-data
                         v-model="paramsTotalEmp.type" hide-details clearable style="max-width:15rem">
                        </v-autocomplete>
                    </v-toolbar>
                    
                    <chart ref="chartTotalEmp" label="Biến đông nhân sự" class="mx-auto"
                    :chartData="chartDataTotalEmp" :ratio="this.$vuetify.breakpoint.name=='xs'?1:3"
                    chartType="bar" >
                    </chart>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <!-- <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark v-bind="attrs" v-on="on" small>
                                    Chọn thời gian
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item @click="setMonth">
                                    <v-list-title>Tháng</v-list-title>
                                </v-list-item>
                                <v-list-item @click="setQuarter">
                                    <v-list-title >Quý</v-list-title>
                                </v-list-item>
                                <v-list-item @click="timeDialog=true">
                                    <v-list-title>Tùy chọn</v-list-title>
                                </v-list-item>
                            </v-list>
                        </v-menu> -->
                        <v-autocomplete color="orange" dense outlined :items="departments" item-text="name" item-value="code" label="Chọn phòng" hide-no-data
                         v-model="paramsEdu.departmentCode" hide-details clearable>
                        </v-autocomplete>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <chart ref="chart" label="Trình độ học vấn nhân viên"
                                :chartData="chartDataEdu" 
                                :chartType="chartType" >
                                </chart>
                                <v-data-table :items="eduOverviews" :headers="educationHeaders" dense height="300px">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            
            <v-col cols="12" md="4">
                <v-card>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-autocomplete color="orange" dense outlined :items="departments" item-text="name" item-value="code" label="Chọn phòng" hide-no-data
                         v-model="paramsPos.departmentCode" hide-details clearable>
                        </v-autocomplete>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <chart ref="chartPos" label="Chức vụ nhân viên"
                                :chartData="chartDataPos" 
                                :chartType="chartType" >
                                </chart>
                                <v-data-table :items="posOverview" :headers="posHeaders" dense height="300px">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <!-- Age overview -->
            <v-col cols="12" md="4">
                <v-card>
                    <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-autocomplete color="orange" dense outlined :items="departments" item-text="name" item-value="code" label="Chọn phòng" hide-no-data
                         v-model="paramsAge.departmentCode" hide-details clearable>
                        </v-autocomplete>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <chart ref="chartAge" label="Tỉ lệ nhóm tuổi"
                                :chartData="chartDataAge" 
                                :chartType="chartType" >
                                </chart>
                                <v-data-table :items="ageOverview" :headers="ageHeaders" dense height="300px">
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        
        <v-dialog ref="dialog" v-model="timeDialog"
        :return-value.sync="customDate" persistent width="290px" >
        <v-date-picker v-model="customDate" scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="timeDialog = false" >
                Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.dialog.save(customDate)" >
                OK
            </v-btn>
        </v-date-picker>
        </v-dialog>

    </div>
</template>
<script>
import myBreadCrumb from '@/components/BreadCrumb';
import Chart from '@/components/Charts/Chart.vue';
import ApiServices from '../../../components/services/ApiServices';
import http from '../../../components/services/http-common';
import moment from 'moment';
export default {
    components: {
        myBreadCrumb,
        "chart": Chart
    },
    watch:{
        'paramsEdu.departmentCode': {
            handler(val) {
                this.fetchEdu();
            }
        },
        
        'paramsPos.departmentCode': {
            handler(val) {
                this.fetchPos();
            }
        },
        'paramsAge.departmentCode': {
            handler(val) {
                this.fetchAge();
            }
        },
        'paramsTotalEmp.type': {
            handler(val) {
                this.fetchAge();
            }
        }
    },
    props: ["breadcrumbs", "icon"],
    data(){
        return {
            timeMenu: false,
            timeDialog: false,
            customDate:[],
            online:0,
            
            //position
            departments:[],
            paramsEdu:{
                departmentCode:null,
            },
            paramsPos:{
                departmentCode:null,
            },
            paramsAge:{
                departmentCode:null,
            },
            
            paramsTotalEmp:{
                type:"MONTH",
            },

            eduOverviews:[],
            educationHeaders:[
                { text: 'Trình độ học vấn', value: 'name'},
                { text: 'Số lượng', value: 'value'},
            ],
            
            posOverview:[],
            posHeaders:[
                { text: 'Vị trí', value: 'name'},
                { text: 'Số lượng', value: 'value'},
            ],
            
            ageOverview:[],
            ageHeaders:[
                { text: 'Nhóm tuổi', value: 'name'},
                { text: 'Số lượng', value: 'value'},
            ],

            totalEmpOverviews:[],
            totalEmpHeaders:[
                { text: 'Tháng', value: 'name'},
                { text: 'Số lượng', value: 'value'},
            ],
            
            chartType: "pie",
            chartDataEdu: {
                labels: [],
                datasets: [
                    {
                        label: "Số lượng",
                        data: [],
                        backgroundColor: []
                    },
                ],
            },

            chartDataPos: {
                labels: [],
                datasets: [
                    {
                        label: "Số lượng",
                        data: [],
                        backgroundColor: []
                    },
                ],
            },
            
            chartDataAge: {
                labels: [],
                datasets: [
                    {
                        label: "Số lượng",
                        data: [],
                        backgroundColor: []
                    },
                ],
            },
            
            chartDataTotalEmp: {
                labels: ["Tháng 1", "Tháng 2", "Tháng 3","Tháng 4","Tháng 5","Tháng 6","Tháng 7", "Tháng 8","Tháng 9","Tháng 10","Tháng 11","Tháng 12",],
                datasets: [
                    {
                        label: "Số lượng",
                        data: [],
                        backgroundColor: [],
                        borderColor: [],
                        borderWidth: 2,
    
                    },
                ],
            },
            chartOnlineEmployee: {
                labels: [
                    moment().add(1, 'minute').format('hh:mm:ss'),
                    moment().add(2, 'minute').format('hh:mm:ss'),
                    moment().add(3, 'minute').format('hh:mm:ss'),
                    moment().add(4, 'minute').format('hh:mm:ss'),
                    moment().add(5, 'minute').format('hh:mm:ss'),
                    ],
                datasets: [
                    {
                        label: "Số lượng",
                        data: [],                    
                        cubicInterpolationMode: 'monotone',
                        tension: 0.4,
                        backgroundColor: [],
                        borderColor: [],
                        borderWidth: 2
                    },
                ],
            }
        }
    },
    methods:{
        setMonth(){
            this.params.from= moment().startOf("month").format("YYYY-MM-DD");
            this.params.to= moment().endOf("month").format("YYYY-MM-DD");
        },
        setQuarter(){
            this.params.from= moment().startOf("quarter").format("YYYY-MM-DD");
            this.params.to= moment().endOf("quarter").format("YYYY-MM-DD");
        },
        
        fetchDepartment() {
            return ApiServices.fetchDepartment().then(res => {
                this.departments = res.data;
            })
        },

        fetchEdu(){
            return http.get("employees/overview/education", {
                params: this.paramsEdu
            }).then(res => {
                let jobOverviewResults = res.data;
                this.eduOverviews = res.data;
                this.chartDataEdu.labels=[];
                this.chartDataEdu.datasets[0].data=[];
                this.chartDataEdu.datasets[0].backgroundColor=[];
                jobOverviewResults.forEach(x => {
                    this.chartDataEdu.labels.push(x.name);
                    this.chartDataEdu.datasets[0].data.push(x.value);
                    this.chartDataEdu.datasets[0].backgroundColor.push(this.getRandomColor());
                });
                this.$refs.chart.update();
            })
        },
        
        fetchPos(){
            return http.get("employees/overview/position", {
                params: this.paramsPos
            }).then(res => {
                let result = res.data;
                this.posOverview = res.data;
                this.chartDataPos.labels=[];
                this.chartDataPos.datasets[0].data=[];
                this.chartDataPos.datasets[0].backgroundColor=[];
                result.forEach(x => {
                    this.chartDataPos.labels.push(x.name);
                    this.chartDataPos.datasets[0].data.push(x.value);
                    this.chartDataPos.datasets[0].backgroundColor.push(this.getRandomColor());
                });
                this.$refs.chartPos.update()
            })
        },
        fetchAge(){
            return http.get("employees/overview/age", {
                params: this.paramsAge
            }).then(res => {
                let result = res.data;
                this.ageOverview = res.data;
                this.chartDataAge.labels=[];
                this.chartDataAge.datasets[0].data=[];
                this.chartDataAge.datasets[0].backgroundColor=[];
                result.forEach(x => {
                    this.chartDataAge.labels.push(x.name);
                    this.chartDataAge.datasets[0].data.push(x.value);
                    this.chartDataAge.datasets[0].backgroundColor.push(this.getRandomColor());
                });
                this.$refs.chartAge.update()
            })
        },
        fetchTotalEmp(){
            return http.get("employees/overview/total-employee", {
                params: this.paramsAge
            }).then(res => {
                let result = res.data;
                this.totalEmpOverviews = res.data;
                // this.chartDataTotalEmp.labels=[];
                this.chartDataTotalEmp.datasets[0].data=[];
                this.chartDataTotalEmp.datasets[0].backgroundColor=[];
                this.chartDataTotalEmp.datasets[0].borderColor=[];
                result.forEach(x => {
                    // this.chartDataTotalEmp.labels.push(x.name);
                    this.chartDataTotalEmp.datasets[0].data.push(x.value);
                    this.chartDataTotalEmp.datasets[0].backgroundColor.push('rgba(75, 192, 192, 0.2)');
                    this.chartDataTotalEmp.datasets[0].borderColor.push('rgb(75, 192, 192)');
                });
                this.$refs.chartTotalEmp.update()
            })
        },
        getRandomColor() {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var i = 0; i < 6; i++ ) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        
        fetchEventsList () {
            return http.get("employeeDashboard/current-emp").then(res => {
                let online = res.data;
                let dataLength = this.chartOnlineEmployee.datasets[0].data.length;
                if(dataLength>5)
                {
                    this.chartOnlineEmployee.labels.splice(0, 1);
                    this.chartOnlineEmployee.datasets[0].data.splice(0, 1);
                    this.chartOnlineEmployee.datasets[0].backgroundColor.splice(0, 1);
                    this.chartOnlineEmployee.datasets[0].borderColor.splice(0, 1);
                    this.chartOnlineEmployee.labels.push(moment().format("hh:mm:ss"));
                }

                this.chartOnlineEmployee.datasets[0].data.push(online);
                this.chartOnlineEmployee.datasets[0].backgroundColor.push('rgba(75, 192, 192, 0.2)');
                this.chartOnlineEmployee.datasets[0].borderColor.push('rgb(75, 192, 192)');
                this.$refs.online.update()
            })
        },
        cancelAutoUpdate () {
            clearInterval(this.timer);
        }
    },
    created(){
        const HR_DIRECT = false;
        var isHr = localStorage.getItem('userClaim')?.includes('hr:dashboard')
        if(isHr && HR_DIRECT){
            this.$router.push({name: 'HrEmployeeOverview'})
        }

        this.fetchDepartment();
        this.fetchEdu();
        this.fetchPos();
        this.fetchAge();
        this.fetchTotalEmp();
        
        this.fetchEventsList();
        this.timer = setInterval(this.fetchEventsList, 60000);
    },
    beforeDestroy () {
      this.cancelAutoUpdate();
    }
    
}
</script>