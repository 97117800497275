<template>
    <div class="enrollment">
        <v-card>
            <v-card-title>
                <month-picker v-model="filters.month" label="Chọn tháng" width="7rem" dense outlined ></month-picker> 
                <v-spacer></v-spacer>
                <v-btn class="mx-1" icon><v-icon @click="fetchData">mdi-refresh</v-icon></v-btn>
            </v-card-title>
            <v-data-table
                :mobile-breakpoint="0"
                height="600px" fixed-header
                :headers="headers"
                :items="items"
                :page.sync="tableParams.page"
                :sort-by.sync="tableParams.sortBy"
                :sort-desc.sync="tableParams.sortDesc"
                :server-items-length="rowCount"
                :loading="isLoading"
                :items-per-page.sync="tableParams.pageSize"
                loading-text="Đang tải dữ liệu..."
                :footer-props="{
                    itemsPerPageOptions:[
                    15, 50, 100, 200
                    ]
                }"
            >
                <template v-slot:[`body.prepend`]="" >
                    <tr class="no-hover">
                        <td></td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.code" outlined hide-details></v-text-field>
                        </td>
                        <td></td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.name" clearable outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.status" clearable :items="statuses" outlined hide-details></v-autocomplete>
                        </td>
                        <td></td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.jobCode" clearable
                            :items="jobs" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.cityCode" clearable
                            :items="cities" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.phone" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.mail" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.city1Code" clearable
                            :items="cities" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.city2Code" clearable
                            :items="cities" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.unisCode" clearable
                            :items="unis" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.eduLevelCode" clearable
                            :items="eduLvls" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.majorCode" clearable
                            :items="majors" item-value="code" item-text="name" outlined hide-details></v-autocomplete>
                        </td>
                        <td>
                            <date-picker2 v-model="filters.createdAt" :range="false" label="" :dense="true" :hideDetails="true" outlined ></date-picker2>
                        </td>
                    </tr>
                </template>
                
                <template v-slot:[`item.notAccept`]="{ item }" >
                    <v-icon v-if="item.notAccept">mdi-check</v-icon>
                </template>
                <template v-slot:[`item.interviewAt`]="{ item }" >
                    {{ formatDate(item.interviewAt)=="01/01/0001" ? "": formatDate(item.interviewAt) }}
                </template>
                <template
                    v-slot:[`item.actions`]="{item}"
                >
                
                    <v-tooltip bottom color="primary" v-if="item.status=='PASS' && !item.notAccept">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" small @click="acceptDialog=true, targetItem=item" class="ma-1" icon color="primary" >
                                <v-icon>mdi-handshake</v-icon>
                            </v-btn>
                        </template>
                        <span> Nhận việc</span>
                    </v-tooltip>

                     <v-tooltip bottom color="error"  v-if="item.status=='PASS'  && !item.notAccept">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" small @click="deny(item)" class="ma-1" icon color="red">
                                <v-icon>mdi-exit-run</v-icon>
                            </v-btn>
                        </template>
                        <span> Không nhận việc</span>
                    </v-tooltip>
                    <!-- <v-btn x-small @click="acceptDialog=true, targetItem=item" color="teal" dark v-if="item.status=='PASS' && !item.notAccept" class="my-1">Nhận việc</v-btn>
                    <v-btn x-small @click="deny(item)" color="orange" dark v-if="item.status=='PASS'  && !item.notAccept" class="my-1">Không nh.việc</v-btn> -->
                    <v-btn x-small @click="undo(item)" dark v-if="item.status=='ACCEPT' || item.notAccept" class="my-1">Hoàn tác</v-btn>
                    <!-- <edit-button @click="edit(item)" label="Chi tiết"></edit-button>
                    <delete-button @click="remove(item)" label="Xóa"></delete-button> -->
                </template>
                <template v-slot:[`item.createdAt`]="{ item }" >
                    {{ formatDate(item.createdAt) }}
                </template>
                <template v-slot:[`item.time`]="{ item }" >
                    {{ formatDate(item.time) }}
                </template>
                <template v-slot:[`item.status`]="{ item }" >
                    <v-chip :color="formatStatusColor(item.status)" label small outlined>
                        <strong> {{formatStatus(item.status)}}</strong>
                    </v-chip>
                    <!-- {{ formatStatus(item.status) }} -->
                </template>
                
                <template v-slot:[`item.avatarUrl`]="{ item }">
                    <v-avatar class="my-1" size="54">
                        <v-img
                            lazy-src="https://banner2.cleanpng.com/20180625/req/kisspng-computer-icons-avatar-business-computer-software-user-avatar-5b3097fcae25c3.3909949015299112927133.jpg"
                            :src="endpoint + item.avatarUrl.replace('wwwroot/', '')"
                            alt="Avatar"
                            v-if="item.avatarUrl" ></v-img>
                    </v-avatar>
                </template>
            </v-data-table>
        </v-card>

        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <!-- ************** DIALOG CONFIRMATION *************** -->
        <v-dialog v-model="acceptDialog" max-width="500px">
            <v-card>
                <v-card-title class="headline">Chuyển vào danh sách nhân viên</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="form.code" label="Mã nhân viên (tự sinh nếu để trống)"></v-text-field>
                        </v-col>
                        <v-col cols="12" v-show="!form.isDepot">
                            <v-autocomplete v-model="form.departmentTypeCode" label="Khối" item-text="name" item-value="code" :items="departmentTypes"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" v-show="!form.isDepot">
                            <v-autocomplete v-model="form.departmentCode" label="Phòng ban" item-text="name" item-value="code" :items="departments"></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete v-model="form.positionCode" label="Vị trí" item-text="name" item-value="code" :items="positions" ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" >
                            <v-autocomplete v-model="form.positionStateCode" label="Chức danh" item-text="name" item-value="code" :items="positionStates"></v-autocomplete>
                        </v-col>
                        <!-- <v-col cols="12">
                            <v-checkbox v-model="form.isDepot" label="Bộ phận kho"></v-checkbox>
                        </v-col> -->
                        <v-col cols="12" >
                            <v-autocomplete v-model="form.shiftCode" label="Ca làm việc" item-text="name" item-value="code" :items="shifts" persistent-hint 
                            hint="Lưu ý: Nhân viên hỗ trợ sử dụng CA VĂN PHÒNG, chi nhánh sử dụng ca tương ứng với chức danh tương ứng, riêng TỔNG KHO thuộc khối hỗ trợ sử dụng ca  làm việc như chi nhánh"></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <date-picker2 :range="false" v-model="form.hireDate" label="Ngày nhận việc" :width="600"></date-picker2>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="recruit(targetItem), acceptDialog=false">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import myBreadCrumb from '@/components/BreadCrumb';
import DataFilter from '@/components/DataFilter';
import * as moment from "moment/moment";
import DatePicker2 from '../../../components/DatePicker2.vue';

export default {
    name: "CandidateIndex",
    components: {
        myBreadCrumb,
        'data-filter': DataFilter

    },
    props: ["breadcrumbs", "icon"],
    watch: {
        acceptDialog(val) {
            this.fetchCity();
            if(!this.acceptDialog)
            {
                this.item=null;
                this.form = Object.assign({}, this.defaultForm);
            }
            
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        "form.departmentTypeCode": {
            handler (val) {
                this.fetchDepartments(val);
            },
        },
        "form.departmentCode": {
            handler (val) {
                this.fetchPosition(val);
            },
        },
        "form.positionCode": {
            handler (val) {
                console.log("sadasda");
                this.fetchPositionState(val);
            },
        },
        "form.positionStateCode": {
            handler (val) {
                if(val == "PST00013")
                    this.form.shiftCode = "CLV00024";
            },
        }
    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data () {
        return {
            ROOT_URL: "EmployeeResource",
            POSITION_FETCH: "departments/fetch-position/",
            POSITION_STATE_FETCH: "positions/fetch-position-state",
            endpoint: process.env.VUE_APP_BASE_URL,

            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
                pageSize:15
            },
            statuses: [
                {text: "Mới", value: "NEW"},
                {text: "Chờ", value: "PEND"},
                {text: "Đạt", value: "PASS"},
                {text: "Loại", value: "FAIL"},
            ],
            jobs:[],
            cities:[],
            unis:[],
            eduLvls:[],
            majors:[],
            filters:{
                code:null,
                createdAt:null,
                status:null,
                name:null,
                jobCode:null,
                cityCode:null,
                city1Code:null,
                city2Code:null,
                unisCode:null,
                eduLevelCode:null,
                majorCode:null,
                phone:null,
                mail:null,
                month: moment().format("YYYY-MM"),
            },
            rowCount: 0,

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:["DepartmentTypeCode"],
                
                from: undefined,
                to: undefined,
                fromOns: [
                ],
                fromOn: "",
            },

            departments:[],
            departmentTypes:[],
            positions:[],
            positionStates:[],
            shifts: [],
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,
            quickItem: {name: undefined, status:undefined },

            acceptDialog: false,
            dialog: false, item: null,
            dialogDelete: false,
            targetItem: undefined,

            form: {
                code:"",
                isDepot: false,
                departmentCode: "",
                departmentTypeCode:"",
                hireDate: moment().format("YYYY-MM-DD"),
                positionCode:undefined,
                positionStateCode:undefined,
                shiftCode: undefined
            },

            defaultForm: {
                code:"",
                isDepot: false,
                departmentCode: "",
                departmentTypeCode:"",
                hireDate: moment().format("YYYY-MM-DD"),
                positionCode:undefined,
                positionStateCode:undefined,
                shiftCode: undefined
            },

            headers: [
                { text: 'Thao tác', value: 'actions',width: 120},
                { text: 'Mã', align: 'start', value: 'code', width: 100},
                { text: "Avatar", value:"avatarUrl", align: 'center', sortable: false},
                { text: 'Ứng viên', align: 'start', value: 'name', width: 200},
                { text: 'Trạng thái', align: 'start', value: 'status', width: '9rem'},
                { text: 'Không nhận việc', align: 'start', value: 'notAccept', width: '9rem'},
                // { text: 'Ngày phỏng vấn', align: 'start', value: 'interviewAt', width:'12rem'},
                { text: 'Công việc ứng tuyển', align: 'start', value: 'job', width: 200},
                { text: 'Sô CMND', align: 'start', value: 'idNumber', width: 100},
                { text: 'Nơi cấp', align: 'start', value: 'idPlace', width: 150},
                { text: 'Ngày cấp', align: 'start', value: 'idDate', width: 100},
                { text: 'Thành phố', align: 'start', value: 'city', width: 250},
                { text: 'Quận', align: 'start', value: 'district', width: 250},
                { text: 'Phường', align: 'start', value: 'ward', width: 250},
                { text: 'Địa chỉ', align: 'start', value: 'address', width: 250},
                { text: 'Phone', value: 'phone', width: 200},
                { text: 'Mail', value: 'mail', width: 200 },
                { text: 'Nơi làm việc mong muốn 1', value: 'city1', width: 200 },
                { text: 'Nơi làm việc mong muốn 2', value: 'city2', width: 200 },

                { text: 'Trường', value: 'university', width: 200 },
                { text: 'Học vấn', value: 'educationLevel', width: 200 },
                { text: 'Chuyên ngành', value: 'educationMajor', width: 200 },
                { text: 'Ngày gửi', align: 'start', value: 'createdAt', width:'12rem'},
            ],
        }
    },
    methods: {
        formatStatus(value) {
            switch(value){
                case "NEW":
                    return "Mới"
                case "PEND":
                    return "Chờ"
                case "PASS":
                    return "Đạt"
                case "FAIL":
                    return "Loại"
                case "ACCEPT":
                    return "Nhận việc"
                case "DENY":
                    return "Không nhận việc"
            }
        },
        formatStatusColor(value) {
            switch(value){
                case "NEW":
                    return "light"
                case "PEND":
                    return "orange"
                case "PASS":
                    return "success"
                case "FAIL":
                    return "error"
                case "ACCEPT":
                    return "teal"
                case "DENY":
                    return "purple"
            }
        },
        
        close () {
            this.acceptDialog = false;
            this.targetItem=undefined;
            this.form = Object.assign({}, this.defaultForm);
        },
        
        recruit(item)
        {
            return http.post("employeeResource/recruit/"+item.code, this.form).then(res=> {
                item.status = "ACCEPT";
                this.fetchData();
                this.close();
            })
        },
        undo(item)
        {
            return http.get("employeeResource/undo/"+item.code).then(res=> {
                this.fetchData();
            })
        },
        deny(item)
        {
            return http.get("employeeResource/deny/"+item.code).then(res=> {
                this.fetchData();
            })
        },
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        fetchJob() {
            return http.get("jobs").then(res=> {
                this.jobs = res.data;
            })
        },
        fetchCity() {
            return http.get("cities/fetch").then(res=> {
                this.cities = res.data;
            })
        },
        fetchDepartments(deptType){
            if(deptType)
                return http.get("departmentTypes/fetch-department/"+ deptType).then(res => {
                    this.departments = res.data;
                })
        },
        fetchDepartmentTypes(){
            return http.get("departmentTypes/fetch").then(res => {
                this.departmentTypes = res.data;
            })
        },
        
        fetchShift(val) {
            return http.get("shifts").then(res => {
                this.shifts = res.data;
            });
        },
        
        fetchPosition(val) {
            if(val)
                return http.get(this.POSITION_FETCH+val).then(res => {
                    // if(!this.fetchData)
                    // {
                    //     this.positions=[];
                    //     if(!res.data.map(x=>x.code).includes(this.form.positionCode))
                    //         this.form.positionCode=null;
                    // }
                    this.positions = res.data;
                    if(this.positions.map(x=>x.code).includes("CV00061"))
                    {
                        this.form.positionCode = "CV00061";
                    }
                    if(this.positions.map(x=>x.code).includes("CV00067"))
                    {
                        this.form.positionCode = "CV00067";
                    }
                    // if(this.positions.map(x=>x.code).includes("CV00061"))
                    //     this.form.positionCode = "CV00067";
                });
        },

        fetchPositionState(val) {
            let url = `${this.POSITION_STATE_FETCH}/${val}`;
            return http.get(url).then(res => {
                // if(!this.fetchData)
                // {
                //     this.positionStates=[];
                //     if(!res.data.map(x=>x.code).includes(this.form.positionStateCode))
                //         this.form.positionStateCode=null;
                // }
                this.positionStates = res.data;
            });
        },

        fetchData () {
            return http.get(this.ROOT_URL, {
                params: this.params
            })
            .then(res => {
                this.isLoading = false;
                this.items = res.data.results;
                this.rowCount = res.data.rowCount;
            })
            .catch(err => {

            })
        },
        fetchUni(){
            return http.get("Universities?dropDown=1").then(res => {
                this.unis = res.data
            })
        },
        fetchMajor(){
            return http.get("educationMajors?dropDown=1").then(res => {
                this.majors = res.data
            })
        },
        fetcheducationLevels(){
            return http.get("educationLevels?dropDown=1").then(res => {
                this.eduLvls = res.data
            })
        },
        edit(event ){
            this.item = event;
            this.dialog = true;
        },

        save(item){
            return http.post("candidates/quick-action/"+item.code, item).then(res=>{
                console.log(res.data);
            })
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete("candidates/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },
        timeFormat(val){
            return moment(val).format("YYYY-MM-DD")
        }
    },
    created() {
        this.fetchJob();
        this.fetchCity();
        this.fetchUni();
        this.fetchMajor();
        this.fetcheducationLevels();
        this.fetchData();
        this.fetchShift();
        this.$emit("setUrl",  this.breadcrumbs);
        this.fetchDepartmentTypes();
    },
    destroyed() {
        this.$emit("setUrl", []);
    }
}
</script>
<style scoped>
.table-cursor tbody tr:hover {
cursor: pointer;    
}
::v-deep .no-hover:hover{
}
::v-deep table > tbody > tr.no-hover:hover
{
    background-color: white !important;
}
::v-deep table > tbody > tr:nth-child(1)>td {
    padding-right: 1px !important;
    padding-left: 1px !important;
}
</style>