<template>
    <div class="insurance">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog.state=true" ></myBreadCrumb> -->
        <create-dialog  v-model="dialog" @CloseDialog="closeHandler" @fetchData="fetchData"></create-dialog>

        <!-- <create-dialog-health-insurance ref="createHealthInsurance" @needFetch="fetchData"></create-dialog-health-insurance> -->
        <v-card>
            <v-card-title>
                Danh sách bảo hiểm
                <v-spacer></v-spacer>
                <create-button @click="dialog={state: true, item:null}"></create-button>
                <excel-button @click="exportPrompt=true"/>
                <export-prompt url="insurances" v-model="exportPrompt" fileName="BAO_HIEM"></export-prompt>
                <data-filter v-model="params" :filter="filter" @fetchData="fetchData"> </data-filter>
            </v-card-title>
            <v-data-table height="600px"
            :options.sync="options"
            :headers="headers"
            :server-items-length="totalItem"
            :items="data"
            :search="search">
                <!-- BHYT -->
                <template v-slot:[`item.healthInsuranceDate`]="{ item }">
                    <v-chip color="blue-grey" label v-if="!item.healthInsuranceDate" dark class="font-weight-medium" small>
                        Không có thông tin
                    </v-chip>
                    <span v-else>{{item.healthInsuranceDate}}</span>
                </template>
                <template v-slot:[`item.withdrawDate`]="{ item }">
                    <v-chip color="blue-grey" label v-if="!item.withdrawDate" dark class="font-weight-medium" small>
                        Không có thông tin
                    </v-chip>
                    <span v-else>{{item.withdrawDate}}</span>
                </template>
                <template v-slot:[`item.registerDate`]="{ item }">
                    <v-chip color="blue-grey" label v-if="!item.registerDate" dark class="font-weight-medium" small>
                        Không có thông tin
                    </v-chip>
                    <span v-else>{{item.registerDate}}</span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip color="success" label dark class="font-weight-medium" small>
                        {{item.status}}
                    </v-chip>
                </template>
                <template v-slot:[`item.department`]="{ item }">
                    <v-chip class="ma-2 caption font-weight-bold" color="orange" label text-color="white" >
                        <v-icon left>
                            mdi-store
                        </v-icon>
                        {{item.department}}
                    </v-chip>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <edit-button @click="editItem(item)"></edit-button>
                    <delete-button @click="deleteItem(item)"></delete-button>
                </template>
            </v-data-table>
        </v-card>

        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

// import myBreadCrumb from '@/components/BreadCrumb';
import http from '@/components/services/http-common';
import CreateInsurance from './Create'
import * as moment from "moment/moment";
import DataFilter from '@/components/DataFilter';
import EventBus  from "@/components/services/EventBus";

export default {
    name: "Insurance",
    components: {
        // myBreadCrumb,
        "create-dialog": CreateInsurance,
        "data-filter": DataFilter
    },
    computed: {
        
    },
    watch: {
        //DB OPTIONS
        options: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
    },
    data () {
        return {
            // URL
            ROOT_URL: "Insurances",
            DEPARTMENT_URL: "Departments",

            exportPrompt: false,
            //filter
            params: {
                sortBy: [],
                sortDesc: [],
                page: "",
                itemsPerPage: "",
                searchs: [],
                searchAttrs: [],

                fromOn: "",
                from: "",
                to: ""
            },

            filter: {
                menu: false,
                search: "",
                searchAttr: "EmployeeName",
                department: "",

                from: new Date().toISOString().substr(0, 10),
                to: new Date().toISOString().substr(0, 10),
                fromOns: [
                    {text: "Hạn BHYT", value: "HealthInsuranceDate"},
                    {text: "Ngày nộp sổ", value: "RegisterDate"},
                    {text: "Ngày rút sổ", value: "WithdrawDate"}
                ],
                fromOn: undefined,

                searchAttrs: [
                    {text: "Tên nhân viên", value: "EmployeeName"},
                    {text: "MSNV", value: "EmployeeCode"},
                ],
                FilterAttrs: [
                    {text: "Phòng", items: [] }
                ],
                FilterOn:["DepartmentCode"],
            },

            //DIALOG
            dialog: {
                state: false,
                item: undefined,
            },
            dialogDelete: false,
            targetItem: {},

            //DATA
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            //DATATABLE
            data: [],
            totalItem: 0,
            search:"",
            options: {},
            isLoading: true,
            targetItem: undefined,
            headers: [
                { text: 'Thao tác', value: 'actions', sortable: false, width: "7rem"},
                { text: 'Mã số', align: 'start', sortable: false, value: 'code', },
                { text: 'Tên NV', align: 'start', value: 'name', width:'15rem' },
                { text: 'Phòng/chi nhánh', align: 'start', value: 'department', },
                { text: 'Số sổ', value: 'insuranceNumber' },
                { text: 'Số BHYT', value: 'healthInsuranceNumber' },
                { text: 'Hạn BHYT', value: 'healthInsuranceDate' },
                { text: 'Ngày nộp sổ', value: 'registerDate' },
                { text: 'Ngày rút sổ', value: 'withdrawDate' },
                { text: 'Mô tả/ghi chú', value: 'description' },
                { text: 'Trạng thái', value: 'status' },
                { text: 'Link đính kèm', value: 'attachmentLink' },
            ],
        }
    },
    
    methods:{
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        closeHandler () {
            this.dialog = {state: false, item: undefined}
        },
        editItem (item) {
            this.dialog = {state: true, item: item}
        },
        
        fetchDepartment() {
            return http(this.DEPARTMENT_URL, {
                params: {dropDown: 1}
            })
            .then(res => {
                this.filter.FilterAttrs[0]={text: "Phòng", items: res.data };
            })
            .catch(err => console.log(err))
        },
        fetchData () {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options;
            
            this.params =  { ...this.params, 
                                sortBy: this.options.sortBy, //array
                                sortDesc: this.options.sortDesc, //array
                                page: this.options.page,
                                itemsPerPage: this.options.itemsPerPage,
                                };
            if(page != undefined || itemsPerPage != undefined)
                return http.get(this.ROOT_URL, {
                    params: this.params
                })
                .then(res => {
                    console.log(res)
                    this.isLoading = false;
                    this.data = res.data.items;
                    this.totalItem = res.data.totalItem;
                })
                .catch(err => { throw err});
        },
        
        deleteItem (item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL + "/" +this.targetItem.code).then(res => {
                this.fetchData();
                let eventObj = {
                    color: "success",
                    message: "Xóa dữ liệu thành công",
                    toast: true,
                };
                this.messageToast(eventObj);
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    // case 403:{
                    //     this.unauthorizeAlert();
                    //     this.dialogDelete = false;
                    //     break;
                    // }
                    // default:
                    //     break;
                }
            })
        },
    },
    created () {
        this.fetchData();
        this.fetchDepartment();
    }
}
</script>