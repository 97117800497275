<template>
    <div>
        <create-dialog  @fetchData="fetchData" ref="create_employee_report_dialog"></create-dialog>
        <view-dialog  @fetchData="fetchData" ref="view_employee_report_dialog"></view-dialog>
        
        <v-row>
            <v-col cols="12" >
                <v-card>
                    <v-toolbar color="white" flat>
                        <v-toolbar-title class="mx-4 d-none d-md-block">Danh sách phiếu</v-toolbar-title>
                        <v-spacer></v-spacer>
                        
                        <v-dialog
                            ref="monthDialog"
                            :return-value.sync="filterMonth"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined dense class="mx-2" style="max-width:150px"
                                v-model="filterMonth"
                                label="Chọn tháng *"
                                prepend-icon="mdi-calendar"
                                readonly hide-details
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker dense locale="vi"
                            v-model="filterMonth"
                            type="month"
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.monthDialog.save(filterMonth)"
                            >
                                Chọn
                            </v-btn>
                            </v-date-picker>
                        </v-dialog>
                        <v-btn color="teal" outlined @click="openDialog" >
                            Tạo báo cáo
                        </v-btn>
                        
                        <!-- //actions -->
                        <!-- <v-menu
                            left
                            bottom offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn outlined color="success" class="ml-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    Hành động<v-icon>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                            <v-list-item @click="dialog=true">
                                <v-list-item-title>Thêm phiếu <v-icon color="primary" right>mdi-plus</v-icon></v-list-item-title>
                            </v-list-item>
                            </v-list>
                        </v-menu> -->
                    </v-toolbar>
                    
                    <v-card-title>
                        <v-row justify="start" style="width: 100%;">
                            <v-col cols="12" md="2">
                                <!-- status -->
                                <v-select clearable :items="statuses" outlined hide-details v-model="filterStatus" dense label="Trạng thái" persistent-placeholder>
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field hide-details outlined dense v-model="filterCategory" label="Loại báo cáo" persistent-placeholder></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field hide-details outlined dense v-model="filterName" label="Tên báo cáo" persistent-placeholder></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <!-- chinhanh -->
                                <v-text-field hide-details outlined dense v-model="filterFromDepartment" label="Chi nhánh gởi" persistent-placeholder></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <!-- chinhanh -->
                                <v-text-field hide-details outlined dense v-model="filterToDepartment" label="Phòng ban nhận" persistent-placeholder></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="start" style="width: 100%;">
                            <v-col cols="12" md="2">
                                <v-text-field type="date" hide-details outlined dense v-model="filterCreatedAt" label="Ngày tạo" persistent-placeholder clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field hide-details outlined dense v-model="filterCreatedBy" label="Tạo bởi" persistent-placeholder clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field type="date" hide-details outlined dense v-model="filterUpdatedAt" label="Ngày tạo" persistent-placeholder clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field hide-details outlined dense v-model="filterUpdatedBy" label="Tạo bởi" persistent-placeholder clearable></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-title>
                </v-card>
                <v-row class="mt-5">
                    <v-col cols="12">
                        <v-card>
                            <!-- <v-card-title>Danh sách bộ câu hỏi</v-card-title> -->
                            <v-data-table :items="items1" :headers="headers1"  height="calc(100vh - 300px)"  fixed-header
                            :search="globalSearch" >
                            
                                <template v-slot:[`item.actions`]="{item}" > 
                                    <v-menu dense
                                            left
                                            bottom offset-y
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="primary" x-small
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    Thao tác<v-icon>mdi-chevron-down</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                
                                                <v-list-item @click="targetItem=item, dialogDelete=true" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="error">mdi-delete</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Xóa </v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="edit(item)" dense>
                                                    <v-list-item-icon>
                                                        <v-icon color="primary">mdi-pen</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-title>Chi tiết </v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                </template>
                                <template v-slot:[`item.fromDepartment`]="{item}" >
                                    <span class="text-subtitle-2 font-weight-bold text-primary">{{ item.fromDepartment }}</span>
                                </template>
                                <template v-slot:[`item.name`]="{item}" >
                                    <v-badge :color="statusColors[item.status]" :content="statusesDict[item.status] " >
                                        {{ item.name }}
                                    </v-badge>
                                </template>
                                <template v-slot:[`item.createdAt`]="{item}" > 
                                    <span >{{ formatDate(item.createdAt) }}</span>
                                </template>
                                <template v-slot:[`item.fromDepartmentNote`]="{item}" > 
                                    <span v-html="item.fromDepartmentNote"></span>
                                </template>
                                
                                <template v-slot:[`item.employeeReportImages`]="{item}" >
                                    <v-row @click="targetItem=item, dialogCarousel=true">
                                        <v-col cols="6" md="4" v-for="(image, index) in item.employeeReportImages" :key="index">
                                            <v-img :src="image.link" height="120px" width="120px" ></v-img>
                                        </v-col>
                                    </v-row>
                                </template>

                                <template v-slot:[`item.status`]="{item}" >
                                    <!-- <v-chip
                                    :color="statusColors[item.status]"
                                    text-color="white"
                                    >
                                    <v-avatar left>
                                        <v-icon>{{statusIcons[item.status]}}</v-icon>
                                    </v-avatar>
                                    {{ statusesDict[item.status] }}
                                    </v-chip> -->
                                    {{statusesDict[item.status]}}
                                </template>

                                <!-- <template v-slot:[`item.note`]="{item}" >
                                    <span v-if="item.DuTruDuoc" class="green--text text--darken-3"><b>Chấp nhận:</b> {{item.DuTruDuoc}}</span>
                                    <span v-if="item.DuTruKhongDuoc" class="red--text text--darken-3"><b>Không đặt được: </b> {{item.DuTruKhongDuoc}}</span>
                                </template> -->
                                
                                <template v-slot:[`item.NganhHang`]="{item}">
                                    <b class="indigo--text">{{item.NganhHang}}</b>
                                </template>

                                <template v-slot:[`item.GhiChu`]="{item}" >
                                    <b v-if="item.GhiChu">Ghi chú:</b> {{item.GhiChu}}
                                    <ul>
                                        <li v-if="item.LoaiHang">
                                            <b>Loại hàng: </b> {{item.LoaiHang}}
                                        </li>
                                        <li v-if="item.LoaiYeuCau">
                                            <b>Loại yêu cầu: </b> {{item.LoaiYeuCau}}
                                        </li>
                                        <li v-if="item.TrungBinhBan">
                                            <b>T.bình bán: </b> {{item.TrungBinhBan}}
                                        </li>
                                        <li v-if="item.NgayGiaoKhach">
                                            <b>Ngày giao khách: </b> {{item.NgayGiaoKhach}}
                                        </li>
                                    </ul>
                                </template>

                                <template v-slot:[`item.deletePhieu`]="{item}" >
                                    <v-btn outlined small color="error" @click="deletePhieu(item.ID)"><v-icon small left>mdi-delete</v-icon> Xóa</v-btn>
                                </template>

                            </v-data-table>
                        </v-card>
                    </v-col>
                    
                </v-row>
            </v-col>

        </v-row>
        <!-- ************** CAROUSEL *************** -->
        <v-dialog v-model="dialogCarousel" :width="dialogCarouselWidth" :fullscreen="dialogCarouselFs">
            <v-carousel v-if="targetItem!=null"  :height="dialogCarouselFs ? '100vh': '90vh'">
                <v-carousel-item
                v-for="(image,i) in targetItem.employeeReportImages"
                :key="i"
                :src="image.link"
                reverse-transition="fade-transition"
                transition="fade-transition"
                :width="dialogCarouselWidth"
                >                    
                    <v-btn rounded small class="float-right ma-1" @click="dialogCarousel=false,dialogCarouselFs=false, dialogCarouselWidth='600px'" color="black"><v-icon color="white">mdi-close</v-icon></v-btn>    

                    <v-btn rounded small class="float-right ma-1" @click="dialogCarouselFs=true, dialogCarouselWidth='100vw'" color="black"><v-icon color="white">mdi-fullscreen</v-icon></v-btn>
                    
                    <v-btn rounded small class="float-right ma-1" :href="image.link" color="black"><v-icon color="white">mdi-download</v-icon></v-btn> 
                </v-carousel-item>
            </v-carousel>
        </v-dialog>
        <!-- ************** BOTTOM SHEET  *************** -->
        <v-bottom-sheet v-model="bottomSheet" persistent max-width="600px" hide-overlay>
            <v-card
                v-if="item1SelectObj"
                class="" 
                height="300px"
            >
                <v-card-title v-if="bottomSheetType!='RETURN'" :class="`font-weight-bold ${bottomSheetType == 'ACCEPT'? 'green' : ( bottomSheetType == 'DENY'? 'red': '')}--text text--darken-2`"><v-spacer></v-spacer>{{bottomSheetType == 'ACCEPT'? 'Xác nhận' : ( bottomSheetType == 'DENY'? 'Từ chối': '')}} phiếu {{item1SelectObj.ID}}<v-spacer></v-spacer></v-card-title>
                <v-card-title v-if="bottomSheetType=='RETURN'" class="font-weight-bold primary--text"> <v-spacer></v-spacer> Trả về phiếu phiếu {{item1SelectObj.ID}}<v-spacer></v-spacer></v-card-title>
                <v-card-text v-if="bottomSheetType!='RETURN'">
                    <v-row>
                        <v-col cols="12" class="">
                            <label for="bottom_note" class="font-weight-bold text-left">Ghi chú</label>
                            <v-textarea id="bottom_note" rows="4" solo hide-details placeholder="Gõ enter để LƯU" v-model="note">

                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-3" outlined color="success" @click="setStatusNote" >
                    Lưu
                    </v-btn>

                    <v-btn class="mt-3" color="error" text @click="closeBottom" >
                    Đóng
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này? ID: {{!!targetItem? targetItem.id: ''}}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                        <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="loadingDialog" width="300px" persistent>
            <v-card>
                <v-card-title class="text-center">
                    <v-spacer></v-spacer>
                    Đang xuất file
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="text-center">
                    <v-progress-circular
                    size="70"
                    indeterminate
                    color="orange"
                    ></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CreateDialog from './Create.vue';
import ViewDialog from './View.vue';
import http from '@/components/services/http-common';
import moment from 'moment/moment';
import api from './services/api.js'

export default {
    components:{
        "create-dialog": CreateDialog,
        "view-dialog": ViewDialog
    },
    computed: {
        isAsm() {
            var result = false
            if(this.GROUP_MA) {
                if(this.GROUP_MA.length>0){
                    result = this.GROUP_MA.includes('TS_ASM')
                }
            }
            return result
        },
        selectedTemplate() {
            let rslt = this.item1Select.length>0 ? ` ${this.item1Select[0].id }`: ""

            return rslt
        },
        chinhanhs(){
            if(!this.Sources) return [] 
            var result =this.Sources.split("|") 
            if(result.length > 0){
                this.dathangForm.chinhanh_dutru = result[0]
            }
            return result
        },
    },
    watch:{
        dialogCarousel(val) {
            if(!val)
                this.targetItem=null;
        },
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        filterMonth(val)
        {
            this.fetchData();
        },
        item1Select() {
            if(this.selectedTemplate != ""){
                this.getQuestions()
            } else{
                this.questions=[]
            }
        },
        filterFromDate() {
            this.getPhieu()
        },
        filterToDate() {
            this.getPhieu()
        },
        hanghoaFilterType() {
            this.getHangHoa()
        },
        hanghoaFilter() {
            this.getHangHoa()
        },
        
        'dathangForm.dvt': function(newVal, oldVal) {
            if(this.hanghoaUnits.length >0){
                let selected = this.hanghoaUnits.filter(x=>x.Code == newVal)[0]
                let tonkho = selected['End Quantity'] ? selected['End Quantity'] : 0
                this.dathangForm.tonkho_hethong = tonkho
            }
        },
        hanghoaOutter() {
            this.dathangForm.mahanghoa=""
            this.dathangForm.soluong_dutru=""
            this.dathangForm.dvt=""
            this.dathangForm.tenhang=""
            // this.dathangForm.chinhanh_dutru=""
            this.dathangForm.tonkho_hethong=""
            // this.dathangForm.userid_tao=""

            this.dathangForm.base64_image=[]
            this.dathangForm.loai_hang= ''
            this.dathangForm.trungbinh_ban= ''
            this.dathangForm.loai_yeucau= ''
            this.dathangForm.ngay_giaokhach= null
        }
    },
    data () {
        return {
            TOKEN: null,
            USER_ID: null,
            GROUP_MA: null,
            Sources: null,
            month: moment().startOf("months").format("yyyy-MM"),
            statuses: [
                {text: "Tạo mới", value: 0},
                {text: "Đã xem", value: 1}
            ],
            statusesDict: {
                0: "Tạo mới",
                1: "Đã xem"
            },
            statusColors: {
                0: 'blue',
                1: 'green'
            },
            statusIcons: {
                0: 'mdi-new-box',
                1: 'mdi-checkbox-marked-circle'
            },
            dialogCarousel: false,
            dialogCarouselFs: false,
            dialogCarouselWidth: 600,
            bottomSheet: false,
            createPanel: true,
            bottomSheetType: "",
            note: "",

            item: null,

            item1SelectObj: null,
            item1Select: [],
            item2Select: [],
            items1:[],
            items2:[],
            search:"",

            filterMonth: moment().startOf('months').format('YYYY-MM-DD'),
            filterCode:"", 
            
            filterFromDate:moment().startOf('months').format('YYYY-MM-DD'),
            filterCategory:'',
            filterName:'',
            filterFromDepartment:'',
            filterToDepartment:'',
            filterCreatedAt:null,
            filterCreatedBy:'',
            filterUpdatedAt:null,
            filterUpdatedBy:'',
            filterStatus: null,

            currentItem: "",

            loadingDialog: false,
            globalSearch:"",
            headers1: [
                {text: "Thao tác", value: "actions"},
                {text:"ID", value: "id", width:"70px"},
                {text:"Chi nhánh gởi", value: "fromDepartment", width:"250px",
                    filter: value => {
                        if (!this.filterFromDepartment) return true
                        if(value == null){
                            return true
                        }
                        return value.toUpperCase().includes(this.filterFromDepartment.toUpperCase())
                    }
                },
                {text:"Danh mục", value: "employeeReportCategory", width:"150px", 
                    filter: value => {
                        if (!this.filterCategory) return true
                        if(value == null){
                            return true
                        }
                        return value.toUpperCase().includes(this.filterCategory.toUpperCase())
                    }
                },
                {text:"Tiêu đề", value: "name", width:"200px",
                    filter: value => {
                        if (!this.filterName) return true
            
                        return value.toUpperCase().includes(this.filterName.toUpperCase())
                    }
                },
                // {text:"Hình ảnh", value: "images", width:400, align:"center"},
                {text:"Hình ảnh", value: "employeeReportImages", width:400, align:"center"},
                // {text:"Chi tiết", value: "fromDepartmentNote", width:"7rem", align:"center"},
                // {text:"Phản hồi", value: "toDepartmentNote", align:"center"},
                {text:"Tạo vào", value: "createdAt", width:"7rem", 
                    filter: value => {
                        if (!this.filterCreatedAt) return true
                        if(value == null){
                            return true
                        }
                        return value == this.filterCreatedAt
                    }
                },
                {text:"Tạo bởi", value: "createdByName", width:"7rem", 
                    filter: value => {
                        if (!this.filterCreatedBy) return true
                        if(value == null){
                            return true
                        }
                        return value.toUpperCase().includes(this.filterCreatedBy.toUpperCase())
                    }
                },
                {text:"Cập nhật vào", value: "updatedAt", width:"7rem", 
                    filter: value => {
                        if (!this.filterUpdatedAt) return true
                        if(value == null){
                            return true
                        }
                        return value.toUpperCase().includes(this.filterUpdatedAt.toUpperCase())
                    }
                },
                {text:"Cập nhật bởi", value: "updatedByName", width:"7rem", 
                    filter: value => {
                        if (!this.filterUpdatedBy) return true
                        if(value == null){
                            return true
                        }
                        return value.toUpperCase().includes(this.filterUpdatedBy.toUpperCase())
                    }
                },
                {text:"Chi tiết", value: "fromDepartmentNote", width:"7rem"},
                {text:"Phòng ban nhận", value: "toDepartment", width:"250px", 
                    filter: value => {
                        if (!this.filterToDepartment) return true
                        if(value == null){
                            return true
                        }
                        return value.toUpperCase().includes(this.filterToDepartment.toUpperCase())
                    }
                },
                {text:"Trạng thái", value: "status", width:"7rem", 
                    filter: value => {
                        if (this.filterStatus == null) return true
                        if(value == null){
                            return true
                        }
                        return value == this.filterStatus
                    }
                },
                // {text:"", value: "actions", width:"auto", align:"center"},
            ],
            
            hanghoaDialog:false,
            hanghoaFilterType: "Name",
            hanghoaFilter: "",
            hanghoaUnits:[],
            hanghoaOutter:false,
            //dathang form
            dathangFormValid:false,
            dathangForm: {
                mahanghoa:"",
                soluong_dutru:"",
                dvt:"",
                tenhang:"",
                chinhanh_dutru:"",
                tonkho_hethong:"",
                userid_tao:"",
                base64_image:[],
                ghichu_dathang:"",
                loai_hang: '',
                trungbinh_ban: '',
                loai_yeucau: '',
                ngay_giaokhach: null,
            },
            image: null,
            imageData: {
                data_image: "",
                name:"",
                extension: ""
            },

            headers2: [
                {text:"", value: "actions", width:"5rem"},
                {text:"Mã", value: "No_", width:"7rem", 
                    filter: value => {
                        if (!this.filterCode) return true
            
                        return value.toUpperCase().includes(this.filterCode.toUpperCase())
                    },
                },
                {text:"Tên", value: "Name", width:"7rem", 
                    filter: value => {
                        if (!this.filterCode) return true
            
                        return value.toUpperCase().includes(this.filterCode.toUpperCase())
                    },
                },
                {text:"Mô tả", value: "Description 2", width:"7rem"}
            ],

            questionTypes: [
                {text: "Tự luận", value: "TEXT"},
                {text: "Lựa chọn", value: "OPTION"},
                {text: "Nhiều lựa chọn", value: "MULTI_OPTION", disabled: true},
            ],
            question:{
                type: "OPTION",
                name:"",
            },
            questions:[],

            answerHeader:[
                {text:"Câu trả lời", value:"name"},
                {text:"Xóa", value:"actions", width: 150}

            ],
            answers: [],
            answer:"",

            dialogDelete: false,
            targetItem: null,
            dialog: false,
            questionDialog:false,
        }
    },
    methods: {
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY")
        },
        openDialog(event, id) {
            if(id){
                alert(event, id)
            }else {
                this.$refs.create_employee_report_dialog.dialog = !this.$refs.create_employee_report_dialog.dialog
            }
        },
        openDialogCarousel() {
            console.log(this.targetItem)
        },
        handleFileChange(file) {
            //reset
            const filename = file.name;
            const extension = filename.split('.').pop();

            this.imageData.name="xxxx";
            this.imageData.extension = extension

            //read base64
            const reader = new FileReader();
            
            reader.onload = (e) => {
                this.imageData.data_image = e.target.result.split(',')[1];
                console.log(e.target.result)
            };
            reader.readAsDataURL(file);
        },
        convertImage(data) {
            const imgPrefix = 'https://storage.googleapis.com/tshrm-bucket/Uploads/DatHang/'
            if(data)
            {
                let arr = JSON.parse(data)
                if(arr.length>0){
                    let imgObj = arr[0]
                    return imgPrefix+imgObj['0']
                }
                return ''
            }
            return ''
        },

        fetchData() {
            return this.apiService.get(this.filterMonth).then(d => {
                this.items1 = d
            })
        },
        setSelect(e)
        {
            this.item= {code : e};
        },
        
        edit(item){
            this.$refs.view_employee_report_dialog.dialog = true
            this.$refs.view_employee_report_dialog.item = item

        },
        publish(item) {
            this.apiService.publishTemplate(item).then(() => this.fetchData())
        },

        // deleteItem(item) {
        //     this.dialogDelete = true;
        //     this.targetItem = item;
        // },
        cfmDeleteItem() {
            return this.apiService.delete(this.targetItem.id).then(() => {
                this.fetchData()
            }).then(() => {
                this.dialogDelete = false
            })
            
        },

        //template
        openBottom(item, status, event) {
            this.item1SelectObj = item
            // if(status == 'Đã xác nhận' || status == 'Không đặt được')
            this.bottomSheet = true;
            if(status == "Đã xác nhận")
                this.bottomSheetType = "ACCEPT"
            if(status == "Không đặt được")
                this.bottomSheetType = "DENY"
            if(status == "Chờ xác nhận"){
                this.bottomSheetType = "RETURN"
            }
            console.log(item, event)
        },
        closeBottom() {
            this.bottomSheet = false
            this.bottomSheetType = ''
            this.note = ''
        },

        deleteItem() {
            return this.apiService.deleteItem(this.targetItem).then(()=> {
                this.targetItem=undefined
                this.dialogDelete = false
                this.fetchData()
            })
        },
        
        exportTemplate(item){
            try {
                this.loadingDialog = true
                return http.get("surveys/"+item.id+"/export", {
                    params: {
                        month: moment(this.month).format('YYYY-MM-DD')
                    },
                    responseType: 'blob',
                }).then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `EXPORT_KHAO_SAT${moment(this.month, 'yyyy-MM-dd').format("MM-YYYY")}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }).finally(()=> {
                    this.loadingDialog=false
                });
            } catch(e){
                alert(e)
            }
        },

        ///QUESTION
        closeQuestion() {
            this.questionDialog = false
            this.answers = []
            this.answer = ""
            this.question.name = ""
            this.question.type = "OPTION"
        },
        getQuestions() {
            try{
                return this.apiService.getQuestions(this.selectedTemplate).then(d => {
                    this.questions = d.data
                })
            }catch(e)
            {
                alert(e)
            }
        },
        saveQuestion(){
            let mappedAnswer = this.answers.map(x=>x.name)
            let postData = {
                name: this.question.name,
                type: this.question.type,
                answers: mappedAnswer
            }
            return this.apiService.postQuestion(this.selectedTemplate, postData).then(() => {
                this.closeQuestion()
                return this.getQuestions()
            })
        },
        deleteQuestion(id) {
            return this.apiService.deleteQuestion(this.selectedTemplate, id).then(() => {
                return this.getQuestions()
            })
        },
        changeQuestionOrder(id, mode) {
            return this.apiService.changeQuestionOrder(this.selectedTemplate, id, mode).then(() => {
                return this.getQuestions()
            })
        },

        /// ANSWER
        addAnswer() {
            this.answers.push({name: this.answer});
            this.answer="";
        },
        deleteAnswer(index) {
            // let index = this.answers.findIndex(obj => obj.name === name);
            if (index !== -1) {
            let removedObject = this.answers.splice(index, 1); // Remove the object from the array
            } else {
                alert('Object not found in the array.');
            }
        },
        
        getToken() {
            let userJson = localStorage.getItem("user")
            let user = JSON.parse(userJson)
            if(user){
                //NS0012 ma CM
                // ma asm NS0138
                let employeeCode = 'NS2977'
                if(user.employeeCode != 'NV05039')
                    employeeCode = user.employeeCode
                return this.apiService.getToken(employeeCode).then(d => {
                    this.TOKEN = d.token
                    this.USER_ID = d.UserID
                    this.GROUP_MA = d.GroupMa
                    this.Sources = d.sourceCode
                    this.dathangForm.userid_tao = d.UserID
                    // this.dathangForm.chinhanh_dutru = d.sourceCode
                })
            }
        },
        getPhieu() {
          return this.apiService.getPhieu(this.TOKEN, this.USER_ID, this.filterFromDate, this.filterToDate).then(d => {
            this.items1 = d.ds_dathang.filter(x=>this.Sources.includes(x.ChiNhanhDuTru) )
          })
        },

        deletePhieu(id) {
            return this.apiService.deletePhieu(id, this.USER_ID).then(() => this.getPhieu())
        }, 
        getHangHoa() {
            return this.apiService.getHangHoa(this.hanghoaFilterType, this.hanghoaFilter).then(d => {
                console.log(d)
                this.items2 = d
            })
        },
        getHangHoaUnit() {
            this.hanghoaUnits = []
            this.dathangForm.dvt = ''
            this.dathangForm.tonkho_hethong = 0
            return this.apiService.getHangHoaUnit(this.dathangForm.mahanghoa, this.USER_ID).then(d => {
                this.hanghoaUnits = d.data
                return true
            })
        },
        setHangHoa(item) {
            // console.log(this.item2Select[0])
            this.dathangForm.mahanghoa = item.No_
            this.dathangForm.tenhang = item.Name
            this.getHangHoaUnit().then(()=> {this.hanghoaDialog = false})

            // reset filter
            this.hanghoaFilter = '',
            this.hanghoaFilterType='Name'
        },

        //SUBMIT
        asmCheckConfirm(value, event) {
            console.log(value,event)
            this.apiService.asmConfirm({
                "ID": value.ID,
                "user_capnhat": this.USER_ID,
                "asm_duyet": this.USER_ID,
                "tinhtrang_asm": "ASM đã duyệt"
            }).then(() => this.getPhieu())
        },

        addDatHang() {
            this.$refs.dathang_form.validate();
            // alert(this.dathangFormValid)
            if(this.dathangFormValid){
                // alert("true")
                this.dathangForm.base64_image.push(this.imageData)
                this.dathangForm.tonkho_hethong = this.hanghoaOutter ? 0: this.dathangForm.tonkho_hethong

                if(this.hanghoaOutter == false)
                    this.dathangForm.base64_image = null

                console.log(this.dathangForm)
                return this.apiService.postDatHang(this.dathangForm).then(() => {
                    this.getPhieu()
                }).finally(() =>{
                    // reset
                    this.hanghoaUnits = []
                    this.dathangForm.mahanghoa =""
                    this.dathangForm.soluong_dutru =""
                    this.dathangForm.dvt =""
                    this.dathangForm.tenhang =""
                    this.dathangForm.chinhanh_dutru =""
                    this.dathangForm.tonkho_hethong =""
                    // this.dathangForm.userid_tao =""
                    this.dathangForm.base64_image =[]
                    this.dathangForm.ghichu_dathang=""
                    this.dathangForm.loai_hang= ''
                    this.dathangForm.trungbinh_ban= ''
                    this.dathangForm.loai_yeucau= ''
                    this.dathangForm.ngay_giaokhach= null
                    
                    //reset fileData
                    this.imageData.data_image = ""
                    this.imageData.name = ""
                    this.imageData.extension = ""
                    this.image = null
                })
            }else {
                // alert("false")
            }
        },

        // getHang
        maHangHoaOnChange(value) {
            this.getHangHoaUnit()
            return this.apiService.getHangHoa("No_", value).then(d => {
                this.dathangForm.tenhang = d.filter(x=>x.No_ == value)[0].Name
            }).catch(e => {
                console.log(e)
                this.dathangForm.tenhang=''
            })
        },
        
        setStatusNote() {
            if(this.item1SelectObj){
                if(this.bottomSheetType == "ACCEPT")
                    return this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, this.note, 'ACCEPT').then(() => {
                            return this.apiService.setTinhTrang(this.item1SelectObj.ID, this.USER_ID,"Đã xác nhận").then(() =>
                            this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'DENY').then(()=>{
                                this.closeBottom()
                                return this.getPhieu()
                            })
                        )
                    })
                if(this.bottomSheetType == "DENY")
                    return this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, this.note, 'DENY').then(() => {
                        return this.apiService.setTinhTrang(this.item1SelectObj.ID, this.USER_ID,"Không đặt được").then(() => 
                        this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'ACCEPT').then(()=>{
                            this.closeBottom()
                            return this.getPhieu()
                            })
                        )
                    })
                if(this.bottomSheetType == "RETURN"){
                    return this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'ACCEPT').then(() => {
                        return this.apiService.setTinhTrang(this.item1SelectObj.ID, this.USER_ID,"Chờ xác nhận").then(() =>
                            this.apiService.setTinhTrangNote(this.item1SelectObj.ID, this.USER_ID, "", 'DENY').then(()=>{
                                this.closeBottom()
                                this.getPhieu()
                            })
                        )
                    })
                }
            }
            return true
        },

    },

    apiService: null,
    created() {
        this.apiService = new api()
    },
    mounted () {
      this.fetchData()
    //   this.getToken().then(()=> {
    //     this.getHangHoa()
    //     this.getPhieu()
    //     })
      
    }
}
</script>