<template>
  <v-main>
    <v-container style="min-height:calc(100vh - 172px)" class="mt-3">
      <v-fade-transition mode="out-in">
        <!--The Main Content component go here-->
        <router-view></router-view>
      </v-fade-transition>
          <v-fab-transition>
            <v-menu top :offset-y="true" :close-on-content-click="false" :nudge-top="10">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="orange"
                  dark fixed
                  v-bind="attrs"
                  v-on="on"
                  bottom right fab >
                  <v-icon>mdi-message-reply</v-icon>
                </v-btn>
              </template>
              <v-card width="200px">
                <v-card-text>
                  <v-form @submit.prevent="sendFeedback">
                    <v-textarea label="Góp ý" color="orange" filled hide-details class="mb-3" v-model="feedBack.body">
                    </v-textarea>
                    <v-btn small right class="ml-auto" depressed color="teal" type="submit" dark> Gởi</v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-fab-transition>
    </v-container>
  </v-main>
</template>
<script>
// import SideBar from "@/components/SideBar.vue";
import http from '@/components/services/http-common';
export default {
    components:{
        // SideBar
    },
    computed:{
      webAllowed() {
          let user = localStorage.getItem("user");
          let webAllowed = localStorage.getItem("WebAllowed");
          user = JSON.parse(user);
        return webAllowed.includes(user.employeeCode);
      },
      items(){
          
          let data= [
            { title: "Chức năng", subheader:true},
            { title: "Đặt hàng", icon: "mdi-text-box-outline", route:{name:"DatHang"}, auth:['module:cm'], webAllowed: true },
            { title: "Cài đặt", subheader:true},
          ]
        return data
      }
    },
    data() {
        return {
          feedBack: {
            body:"",
          }
        }
    },
    methods: {
      sendFeedback() {
        return http.post("feedbacks", this.feedBack).then(() => {
          console.log("feedback")
        })
      }
    },
    created(){
      this.$emit('updateSidebar', this.items);
    }
}
</script>