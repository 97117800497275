<template>
    <div class="assignment">
        <!-- <create-dialog v-model="dialog" :item="item" @fetch="fetchData"></create-dialog> -->
        <v-card>
            <v-card-title>
                Danh sách phiếu công tác
            <v-spacer></v-spacer>
            <v-btn @click="fetchData" class="mx-3" color="orange" icon>
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn icon @click="adminDialog=!adminDialog" color="primary"> <v-icon>mdi-plus</v-icon> </v-btn>
            <excel-button @click="exportPromt=true"></excel-button>
            <export-prompt v-model="exportPromt" url="BusinessRequests" :timeLimit="true" fileName="DU_LIEU_PHIEU_CONG_TAC"></export-prompt>
            <month-picker v-model="month" label="Chọn tháng" width="7rem" dense outlined></month-picker>
            <!-- <data-filter v-model="params" :filter="filter" @fetchData="fetchData">
            </data-filter> -->
            </v-card-title>
            <!-- ******** dialog ADMIN ********  -->
            <v-dialog v-model="adminDialog" max-width="600px">
                <v-card>
                    <v-card-title>
                        Tạo mới đơn đi công tác <v-spacer></v-spacer> <v-icon small @click="adminDialog=false">mdi-close</v-icon>
                    </v-card-title>
                    <v-form @submit.prevent="saveData" v-model="valid" ref="form">
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-autocomplete v-model="form.employeeCode"  @update:search-input="fetchEmployee" label="Nhân viên *" 
                                        :filter="customFilter"
                                        hide-no-data :items="employees" item-text="name" item-value="code">
                                        <template v-slot:item="data">
                                            <strong>{{ data.item.code }}: </strong> {{` ${data.item.name} - ${data.item.department}`}}
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <date-picker2 v-model="form.date" :range="false" label="Ngày áp dụng"></date-picker2>
                                </v-col>
                                
                                <v-col cols="12" md="6">
                                    <v-text-field step='0.5' v-model="form.amount" label="Giờ công tác" type="number" :rules="[x=>x%0.5==0||'Không hợp lệ']"></v-text-field>
                                </v-col>
                                <v-col cols="12" >
                                    <v-autocomplete v-model="form.businessTypeCode" :items="formData.businessTypes" item-text="name" item-value="code" label="Lý do">
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="form.note" solo flat outlined label="Diễn giải">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn dark color="primary" type="submit" small>
                                Lưu
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
            
            <v-data-table
                :mobile-breakpoint="0"
                height="600px" fixed-header
                :headers="headers"
                :items="items"
                :items-per-page="tableParams.pageSize"
                :page.sync="tableParams.page"
                :sort-by.sync="tableParams.sortBy"
                :sort-desc.sync="tableParams.sortDesc"
                :server-items-length="rowCount"
                :loading="isLoading"
                loading-text="Đang tải dữ liệu..."
                :footer-props="{
                    itemsPerPageOptions:[
                    50, 100, 200
                    ]
                }"
            >
                <template v-slot:[`body.prepend`]="" >
                    <tr>
                        <td></td>
                        <td>
                            <v-select placeholder="Chọn" dense v-model="filters.status" outlined hide-details
                            :items='[{text:"Tất cả", value:-1},{text:"Chờ XN", value:0}, {text:"Đã XN", value:1}, {text:"Từ chối", value:2}]'></v-select>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.code" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-select placeholder="Chọn" dense v-model="filters.businessTypeCode" :items="leavePlans" item-text="name" item-value="code"
                             outlined hide-details></v-select>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.employeeCode" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.employeeName" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.departmentCode" outlined hide-details
                            item-text="name" item-value="code" :items="departments" :search-input.sync="departmentSearch" hide-no-data clearable
                            @update:search-input="fetchDepartment"></v-autocomplete>
                        </td>
                        <td colspan="100%"></td>
                    </tr>
                </template>
                <template v-slot:[`item.actions`]="{item}" >
                    <!-- ********* Edit ****** -->
                    <v-btn x-small class="mx-1 my-1 elevation-1" v-if="item.status == 0" color="success" @click="accept(item)" dark>
                        <v-icon small >
                            mdi-check
                        </v-icon> Đồng ý
                    </v-btn>

                    <!-- ********* Xoa ****** -->
                    <v-btn x-small class="mx-1 my-1 elevation-1 " v-if="item.status == 0" color="error" @click="decline(item)" dark >
                        <v-icon small >
                            mdi-close
                        </v-icon>Từ chối
                    </v-btn>

                    <!-- ********* Undo ****** -->
                    <v-btn x-small class="mx-1 elevation-1" dark v-if="item.status != 0" color="blue-grey" @click="undo(item)" >
                        <v-icon small   >
                            mdi-undo
                        </v-icon>Hoàn tác
                    </v-btn>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip :color="$helper.formatStatus(item.status).color" label small outlined>
                        <strong> {{$helper.formatStatus(item.status).text}}</strong>
                    </v-chip>
                </template>
                <template v-slot:[`item.departmentName`]="{ item }">
                    <v-chip class="ma-2 caption font-weight-bold" color="orange" label text-color="white" >
                    <v-icon left>
                        mdi-store
                    </v-icon>
                    {{item.departmentName}}
                    </v-chip>
                </template>

                <template v-slot:[`item.sentDate`]="{ item }">
                    {{formatDate(item.sentDate)}}
                </template>
                <template v-slot:[`item.date`]="{ item }">
                    {{formatDate(item.date)}}
                </template>
                <template v-slot:[`item.confirmDate`]="{ item }">
                    {{formatDate(item.confirmDate)}}
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
import ApiServices from '@/components/services/ApiServices';
import DataFilter from '@/components/DataFilter';
// import CreateDialog from './Create.vue';
import * as moment from "moment/moment";

export default {
    name: "Attendance",
    components: {
        'data-filter': DataFilter,
        // 'create-dialog': CreateDialog,

    },
    props: ["icon"],
    watch: {
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        month:{
            handler(val){
                this.filters.from=moment(val, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
                this.filters.to=moment(val, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
            }
        }
    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },
    data () {
        return {
            ROOT_URL: "BusinessRequests/",
            BUSINESSTYPE_URL: "businesstypes/",
            adminDialog: false,
            exportPromt: false,
            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
                pageSize: 50
            },

            month: moment().format("YYYY-MM"),
            leavePlans:[],
            filters:{
                status: -1,
                code: null,
                employeeCode: null,
                employeeName: null,
                departmentCode: null,
                businessTypeCode:null,
                from: moment().startOf("month").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
            },
            rowCount: 0,

            departmentSearch:null,
            departments: [],

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [],
                FilterOn:["DepartmentTypeCode"],
                
                from: undefined,
                to: undefined,
                fromOns: [
                ],
                fromOn: "",
            },
            
            // VIEW DATA
            filterOpen: 1,

            //DATA TABLE
            totalItem: 0,
            items: [],
            search:"",
            options: {},
            isLoading: true,

            dialog: false, item: null,
            dialogDelete: false,
            targetItem: undefined,

            headers: [
                { text: 'Thao tác', value: 'actions', width: '7rem'},
                { text: 'Trạng thái', align: 'start', value: 'status', width: '7rem'},
                { text: 'Mã', align: 'start', value: 'code', width: '7rem'},
                { text: 'Loại công tác', align: 'start', value: 'businessTypeName', width: '9rem'},
                { text: 'Mã nhân viên', align: 'start', value: 'employeeCode', width: '7rem'},
                { text: 'Nhân viên', align: 'start', value: 'employeeName', width: '12rem'},
                { text: 'Phòng', align: 'start', value: 'departmentName', width: '7rem'},
                { text: 'Ngày gởi', align: 'start', value: 'sentDate', width: '7rem'},
                { text: 'Ngày áp dụng', align: 'start', value: 'date', width: '7rem'},
                { text: 'Số giờ', align: 'start', value: 'amount'},
                { text: 'Ghi chú', align: 'start', value: 'note', width: '12rem'},
                { text: 'Người xác nhận', align: 'start', value: 'confirmedBy'},
                { text: 'Ngày xác nhận', align: 'start', value: 'confirmDate'},
            ],

            valid: false,
            employees:[],
            formData: {
                businessTypes: [],
            },
            form: {
                businessTypeCode: "",
                date: new Date().toISOString().substr(0,10),
                note: "",
                amountOvertime: 0,
                symbol: "",
            },

            defaultForm: {
                businessTypeCode: "",
                date: new Date().toISOString().substr(0,10),
                note: "",
                amountOvertime: 0,
                symbol: "",
            },
        }
    },
    methods: {
        customFilter(item, queryText, itemText) {
            return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || item.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        },
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        formatStatus(value){
            switch(value){
                case 0:{
                    return "Chờ duyệt";
                }
                case 1:{
                    return "Chấp nhận";
                }
                case 2:{
                    return "Từ chối";
                }
            }
        },
        formatTime(value) {
            return moment(value).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchLeavePlan(){
            ApiServices.fetchLeavePlan().then(res=>{
                this.leavePlans = res.data;
            })
        },
        fetchDepartment() {
            return http.get("departments/fetch", {
                params: {
                    search: this.departmentSearch
                }
            }).then(res => {
                this.departments = res.data;
            })
        },
        fetchData () {
            this.isLoading = true;
            return http.get(this.ROOT_URL+'admin/', {
                params: this.params
            })
            .then(res => {
                this.items = res.data.results;
                this.rowCount = res.data.rowCount;
            })
            .catch(err => {

            })
            .finally(() => {
                this.isLoading = false;
            })
        },
        
        saveData() {
            this.$refs.form.validate();
            if(this.valid)
            {
                return http.post(this.ROOT_URL + "admin", this.form)
                .then(res => {
                    this.fetchData();
                    this.adminDialog = false;
                })
                .catch(err => {
                });
            }
        },
        
        accept(item) {
            return http.get(this.ROOT_URL + "accept/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        decline(item) {
            return http.get(this.ROOT_URL + "decline/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },

        undo(item) {
            http.get(this.ROOT_URL + "undo/" + item.code).then(res => {
                this.fetchData();
            }).catch(err => {
                console.log(err)
            });
        },
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        
        remove(item){
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            })
        },

        fetchBusinessType () {
            return http.get(this.BUSINESSTYPE_URL)
            .then(res => {
                this.formData.businessTypes = res.data.items;
            }).catch(err => {throw err})

        },
        fetchEmployee(val){
            ApiServices.fetchEmployee(val).then(res=>{
                this.employees = res.data;
                console.log(res.data);
            })
        },
    },
    created () {
        this.fetchData();
        this.fetchBusinessType();
    }
}
</script>
<style scoped>
    ::v-deep table > tbody > tr:nth-child(1)>td {
        padding-right: 1px !important;
        padding-left: 1px !important;
    }
</style>