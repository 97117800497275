<template>
    <div class="profile">
        <edit-profile-dialog v-model="editDialog" :employee="ClientUser.employeeCode"></edit-profile-dialog>
        <v-card-title>
            <span class="text-h5">Hồ sơ của bạn</span> <v-divider class="ml-4"></v-divider>
        </v-card-title>
        <v-card class="elevation-6 my-6 rounded-xl">
            <v-row>
                <v-col cols="12" md="4" class="text-center align-center d-flex justify-center">
                    <div class="basic-wrapper py-6">
                        <v-avatar class="profile mx-auto elevation-3"
                            color="grey" size="200" >
                            <v-img :src="avatarUrl"></v-img>
                        </v-avatar>
                        <h5 class="title mt-5 mb-3">
                            <v-icon class="font-weight-bold" :color="data.gender ? 'blue' : 'red'">{{ data.gender ? 'mdi-gender-male': 'mdi-gender-female' }}</v-icon>
                             {{ data.name }} - {{ data.code }}
                        </h5>
                        <h4 class="mb-4">{{ data.departmentTypeName == 'Hỗ trợ'? 'Phòng': 'Chi nhánh' }} {{ data.departmentName }}</h4> 
                        <div>
                            <!-- <v-chip color="orange lighten-4 elevation-2" class="mx-2"><b class="mr-2 orange--text text--darken-3">Chức vụ:</b> {{data.positionName}}</v-chip>
                            <v-chip color="orange lighten-4 elevation-2" class="mx-2"><b class="mr-2 orange--text text--darken-3">Chức danh:</b> {{data.positionStateName}}</v-chip> -->
                            {{ data.positionName != data.positionStateName ? data.positionName :'' }} {{data.positionStateName}}
                        </div>
                    </div>
                </v-col>
                <v-col>
                    <v-card-subtitle class=" text--secondary text-subtitle-1 pb-0 bt-4">
                        Thông tin cơ bản
                    </v-card-subtitle>
                    <v-card-text class="body-1">
                        <v-row class="my-0">
                            <v-col cols="12" md="6" lg="4" class="">
                                <span class="text-subtitle-1 mr-2 ">Ngày sinh: </span>
                                <span class="text--primary font-weight-medium">{{ formatDate(data.birthDay) }}</span>
                            </v-col>
                            <v-col cols="12" md="6" lg="4" class="">
                                <span class="text-subtitle-1 mr-2">SĐT: </span>
                                <span class="text--primary font-weight-medium">{{ data.phoneNumber }}</span>
                            </v-col>
                            <v-col cols="12" md="6" lg="4" class="">
                                <span class="text-subtitle-1 mr-2">Email: </span>
                                <span class="text--primary font-weight-medium">{{ data.mail }}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider ></v-divider>
                    <v-card-subtitle class=" text--secondary text-subtitle-1 pb-0 bt-4">
                        Địa chỉ
                    </v-card-subtitle>
                    <v-card-text class="body-1">
                        <v-row class="my-0">
                            <v-col cols="12" md="4" class="">
                                <span class="text-subtitle-1 mr-2">Địa chỉ 1: </span>
                                <span class="text--primary font-weight-medium">{{ `${(data.address=='')?'':data.address+', '} ${data.ward==null?'':data.ward+', '} ${data.district==null?'':data.district+', '} ${data.city}`}}</span>
                            </v-col>
                            <v-col cols="12" md="4" class="">
                                <span class="text-subtitle-1 mr-2">Địa chỉ 2: </span>
                                <span class="text--primary font-weight-medium">{{ `${(data.domicileAddress=='')?'':data.domicileAddress+', '} ${data.dWardName==null?'':data.dWardName+', '} ${data.dDistrict==null?'':data.dDistrict +', '} ${data.dCityName}`}}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    
                    <v-divider ></v-divider>
                    <v-card-subtitle class=" text--secondary text-subtitle-1 pb-0 bt-4">
                        Thông tin chi tiết
                    </v-card-subtitle>
                    <v-card-text class="body-1">
                        <v-row class="my-0">
                            <v-col cols="12" md="4" class="">
                                <span class="text-subtitle-1 mr-2 ">Số CMND/CCCD: </span>
                                <span class="text--primary font-weight-medium">{{ data.idNumber }}</span>
                            </v-col>
                            <v-col cols="12" md="4" class="">
                                <span class="text-subtitle-1 mr-2 ">Ngày cấp: </span>
                                <span class="text--primary font-weight-medium">{{ formatDate(data.idDate) }}</span>
                            </v-col>
                            <v-col cols="12" md="4" class="">
                                <span class="text-subtitle-1 mr-2 ">Nơi cấp: </span>
                                <span class="text--primary font-weight-medium">{{ data.idPlace }}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider ></v-divider>
                    <v-card-subtitle class=" text--secondary text-subtitle-1 pb-0 bt-4">
                        Học vấn
                    </v-card-subtitle>
                    <v-card-text class="body-1">
                        <v-row class="my-0">
                            <v-col cols="12" md="4" class="">
                                <span class="text-subtitle-1 mr-2 ">Học vấn: </span>
                                <span class="text--primary font-weight-medium">{{ data.educationLevelName }}</span>
                            </v-col>
                            <v-col cols="12" md="4" class="">
                                <span class="text-subtitle-1 mr-2 ">Chuyên ngành: </span>
                                <span class="text--primary font-weight-medium">{{ data.educationMajorName }}</span>
                            </v-col>
                            <v-col cols="12" md="4" class="">
                                <span class="text-subtitle-1 mr-2 ">Cơ sở đào tạo: </span>
                                <span class="text--primary font-weight-medium">{{ data.universityName }}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    
                    <v-divider ></v-divider>
                    <v-card-subtitle class=" text--secondary text-subtitle-1 pb-0 bt-4">
                        Thông tin khác
                    </v-card-subtitle>
                    <v-card-text class="body-1">
                        <v-row class="my-0">
                            <v-col cols="12" md="4" class="">
                                <span class="text-subtitle-1 mr-2 ">Dân tộc: </span>
                                <span class="text--primary font-weight-medium">{{ data.ethnicity }}</span>
                            </v-col>
                            <v-col cols="12" md="4" class="">
                                <span class="text-subtitle-1 mr-2 ">Tôn giáo: </span>
                                <span class="text--primary font-weight-medium">{{ data.religion }}</span>
                            </v-col>
                            <v-col cols="12" md="4" class="">
                                <span class="text-subtitle-1 mr-2 ">Quốc tịch: </span>
                                <span class="text--primary font-weight-medium">{{ data.origin }}</span>
                            </v-col>
                            <v-col cols="12" md="4" class="">
                                <span class="text-subtitle-1 mr-2 ">Mã cài đặt APP iOS: </span> 
                                <v-btn @click="getIosCode" small dark color="blue" class="rounded-pill font-weight-bold">Tạo mã</v-btn>
                                <span class="text--primary font-weight-medium d-block">{{iOSCode}}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
        
        <v-card-title>
            <span class="text-h5">Thông tin công việc</span> <v-divider class="ml-4"></v-divider>
        </v-card-title>
        <v-row>
            <v-col cols="12" >
                <v-tabs background-color="transparent" color="green darken-2" slider-color="orange" v-model="tabIndex" centered >
                    <!-- <v-tab @change="tabInfoHandler" :key="0">
                        <span class="title">Thông tin cơ bản</span>
                    </v-tab> -->
                    <v-tab @change="tabHistoryHandler" :key="0">
                        <span class="title">Công việc & Hợp đồng</span>
                    </v-tab>
                    <v-tab @change="tabEnrollmentHandler" :key="1">
                        <span class="title">Chấm công</span>
                    </v-tab>
                </v-tabs>
                <v-card  class="rounded-xl elevation-6" >
                    <v-tabs-items v-model="tabIndex">
                        <!-- <v-tab-item :key="0">
                            <v-expansion-panels flat :value="0">
                                <v-expansion-panel :key="0">
                                    <v-expansion-panel-header>
                                        <strong>Thông tin cá nhân</strong>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Mã nhân viên:</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{ data.code }}
                                            </v-list-item-content>
                                        </v-list-item>
                                        
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Mã BFO:</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{ data.bfoCode }}
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Tên:</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{ data.name }}
                                            </v-list-item-content>
                                        </v-list-item>
                                        
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Giới tính: </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{ data.gender ? "Nam" : "Nữ" }}
                                            </v-list-item-content>
                                        </v-list-item>
                                        
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title >Ngày sinh:
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{ data.birthDay }}
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Địa chỉ:</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content> {{ `${(data.address=='')?'':data.address+', '} ${data.ward==null?'':data.ward+', '} ${data.district==null?'':data.district+', '} ${data.city}`}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title >Số CMND:
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{data.idNumber}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Ngày cấp CMND:
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{ formatDate(data.idDate) }}
                                            </v-list-item-content>
                                        </v-list-item>
                                        
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Nơi cấp CMND:
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{data.idPlace}}
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-divider></v-divider>
                                        
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title >Học vấn:
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{ data.educationLevelName }}
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title >Trường:
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{ data.universityName }}
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title >Học vị:
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{ data.academicRankName }}
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title >Chuyên ngành:
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{ data.educationMajorName }}
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider></v-divider>

                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title >Dân tộc:
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{ data.ethnicity}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title >Tôn giáo: </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{ data.religion }}
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title >Quốc tịch:
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{ data.origin }}
                                            </v-list-item-content>
                                        </v-list-item>
                                        
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                
                                <v-expansion-panel :key="1">
                                    <v-expansion-panel-header>
                                        <strong>Công việc</strong>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Thuộc khối:</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{data.departmentTypeName}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Thuộc phòng ban:</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{data.departmentName}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Chức vụ:</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{data.positionName}}
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Ca làm việc:</v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                {{data.shiftName}}
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                
                                <v-expansion-panel :key="2">
                                    <v-form @submit.prevent="updateAccount">
                                        <v-expansion-panel-header>
                                            <strong>Tài khoản</strong>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Tên tài khoản:</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-content>
                                                    {{account.userName}}
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Mật khẩu:</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-content>
                                                    <v-text-field dense v-model="account.password" type="password">
                                                    </v-text-field>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Xác nhận mật khẩu:</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-content>
                                                    <v-text-field dense v-model="account.confirmPassword" type="password">
                                                    </v-text-field>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="lime lighten-4" type="submit" >Lưu</v-btn>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-expansion-panel-content>
                                    </v-form>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-tab-item> -->
                        <v-tab-item :key="0">
                            <v-card-text>
                                <v-data-table  :headers="contractHeader" :items="contractHistory" >
                                    <template v-slot:top>
                                        <v-toolbar flat >
                                            <h3 style="width: 100%;">
                                            <strong>Danh sách hợp đồng:</strong>
                                            </h3>
                                            <!-- <v-divider class="ml-auto" inset vertical ></v-divider> -->
                                        </v-toolbar>
                                        <v-subheader class="red--text font-weight-medium mt-0 d-block">
                                            <span>Nếu nhân viên mở bằng trình duyệt của zalo sẽ không tải được file. Ấn vào 3 chấm và mở bằng safari(Iphone) hoặc Chrome 
                                            với các thiết bị còn lại.</span>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon class="d-inline-block"
                                                    color="primary"
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    >
                                                    mdi-image
                                                    </v-icon>
                                                </template>
                                                <img src="/static/help/contract_help.jpg" alt="" srcset="" width="256">
                                            </v-tooltip>
                                        </v-subheader>
                                    </template>
                                    <template v-slot:[`item.validFrom`]="{ item }">
                                        {{ formatDate(item.validFrom) }}
                                    </template>
                                    <template v-slot:[`item.validTo`]="{ item }">
                                        {{ formatDate(item.validTo) }}
                                    </template>
                                    <template v-slot:[`item.status`]="{ item }">
                                        <v-chip outlined class="font-weight-medium" small :color="contractStatusDict[item.status].color">
                                            {{ contractStatusDict[item.status].name }}
                                        </v-chip>
                                        
                                    </template>
                                    <template v-slot:[`item.htmlSource`]="{ item }">
                                        
                                        <v-dialog  hide-overlay transition="dialog-bottom-transition" max-width="800" scrollable>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn color="success" small class="rounded-xl"
                                                v-bind="attrs"  v-on="on" >
                                                    Xem hợp đồng
                                                </v-btn>
                                            </template>
                                        <v-card>
                                            <v-card-title>
                                                Hợp đồng số {{ item.contractNumber }}
                                            </v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text>
                                                <span v-html="item.htmlSource"></span>
                                            </v-card-text>
                                            <v-card-actions class="justify-center flex-wrap" v-if="item.status==2">
                                                <div class="d-block my-2" style="width: 100%;">
                                                    <small class="error--text font-weight-medium">
                                                        Nhân viên đọc qua bản hợp đồng  <span class="success--text font-weight-bold">xác nhận thông tin </span> 
                                                        , nếu có <b>sai sót</b> hãy ấn trả về kèm ghi chú!
                                                    </small>
                                                    <v-textarea v-model="contractNote" rows="3" outlined label="Ghi chú" hide-details="auto">

                                                    </v-textarea>
                                                </div>
                                                <v-btn color="success" class="rounded-xl mx-2" @click="changeContractStatus(item.id, 3)">Nhân viên xác nhận</v-btn>

                                                <v-btn color="error" class="rounded-xl mx-2" @click="changeContractStatus(item.id, 4)">Nhân viên trả về</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                        </v-dialog>
                                    </template>
                                    <template v-slot:[`item.fileUrl`]="{ item }">
                                        <a :href="item.fileUrl+'?ticket=' + generateRandom10DigitNumber()">
                                            <v-icon color="red" v-if="item.fileUrl">mdi-file</v-icon> Tải xuống
                                        </a>
                                    </template>
                                </v-data-table>
                            </v-card-text>

                            <v-subheader>
                                <h3>
                                    <strong>Lịch sử công việc:</strong>
                                </h3>
                            </v-subheader>
                            <v-data-table
                            :headers="assignHeader"
                            :items="assignHistory"
                            >
                                <template
                                    v-slot:[`item.assignDate`]="{ item }"
                                >
                                    {{ formatDate(item.assignDate) }}
                                </template>
                            </v-data-table>

                            <!-- Thời gian chấm công -->
                            
                            <!-- Phép được hưởng -->
                            <v-subheader>
                                <h3>
                                <strong>Danh sách phép:</strong>
                                </h3>
                            </v-subheader>
                            <v-data-table
                            :items="LeaveEnrollmentTable"
                            :headers="LeaveEnrollmentHeader"
                            >
                            </v-data-table>
                        </v-tab-item>
                        <v-tab-item :key="1">
                            <v-data-table
                            :headers="enrollHeader"
                            :items="enrollHistory" >
                                <template v-slot:top>
                                    <v-toolbar flat >
                                        <h3> <strong>Lịch sử chấm công:</strong> </h3>
                                        <v-divider class="mx-4" inset vertical ></v-divider>
                                        <v-spacer></v-spacer>
                                        <month-picker v-model="monthYear"></month-picker>
                                    </v-toolbar>
                                </template>
                            </v-data-table>

                            <v-data-table :headers="timesheetHeader" :items="timesheetTable" >
                                <template v-slot:top>
                                    <v-toolbar flat >
                                        <h3> <strong>Ngày công:</strong> </h3>
                                        <v-divider class="mx-4" inset vertical ></v-divider>
                                        <v-spacer></v-spacer>
                                        <month-picker v-model="monthYear"></month-picker>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.isConfirmed`]="{ item }">
                                    <v-icon v-if="item.isConfirmed">mdi-check</v-icon>
                                </template>
                            </v-data-table>
                        </v-tab-item>
                    </v-tabs-items>
                    <v-spacer></v-spacer>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import http from '@/components/services/http-common'
import * as moment from "moment/moment"
import BreadCrumb from '@/components/BreadCrumb'
import EditProfile from './EditProfile';
import EventBus  from "@/components/services/EventBus";
import ApiCaller  from "./js/api.js";

export default {
    name: "Profile",
    components: {
        "myBreadCrumb": BreadCrumb,
        "edit-profile-dialog": EditProfile
    },

    //ANCHOR DATA
    data() {
        return {
            // CONST
            ACCOUNT_URL: "account",
            ENROLL_FETCH_URL: "account/enrollment/",

            editDialog: false,
            icon: {
                icon: "",
                to: ""
            },
            formData: {
                gender: [
                    {text: "Nam", value: "0"},
                    {text: "Nữ", value: "1"},
                ]
            },

            //Update account
            account: {
                UserName: "",
                Password: "",
                ConfirmPassword: "",
            },

            tabIndex: 0,
            tabContent: 0,

            data: {
                avatar: "",
                name: "",
                mail: "",
                phone: "",
                birthDay: "",
                gender: false,
                marriageCode: "",
                email: "",
                idNumber: "",
                idDate: "",
                idPlace: "",
                bankName:"",
                bankNumber: "",

                //address
                address: "",
                city: "",
                district: "",
                ward: "",

                dCityName:"",
                dDistrictName:"",
                dWardName:"",
                domicileAddress:"",
                
                educationLevelName: "",
                universityName: "",
                academicRankName: "",
                majorName: "",
                
                //work
                currentGroup: "",
                currentDepartment: "",
                currentPosition: "",
                currentShift: "",
            },
            endpoint: process.env.VUE_APP_BASE_URL,


            //ENROLLMENTS DATA
            headers: [
                    {text: "", value: "isCheckIn", sortable: false},
                    {text: "Ngày", value: "EnrollmentDate" },
                    {text: "Thời gian", value: "EnrollmentTime" }
            ],
            
            //assignmentHistory
            assignHistory: [],
            assignHeader:[
                {text: "Phòng ban", value: "department"},
                {text: "Vị trí", value: "position"},
                {text: "Ca làm việc", value: "shift"},
                {text: "Ngày chuyển", value: "assignDate"}
            ],

            contractStatusDict: {
                0: {name: "Tạo mới", color: "light-green"},
                1: {name: "Hoàn thành", color: "success"},
                2: {name: "Chờ nhân viên xác nhận", color: "blue"},
                4: {name: "Trả về", color: "error"},
                3: {name: "Chờ duyệt", color: "warning"},
            },
            contractNote: "",
            contractHeader: [
                {text: "ID", value: "id"},
                {text: "Mã", value: "contractNumber"},
                {text: "Loại HĐ", value: "contractCategoryCode"},
                {text: "Ngày ký", value: "validFrom"},
                {text: "Ngày hết hạn", value: "validTo"},
                {text: "Lương", value: "salary"},
                // {text: "Phụ cấp", value: "allowance"},
                // {text: "Phụ cấp khác", value: "otherAllowance"},
                {text: "Trạng thái", value: "status"},
                {text: "Preview", value: "htmlSource"},
                {text: "File", value: "fileUrl"},
            ],
            contractHistory: [],

            //Leave Enrollments
            LeaveEnrollment: false,
            LeaveEnrollmentTable: [],
            LeaveEnrollmentHeader: [
                {text: "Mã", value: "code"},
                {text: "Tên", value: "name"},
                {text: "Còn lại", value: "amount"},
                // {text: "Hạn dùng", value: "expireDate"},
            ],
            
            // Enrollments
            enrollHistory: [],
            enrollHeader: [
                {text: "Ngày chấm công", value: "enrollDate"},
                {text: "Checkin", value: "checkinTime"},
                {text: "Checkout", value: "checkoutTime"},
                {text: "Nơi checkout", value: "department"},
                {text: "Trễ", value: "late"},
                {text: "Về sớm", value: "early"},
                {text: "Số giờ", value: "actualTime"},
            ],
            timesheetTable:[],
            monthYear: moment().format("YYYY/MM"),
            timesheetHeader: [
                {text: "Ngày công", value: "workDate"},
                {text: "Giờ công làm việc", value: "actualWorkTime"},
                {text: "Giờ công ngoài giờ", value: "extendedTime"},
                {text: "Giờ công xác nhận làm thêm", value: "overtime"},
                {text: "Hệ số tăng ca", value: "overtimeScale"},
                {text: "Xác nhận", value: "isConfirmed"},
            ],
            iOSCode:"************"
        }
    },
    computed: {
        ClientUser () {
            let result = localStorage.getItem("user");
            result = JSON.parse(result);
            return result;
        },
        avatarUrl(){
            return this.data.avatar ? this.endpoint + this.data.avatar :'https://cdn.vuetifyjs.com/images/lists/ali.png';
        }
    },
    methods: {
        generateRandom10DigitNumber() {
            const min = 1000000000; // Smallest 10-digit number (10 zeros)
            const max = 9999999999; // Largest 10-digit number (nine nines)

            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        dialogHandler() {
            this.editDialog = false;
        },

        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        // ********* FETCH SECTIONS *******
        fetchData () {
            http.get("account/").then((res)=> {
                let data= res.data;
                console.log(res.data)
                this.data = Object.assign({}, res.data.employee);
                this.account = Object.assign({}, res.data.account);
            }).catch((err) => {
                console.log(err)
            });
        },

        //General Info tab handler
        tabInfoHandler() {
            console.log("tab1");
        },
        tabHistoryHandler() {
            const assign = http.get(this.ACCOUNT_URL + "/assign-history")
            .then(res => {
                let data= res.data;
                this.assignHistory = data.items;
            })
            
            const contract = http.get(this.ACCOUNT_URL + "/contract-history")
            .then(res => {
                let data= res.data;
                this.contractHistory = data.items;
            })
            
            const leave = http.get(this.ACCOUNT_URL + "/leave-enrollment")
            .then(res => {
                let data= res.data;
                this.LeaveEnrollmentTable = data.items;
            })
            
            return http.all([assign, contract, leave]).then(http.spread((...responses) => {
                const assign = responses[0]
                const contract = responses[1]
                const leave = responses[2]
                const timesheet = responses[3]
            })).catch(errors => {
            })
            
        },
        
        
        tabEnrollmentHandler() {
            const enroll = http.get(this.ENROLL_FETCH_URL).then(res => {
                this.enrollHistory = res.data.items;
            });
            
            const timesheet = http.get(this.ACCOUNT_URL + "/timesheet", {
                params:{
                    monthYear: this.monthYear,
                }
            }).then(res=>{
                this.timesheetTable = res.data.items
            })
            return http.all([enroll, timesheet]).then(http.spread((...responses) => {
                const enroll = responses[0];
                const timesheet = responses[1];
            }));
        },
        messageToast (eventObj) {
            EventBus.$emit('hasMessage', eventObj);
        },

        updateAccount () {
            return http.post(this.ACCOUNT_URL+ "/change-password", this.account)
                    .then(res => {
                        let eventObj = {
                            color: "success",
                            message: "Cập nhật thành công",
                            toast: false,
                        };
                        this.messageToast(eventObj);
                    })
                    .catch(err => {
                    });
        },

        getIosCode()
        {
            return http.get("account/get-ios-code", this.account).then(res => {
                this.iOSCode = res.data.data
            })
        },
        
        changeContractStatus(id, status) {
            if(status == 4 && !this.contractNote){
                alert("Bạn phải nhập nội dung trả về")
                return
            }
            return ApiCaller.changeContractStatus(id, status, this.contractNote).then(()=>{
                this.tabHistoryHandler()
                this.contractNote = ""
            })
        }
    },
    created(){
        this.fetchData();
        this.tabHistoryHandler()
    }
    
}
</script>
<style scoped>

</style>