<template>
    <v-main style="min-height:calc(100vh - 161px)">
      <v-fade-transition mode="out-in">
        <v-container class="mt-3">
            <router-view></router-view>
        </v-container>
      </v-fade-transition>
    </v-main>
</template>
<script>
import SideBar from "@/components/SideBar.vue";
export default {
    components:{
        SideBar
    },
    data(){
        return {
            items: [
                { title: "Dashboard", subheader:true},
                { title: "Tổng quan", icon: "mdi-view-dashboard-outline", route:{ name: 'EmployeeOverview'}, auth: ["module:employment"]},
                { title: "Chức năng khác", subheader:true},
                { title: "Sinh mã QR", icon: "mdi-qrcode", route:{ name: 'qrLink'}, auth: [ "module:employment", "qr:module"]},
                { title: "Sơ đồ tổ chức", icon: "mdi-sitemap", route:{ name: 'OrganizationMap'}, auth: [ ]},
                // { title: "Phản hồi", icon: "mdi-message-alert", route:{ name: 'Feedback'}, auth: [ "module:employment"]},
                { title: "B/C videos", icon: "mdi-video-outline", route:{ name: 'videoSurvey'}, auth: [ "module:employment"]},
                { title: "B/C tổng hợp", icon: "mdi-chart-box-plus-outline", route:{ name: 'EmployeeReport'}, auth: [ ]},
                { title: "Chấm công", subheader:true},
                { title: "Giờ công", icon: "mdi-calendar-clock", route:{ name: 'HrTimeSheet'}, auth: [ "timesheet:index"]},
                // { title: "Review giờ công", icon: "mdi-electron-framework", route:{ name: 'ReviewTimesheet'}, auth: [ "timesheet:review"]},
                { title: "Chấm công", icon: "mdi-clock-digital", route:{ name: 'Attendance'}, auth: "module:salary"},
                
                { title: "Yêu cầu", subheader:true},
                { title: "Yêu cầu điều chuyển", icon: "mdi-transfer", route:{name:"EmployeeRotationRequest"}, auth: ["rot:rq"]},
                { title: "Xác nhận đơn", icon: "mdi-note-multiple-outline", group:"/Confirm", active: false, auth: ["module:employment"], subs: [
                  { title: "Tăng ca tối đa", icon: "", route:{name:"ConfirmOvertimeBooking"}, auth: ["module:employment", "otmax:rq"]},
                  { title: "Làm thêm", route:{name:"ConfirmOvertime"}, auth: ["module:employment", "request:index"]},
                  { title: "Công tác", route:{name:"ConfirmBusiness"}, auth: ["module:employment", "request:index"]},
                  { title: "Nghỉ phép", route:{name:"ConfirmLeave"} , auth: ["module:employment", "request:index"]},
                  // { title: "Nghỉ việc", route:{name:"ConfirmQuit"} , auth: ["module:employment"]},
                  // { title: "Nghỉ dài hạn", icon: "", route:{name:"ConfirmLongLeaveRequest"}, auth: ["module:employment", "request:index"]},
                  { title: "Điều chuyển", icon: "mdi-transfer", route:{name:"ConfirmRotationRequest"}, auth: ["rot:ad"]},
                ]},
                
                { title: "Nhân sự", subheader:true},
                { title: "Thông tin nhân sự", icon: "mdi-folder-account-outline", group:"/admin/employment/HR", active: false, auth: ["module:employment"],  subs: [
                  { title: "Lịch làm việc", icon: "mdi-file-check-outline", route:{ name: 'Schedule'}, auth: [ "module:employment"]},
                  { title: "Nguồn tuyển dụng", icon: "mdi-semantic-web", route:{ name: 'EmployeeResource'}, auth: ["employee:index", "module:employment", "employee:rsc"]},
                  { title: "Nhân viên", icon: "mdi-human-greeting", route:{ name: 'Employee'}, path:"admin/hr/employees", auth: ["employee:index", "module:employment"]},
                  // { title: "Chốt nhân viên", icon: "mdi-human-queue", route:{ name: 'MonthlyEmployee'}, auth: ["employee:monthly", "module:employment"]},
                  { title: "Trạng thái chấm công", icon: "mdi-ruler", route:{name:"CurrentEmployee"}, auth: ["actEmp:index"]},
                  // { title: "Lịch sử phân công", icon: "mdi-human-greeting", route:{name:"Assignment"}, path:"/hr/assignments", auth: ["employee:index", "assignment:index"]},
                  { title: "Người dùng", icon: "mdi-account-box-multiple", route:{name:"User"}, auth: ["user:index", "module:employment"]},
                  { title: "Phân quyền", icon: "mdi-ruler", route:{name:"Role"}, auth: ["role:index", "module:employment"]},
                  { title: "Cài đặt ASM", icon: "mdi-ruler", route:{name:"AsmConfig"}, auth: ["role:index", "module:employment"]},
                ]},
                
                { title: "Phúc lợi nhân sự", icon: "mdi-folder-heart-outline", group:"/admin/employment/benefits", active: false, auth: ["module:employment"],  subs: [
                  { title: "Hợp đồng", icon: "mdi-shield-account", route:{name:"Contract"}, auth: ["contract:index", "module:employment"]},
                  { title: "Hợp đồng v2", icon: "mdi-shield-account", route:{name:"Contractv2"}, auth: ["contract:index", "module:employment"]},
                  { title: "Bảo hiểm", icon: "mdi-shield-account", route:{name:"Insurance"}, auth: ["insurance:index", "module:employment"]},
                  { title: "Chế độ", icon: "mdi-ruler", route:{name:"BenefitManager"}, auth: ["benMan:index"]},
                  { title: "Phân bổ phép", icon: "mdi-human-greeting", route:{name:"LeaveEnroll"}, auth: ["employee:index", "module:employment", "leaveEnrollment:index"]},
                  { title: "Thiết bị", icon: "", route:{name:"EmployeeIssue"}, auth: ["module:employment"]},
                ]},

                { title: "Truyền thông", subheader:true},
                { title: "Quản lý khảo sát", icon: "mdi-vote-outline", group:"admin/employment/survey", active: false, auth: ["employee:index", "module:employment", "employee:rsc"], subs: [
                  { title: "Danh mục", icon: "mdi-forum", route:{name: "Survey"}, auth: ["module:employment"] },
                ]},
               
                { title: "Quản lý thông báo", icon: "mdi-bullhorn-outline", group:"admin/employment/content", active: false, auth: [ "cont:edit"], subs: [
                  { title: "Danh mục", icon: "mdi-book-open-page-variant", route:{name: "Category"}, auth: ["module:employment"] },
                  { title: "Bài viết", icon: "mdi-bank", route:{name: "Content"}, auth: ["module:employment"] },
                ]},
                
                { title: "Cài đặt", subheader:true},
                { title: "Danh mục hợp đồng", icon: "mdi-qrcode", route:{ name: 'ContractCategory'}, auth: [ "module:employment","contract:index"]},
                
            ],
        }
    },
    
    created(){
      this.$emit('updateSidebar', this.items);
    }
}
</script>