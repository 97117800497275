<template>
<div>
    <!-- <side-bar ref="sideBar" :items="items"></side-bar> -->
    <v-main>
      <v-container style="min-height:calc(100vh - 124px)" class="mt-3">
        <v-fade-transition mode="out-in">
          <!--The Main Content component go here-->
          <router-view></router-view>
        </v-fade-transition>
      </v-container>
    </v-main>
</div>
</template>
<script>
import SideBar from "@/components/SideBar.vue";
export default {
    components:{
        SideBar
    },
    
    data(){
      return {
        items: [
            { title: "Quản lý vị trí", icon: "mdi-map", group:"/geo", active: false, auth:"module:sysconfig", subs: [
            { title: "Thành phố", icon: "mdi-bank", route:{name:"City"}, auth:"module:sysconfig" },
            { title: "Quận huyện", icon: "mdi-briefcase-variant", route:{name:"District"}, auth:"module:sysconfig" },
            { title: "Phường xã", icon: "mdi-desk", route:{name:"Ward"}, auth:"module:sysconfig" },
            { title: "Khu vực", icon: "mdi-desk", route:{name:"Area"}, auth:"module:sysconfig" },
            ]},
            { title: "Thiết lập", icon: "mdi-cogs", group:"/setting", active: false, auth:"module:sysconfig", subs: [
            { title: "Học vấn", icon: "mdi-book-open-page-variant", route:{name: "Education"}, auth:"module:sysconfig" },
            // { title: "Kỹ năng", icon: "mdi-bank", route:{name: "Skill"}, auth:"module:sysconfig" },
            // { title: "Năng lực", icon: "mdi-bank", route:{name: "Ability"}, auth:"module:sysconfig" },
            { title: "Khối", icon: "mdi-bank", route:{name:"DepartmentType"}, auth:"module:sysconfig" },
            { title: "Phòng ban", icon: "mdi-desk", route:{name:"Department"}, auth:"module:sysconfig" },
            { title: "Chức vụ", icon: "mdi-briefcase-variant", route:{name:"Position"}, auth:"module:sysconfig" },
            { title: "Chức danh", icon: "", route:{name: "PositionState"}, auth: "module:sysconfig"},
            { title: "Ca làm việc", icon: "mdi-clock", route:{name:"Shift"}, auth:"module:sysconfig" },
            { title: "Tăng ca", icon: "mdi-script-outline", route:{name: "OvertimePlan"}, auth:"module:sysconfig" },
            { title: "Hợp đồng", icon: "mdi-script-outline",  route:{name: "ContractType"}, auth:"module:sysconfig" },
            { title: "Công tác", icon: "", route:{name: "BusinessPlan"}, auth:"module:sysconfig" },
            { title: "Nghỉ phép", icon: "mdi-calendar-arrow-left", route:{name:'LeavePlan'}, auth:"module:sysconfig" },
            { title: "Nghỉ dài hạn", icon: "mdi-bank", route:{name: "LongLeave"}, auth:"module:sysconfig" },
            { title: "Ngày lễ", icon: "", route:{name: "Holiday"}, auth:"module:sysconfig" },
            { title: "Thiết bị", icon: "mdi-bank", route:{name:"Property"}, auth:"module:sysconfig" },
            { title: "Hệ số tăng ca theo ca làm việc", icon: "mdi-bank", route:{name:"OvertimeScaleShift"}, auth:"module:sysconfig" },
            { title: "Giới hạng tăng ca theo ca làm việc", icon: "mdi-bank", route:{name:"DefaultBookingShift"}, auth:"module:sysconfig" },
            ]},
        ],
      }
    },
    created(){
      this.$emit('updateSidebar', this.items);
    }
}
</script>