<template>
  
  <v-navigation-drawer v-model="drawer" 
  app floating
  width="350px"
  >
    <v-row
      class="fill-height"
      no-gutters
    >
      <v-navigation-drawer v-model="drawer" class="fill-height" height="1500px" disable-resize-watcher mobile-breakpoint="0" touchless color=""
        dark floating
        mini-variant-width="56"
        :mini-variant="mini"
        :expand-on-hover="expandOnHover"
      >
        <v-list-item class="px-2 pt-2">
          <v-list-item-avatar @click="$router.push({name:'Profile'})">
            <v-img  :src="!!user.avatar? user.avatar.includes('http')?user.avatar: $baseURL +user.avatar : '/static/icons/avatar_holder.png'"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            Xin chào {{user.employeeName}} 
          </v-list-item-content>
          <v-list-item-action>
            <!-- <v-icon @click="mini=true">mdi-arrow-left </v-icon> -->
          </v-list-item-action>
        </v-list-item>
        <v-btn color="transparent" width="100%" depressed style="height: 20px;" @click="mini = !mini"><v-icon left>mdi-dots-horizontal</v-icon></v-btn>

        <v-divider></v-divider>

        <v-list nav
          dense
        >
          <v-list-item link
            v-for="section in validSections"
            :key="section.title"
            :to="section.href"
            :exact="false"
          >
            <v-list-item-action>
              <v-icon :color="section.color">{{ section.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ section.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-list :class="`grow ${mini? '': 'd-none'} `" width="calc(100% - 60px)" dense id="main_menu">
        
        <v-list-item class="px-2" >
          <img src='/static/logo2.png' alt="logo" height="64" class="mx-auto" @click="$router.push({name: 'Home'})" style="cursor:pointer">
          <!-- <v-avatar :tile="true" height="56" @click="$router.push({name: 'Home'})" style="cursor:pointer" class="mx-auto">
            
          </v-avatar> -->
        </v-list-item>
        <!-- <v-toolbar-title>
        </v-toolbar-title> -->
          <div class="dynamic-list" v-for="(item, i) in items" :key="i">
            <v-subheader v-if="item.subheader">
              {{item.title}}
            </v-subheader>
            <v-list-item color="green darken-2" v-if="!item.subs && !item.subheader && authorize(item.auth) && (item.webAllowed || item.webAllowed==null)" link :to="item.route"  class="text-decoration-none">
              <v-list-item-icon >
                <v-icon color="teal darken-4">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title >
                    <v-badge inline class="my-auto"
                      color="error"
                      icon="mdi-lock"
                      :content="item.badge"
                      :value="!!item.badge"
                    >
                    {{ item.title }} 
                    </v-badge>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group v-if="item.subs && authorize(item.auth)" color="green darken-2" :group="item.group" no-action>
              <template v-slot:prependIcon>
                <v-icon color="teal darken-4">
                  {{item.icon}}
                </v-icon>
              </template>
              <template v-slot:activator>
                <v-list-item-content >
                  <v-list-item-title > {{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item color="green darken-2"
                v-for="(child, i) in item.subs"
                :key="i"
                link :to="child.route"  class="text-decoration-none" v-if="authorize(child.auth) ">
                <v-list-item-icon class="ml-n12">
                  <v-icon >mdi-circle-small</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title >
                    <v-badge inline  class="my-auto"
                      color="error"
                      icon="mdi-lock"
                      :content="child.badge"
                      :value="!!child.badge"
                    >
                    {{ child.title }}
                    </v-badge>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
      </v-list>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import axios from "axios";

export default {
name: "SideBar",
props: {
  value: { type: Boolean },
  items: { type: Array },
  validSections: { type: Array },
},

data() {
  return {
    claims: ["employee:index", "employee:create", "role:index"],
    drawer: false,
    // drawer1: true,
    mini:true,
    expandOnHover: true
  };
},
computed: {
  userClaims () {
    return localStorage.getItem("userClaim");
  },
  user () {
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    return user;
  },

  AuthorizeMenu () {
    
    if(!this.items)
    {
      return [];
    }
    var claims = this.userClaims;
    var result = this.items.map(obj => {
      if(obj.subs)
      {
        if(obj.authorize)
        {
          if(claims.includes(obj.authorize))
            return Object.assign({}, obj, {
              subs: obj.subs.filter(el => claims.includes(el.authorize))
            })
        }
        else {
          return Object.assign({}, obj, {
            subs: obj.subs.filter(el => claims.includes(el.authorize) || !el.authorize)
          })
        }
      }
      else {
        if(obj.authorize)
        {
          if(claims.includes(obj.authorize))
            return obj;
        } else return obj;
      }
    }).filter(u => u != undefined);
    return result;
  }
},
watch: {
  value: function () {
    this.localDrawer = this.value;
  },
  mini(value){
    console.log(value)
  }
},
methods:{

  authorize(value)
  {
    if(typeof value == 'object'){
      let result = true;
      value.forEach(x=> {
        result = result && this.userClaims.includes(x);
      })
      return result;
    }else{
      return this.userClaims.includes(value)
    }
  },
  openDrawer: function (val) {
    this.drawer = val;
    // this.$emit("input", this.localDrawer);
  },
},
mounted() {
  console.log("rendered");
  // console.log(this.userClaims);
  if (this.$vuetify.breakpoint.name === 'xs') {
    this.expandOnHover = false
    this.drawer = false
    this.mini = true
    console.log(this.mini)
  } else if (this.$vuetify.breakpoint.name === 'sm') {
    this.mini = true
    this.expandOnHover = false
    this.drawer=false
  } else if (this.$vuetify.breakpoint.name === 'md') {
    this.mini = true
    this.drawer=true
    // Do something when the viewport is in medium (md) breakpoint
  } else if (this.$vuetify.breakpoint.name === 'lg') {
    this.mini = true
    this.drawer=true
    // Do something when the viewport is in large (lg) breakpoint
  } else if (this.$vuetify.breakpoint.name === 'xl') {
    this.mini = true
    this.drawer=true
    // Do something when the viewport is in extra-large (xl) breakpoint
  }
},
created() {
  
}

};
</script>

<!-- <style scoped>
::v-deep #main_menu.v-list div.dynamic-list .v-list-item.v-list-item--active.v-list-item--link {
  background-color: #EBF8F4 !important;
  color: orange;
}
</style> -->
