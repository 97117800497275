<template>
<div>
    <!-- <side-bar ref="sideBar" :items="items"></side-bar> -->
    <v-main>
      <v-container style="min-height:calc(75vh - 124px)" class="mt-3">
        <v-fade-transition mode="out-in">
          <!--The Main Content component go here-->
          <router-view></router-view>
        </v-fade-transition>
      </v-container>
    </v-main>
</div>
</template>
<script>
import SideBar from "@/components/SideBar.vue";
export default {
    components:{
        SideBar
    },
    
    data(){
      return {
        items: [
          { title: "Tăng ca tối đa", icon: "mdi-image-filter-hdr", route:{name:"SalaryConfirmOvertimeBooking"}, auth: ["module:salary"]},
          { title: "Làm thêm", icon: "mdi-clock-alert", route:{name:"SalaryConfirmOvertime"}, auth: ["module:salary"]},
          { title: "Công tác", icon:"mdi-bus-side", route:{name:"SalaryConfirmBusiness"}, auth: ["module:salary"]},
          { title: "Đơn nghỉ phép", icon: "mdi-island", route:{name: 'SalaryConfirmLeave'}, auth: "module:salary"},
          { title: "Nghỉ dài hạn", icon: "mdi-car-estate", route:{name:"SalaryConfirmLongLeaveRequest"}, auth: ["module:salary"]},
          { title: "Giờ công và lương", subheader:true },
          { title: "Phản hồi lương", icon: "mdi-headset", route:{name:"SalarySupport"}, auth: ["sal:imp"]},
          { title: "Phản hồi x/n công", icon: "mdi-headset", route:{name:"TimesheetSupport"}, auth: ["sal:imp"]},
          { title: "Thông tin lương", icon: "mdi-upload", route:{name:"SalaryImport"}, auth: ["sal:imp"]},
          { title: "Chốt giờ công", icon: "mdi-checkbox-marked", route:{name: 'MonthlyTimeSheet'}, auth: "module:salary"},
          { title: "Giờ công", icon: "mdi-electron-framework", route:{ name: 'TimeSheet'}, auth: "module:salary"},
          { title: "Chấm công", icon: "mdi-clock-time-one", route:{ name: 'Attendance'}, auth: "module:salary"},
          { title: "Hệ số tăng ca", icon: "mdi-counter", route:{ name: 'OvertimeScale'}, auth: "module:salary"},
          { title: "Cài đặt", subheader:true },
          { title: "Hệ thống", icon: "mdi-cog-outline", route:{ name: 'SalarySetting'}, auth: "module:salary"},
          { title: "Lương theo chức vụ", icon: "mdi-cog-outline", route:{ name: 'SalaryConfig'}, auth: "module:salary"},
        ],
      }
    },
    
    created(){
      this.$emit('updateSidebar', this.items);
    },
}
</script>