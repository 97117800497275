import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import Swal from 'sweetalert2';
import DatePickerRange from '@/components/DatePickerRange.vue'
import MonthPicker from '@/components/MonthPicker.vue'
import Helper from '@/components/services/Helper'
import ApiService from '@/components/services/ApiServices'
import Timepicker from '@/components/TimePicker2.vue'

import CreateButton from '@/components/Crud/CreateButton.vue'
import EditButton from '@/components/Crud/EditButton.vue'
import DetailButton from '@/components/Crud/DetailButton.vue'
import DeleteButton from '@/components/Crud/DeleteButton.vue'
import UndoButton from '@/components/Crud/UndoButton.vue'
import FilterMenu from '@/components/Crud/FilterMenu.vue'
import ExcelButton from '@/components/Crud/ExcelButton.vue'
import ImportButton from '@/components/Crud/ImportButton.vue'
import RefreshButton from '@/components/Crud/RefreshButton.vue'
import ExportPrompt from '@/components/Crud/Prompt/ExcelPrompt.vue';
// import VueCompositionAPI from '@vue/composition-api';
// import { createPinia, PiniaVuePlugin } from 'pinia'


import NotificationHub from './notificationHub'

import VueExcelXlsx from "vue-excel-xlsx"

import VueQuillEditor from 'vue-quill-editor'
import CKEditor from '@ckeditor/ckeditor5-vue2';
// import CKEditor from '@ckeditor/ckeditor5-vue2'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import WebCam from 'vue-web-cam'

// import firebaseMessaging from './firebase';

// Vue.prototype.$messaging = firebaseMessaging

const EventBus = new Vue()
Vue.prototype.$eventBus = EventBus

import '../public/custom-style.css';
import '../public/scss/app.scss';
import firebaseMessaging from './firebase'

Vue.prototype.$messaging = firebaseMessaging

Vue.config.productionTip = false;

Vue.prototype.$helper = Helper;
Vue.prototype.$apiService = ApiService;
Vue.prototype.$Swal = Swal;
Vue.prototype.$baseURL = process.env.VUE_APP_BASE_URL;
Vue.prototype.$userClaims = () => {return JSON.parse(localStorage.getItem("user")).claims};

Vue.use( CKEditor );
Vue.use(VueQuillEditor, /* { default global options } */)
// Vue.use(VueCompositionAPI);
// Vue.use(PiniaVuePlugin);

Vue.use(NotificationHub)
// const pinia = createPinia()
Vue.use( CKEditor );

Vue.use(VueExcelXlsx);

Vue.use(WebCam)

//components
Vue.component('date-picker2', DatePickerRange);
Vue.component('month-picker', MonthPicker);
Vue.component('time-picker2', Timepicker);
Vue.component('edit-button', EditButton);
Vue.component('delete-button', DeleteButton);
Vue.component('detail-button', DetailButton);
Vue.component('excel-button', ExcelButton);
Vue.component('import-button', ImportButton);
Vue.component('refresh-button', RefreshButton);
Vue.component('create-button', CreateButton);
Vue.component('undo-button', UndoButton);
Vue.component('filter-menu', FilterMenu);

Vue.component('export-prompt', ExportPrompt);


// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('firebase-messaging-sw.js')
//     .then(reg => {
//       console.log(`Service Worker Registration (Scope: ${reg.scope})`);
//     })
//     .catch(error => {
//       const msg = `Service Worker Error (${error})`;
//       console.error(msg);
//     });
// } else {
//   // happens when the app isn't served over HTTPS or if the browser doesn't support service workers
//   console.warn('Service Worker not available');
// }

new Vue({
  router,
  vuetify,
  // pinia,
  render: h => h(App)
}).$mount('#app')



