<template>
    <div class="createRole">
        <v-dialog v-model="dialog" max-width="80vw">
            <v-card>
                <form @submit.prevent="createData" novalidate>
                    <v-card-title>
                    <span class="headline">{{formTitle}}</span>
                    </v-card-title>
                    <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                v-model="form.RoleName"
                                label="Tên quyền"
                                required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                v-model="form.Alias"
                                label="Alias"
                                required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                v-model="form.Description"
                                label="Mô tả"
                                ></v-text-field>
                            </v-col>
                            <v-subheader><strong>Phân quyền</strong></v-subheader>
                            <v-col cols="12">
                                <v-autocomplete
                                :items="formData.departments"
                                v-model="form.Managers" small-chips deletable-chips
                                label="Chi nhánh quản lý" multiple
                                item-value="code"
                                item-text="name"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-card-title>
                                DS Quyền 
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="permissionSearch"
                                    append-icon="mdi-magnify"
                                    label="Tìm kiếm"
                                    single-line
                                    hide-details
                                ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                show-select
                                hide-default-footer
                                :items-per-page=500
                                item-key="code"
                                v-model="form.Permissions"
                                :headers="permissionHeaders"
                                :items="permissions"
                                :search="permissionSearch"
                                ></v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                    >
                        Thoát
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        type="submit"
                    >
                        Lưu
                    </v-btn>
                    </v-card-actions>
                </form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common'

export default {
    name: "RoleCreateDialog",
    components: {

    },
    computed: {
        formTitle(){
            return this.isUpdate ? "Cập nhật phân quyền" : "Tạo mới phân quyền";
        },
        selectedCode () {
            var result =  this.form.Permissions.map(obj => {
                if(obj)
                    return obj.code;
            })
            return result;
        }
    },
    watch: {
        dialog (val) {
            if(val)
            {
                this.fetchPermissions();
                return val;
            }else
                this.close();
        },
        'form.Permissions' (val) {
            console.log(val)
        }
    },
    data () {
        return {
            DEPARTMENT_URL: "departments",
            permissionData:[],
            item: undefined,
            isUpdate: false,
            dialog: false,
            form: {
                RoleName:"",
                Alias: "",
                Description: "",
                Permissions: [],
                Managers:[],
            },
            defaultForm: {
                RoleName:"",
                Alias: "",
                Description: "",
                Managers:[],
                Permissions: []
            },
            formData: {
                Employees: [],
                departments:[],
                permissions: [
                    {
                        id: 0,
                        name: "Danh mục nhân viên",
                        children: [
                            {
                                id: "EMP-INDEX",
                                name: "Danh sách nhân viên"
                            },
                            {
                                id: "EMP-CREATE",
                                name: "Tạo mới nhân viên"
                            },
                            {
                                id: "EMP-EDIT",
                                name: "Chỉnh sửa nhân viên"
                            },
                        ]
                    }
                ]
            },

            //Permission
            permissionSearch: "",
            permissionHeaders: [
                {text: "Mã", value:"code"},
                {text: "Tên", value:"name"},
                {text: "Slug", value:"slug"},
                {text: "Nhóm slug", value:"groupSlug"},
            ],
            permissions: [],
        }
    },
    methods: {
        close () {
            this.dialog = false;
            this.isUpdate = false;
            this.form = Object.assign({}, this.defaultForm);
            this.permissions = [];
        },

        createData () {
            this.form.Permissions = this.selectedCode;
            if(!this.isUpdate)
                http.post('roles', this.form)
                .then(res => {
                    this.$parent.fetchData();
                    this.dialog=false;
                })
                .catch((err)=> {
                    console.log("err", err.response);
                })
            else{
                http.put('roles/' + this.item.code, this.form).then(res => {
                    this.dialog = false;
                    this.$parent.fetchData();
                }).catch( (err) => {
                    console.log(err.response.status);
                })
            }
        },

        fetchUpdate (code){
            http.get("roles/" + code).then(res => {
                let data = res.data;
                console.log(data)
                this.form.RoleName = data.role.roleName;
                this.form.Alias = data.role.alias;
                this.form.Permissions = data.permissions;
                this.form.Description = data.role.description;
            }).catch(err => {
                console.log(err);
            });
        },
                
        fetchDepartment() {
            return http.get(this.DEPARTMENT_URL + "?dropDown=1")
            .then(res => {
                this.formData.departments = res.data;
            })
            .catch(err => {
                console.log(err);
            })
        },

        fetchPermissions () {
            http.get("permissions").then( (res) => {
                let data= res.data.items;
                this.permissions = data;
                
                // this.formData.permissions = res.data.items.map(function(obj) {
                //     let first = obj[0];
                //     return {
                //         id: first.groupSlug,
                //         name: first.groupName,
                //         children: obj.map(function(obj1) {
                //             return {
                //                 name: obj1.name,
                //                 id: obj1.code,
                //                 }
                //             })
                //         }
                //     });
            });
        }
    },
    mounted() {
        this.fetchPermissions();
        this.fetchDepartment();
    }
}
</script>