var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.isEdit ? "Thay đổi" :"Tạo")+" hợp đồng "),_c('v-spacer'),_vm._v(" "),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){_vm.dialog=false}}},[_vm._v(" mdi-close")])],1),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"mt-6",attrs:{"id":"contractForm"},on:{"submit":function($event){$event.preventDefault();return _vm.saveData.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('date-picker2',{attrs:{"range":false,"label":"Ngày kí","outlined":"","dense":""},model:{value:(_vm.form.signDate),callback:function ($$v) {_vm.$set(_vm.form, "signDate", $$v)},expression:"form.signDate"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","offset":"0","offset-md":"2"}},[_c('v-text-field',{attrs:{"label":"Số hợp đồng","outlined":"","dense":""},model:{value:(_vm.form.contractNumber),callback:function ($$v) {_vm.$set(_vm.form, "contractNumber", $$v)},expression:"form.contractNumber"}}),_c('v-select',{attrs:{"label":"Trạng thái","outlined":"","dense":"","items":[
                            {text: 'Mới', value:'0'},
                            {text: 'Duyệt', value:'1'}
                        ],"hide-details":"","rules":[v=>(!!v||v==0)||'Nhập dữ liệu']},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"item-text":"name","item-value":"code","items":_vm.employees,"search-input":_vm.employeeSearch,"clearable":"","label":"Nhân viên","hide-no-data":"","rules":[v=>!!v||"Chọn dữ liệu"],"readonly":!!_vm.employee || _vm.isEdit},on:{"update:searchInput":function($event){_vm.employeeSearch=$event},"update:search-input":function($event){_vm.employeeSearch=$event},"change":_vm.fetchEmployee},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('strong',[_vm._v(_vm._s(data.item.code))]),_vm._v(" "+_vm._s(`: ${data.item.name} - ${data.item.department}`)+" ")]}},{key:"item",fn:function(data){return [_c('strong',[_vm._v(_vm._s(data.item.code)+" ")]),_vm._v(" "+_vm._s(`: ${data.item.name} - ${data.item.department}`)+" ")]}}]),model:{value:(_vm.form.employeeAcode),callback:function ($$v) {_vm.$set(_vm.form, "employeeAcode", $$v)},expression:"form.employeeAcode"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.contractTypes,"item-text":"name","item-value":"code","label":"Loại hợp đồng","rules":[v=>!!v||'Nhập dữ liệu']},model:{value:(_vm.form.contractTypeCode),callback:function ($$v) {_vm.$set(_vm.form, "contractTypeCode", $$v)},expression:"form.contractTypeCode"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('date-picker2',{attrs:{"range":false,"hideDetails":true,"label":"Ngày hết hạn","width":500},model:{value:(_vm.form.expireDate),callback:function ($$v) {_vm.$set(_vm.form, "expireDate", $$v)},expression:"form.expireDate"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"rules":[
                            v=>(v!=null || v != undefined) || 'Nhập dữ liệu',
                            v =>  !/\D/.test(v) || 'Dữ liệu không đúng' 
                        ],"label":"Lương","hint":_vm.currencyFormat(_vm.form.salary),"suffix":"vnd"},model:{value:(_vm.form.salary),callback:function ($$v) {_vm.$set(_vm.form, "salary", $$v)},expression:"form.salary"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":[
                                        {text: '1 Tháng', value:'1months'},
                                        {text: '1 Năm', value:'1years'},
                                        {text: '3 Năm', value:'3years'},
                                        {text: 'Không thời hạn', value:'nolimit'},
                                    ],"label":"Thời hạn hợp đồng"},model:{value:(_vm.form.lengthType),callback:function ($$v) {_vm.$set(_vm.form, "lengthType", $$v)},expression:"form.lengthType"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"label":"File pdf","accept":".pdf"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),(_vm.item && _vm.form.attachmentUrl)?_c('v-col',{attrs:{"cols":"12"}},[_c('a',{attrs:{"href":_vm.endpoint + _vm.form.attachmentUrl.replace('wwwroot/', ''),"target":"#"}},[_vm._v("Xem file PDF")])]):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"success","type":"submit","form":"contractForm"}},[_vm._v(" Lưu")]),_c('v-spacer')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }