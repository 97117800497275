<template>
    <div class="contract-index">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="dialog=true" ></myBreadCrumb> -->
        <create-dialog v-model="dialog"  :item="item" @fetchData="fetchData"></create-dialog>

        <v-card>
            <v-card-title>
                <!-- <span class="d-none d-sm-flex">
                    Danh sách hợp đồng
                </span> -->
                <date-picker2 v-model="dateRange" outlined dense :width="250" :hideDetails="true"></date-picker2>
                <v-spacer></v-spacer>

                <!-- <import-upload :url="IMPORT_URL" note="Các trường bắt buộc nhập trên TEMPLATE là [mã nhân viên, mã người ký, mã phòng]"></import-upload> -->
                <!-- #FILTER MENU -->
                <v-btn icon color="error" @click="warningDate" large class="rounded">
                    <v-icon>mdi-calendar-alert</v-icon>
                </v-btn>
                <refresh-button @click="fetchData"></refresh-button>
                <excel-button @click="exportPrompt=true" :timeLimit="true"/>
                <export-prompt url="contracts" v-model="exportPrompt"></export-prompt>
                <import-button @click="importPrompt=true" />
                <create-button @click="dialog=true"></create-button>
                <!-- <v-btn small class="mx-3" @click="exportExcel" color="teal" dark> <v-icon>mdi-database-export</v-icon> Xuất</v-btn> -->
                <!-- <data-filter2 :filterProps="filters2" :defaults="defaults" @fetchData="fetchData" >
                </data-filter2> -->
                
            </v-card-title>
            <v-data-table
                :mobile-breakpoint="0"
                height="600px" fixed-header
                :headers="headers"
                :items="items"
                :page.sync="tableParams.page"
                :sort-by.sync="tableParams.sortBy"
                :sort-desc.sync="tableParams.sortDesc"
                :server-items-length="rowCount"
                :loading="isLoading"
                loading-text="Đang tải dữ liệu..." >
                <template v-slot:[`body.prepend`]="" >
                    <tr class="filter">
                        <td></td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.code" outlined hide-details></v-text-field>
                        </td>
                        <td></td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.employeeCode" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-text-field placeholder="Nhập" dense v-model="filters.employeeName" outlined hide-details></v-text-field>
                        </td>
                        <td>
                            <v-autocomplete placeholder="Nhập" dense v-model="filters.departmentCode"
                            item-text="name" item-value="code" :items="departments" :search-input.sync="departmentSearch" hide-no-data clearable
                            @update:search-input="fetchDepartment" outlined hide-details></v-autocomplete>
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                            <date-picker2 placeholder="Nhập" v-model="filters.expire" dense outlined hide-details></date-picker2>
                        </td>
                        <td colspan="100%"></td>
                    </tr>
                </template>
                
                <template v-slot:[`header.expireDateStr`]="{header}" >
                    <v-badge color="error" :content="String(warning)">
                    {{header.text}}
                    </v-badge>
                </template>

                <template v-slot:[`item.actions`]="{item}" >
                    <edit-button @click="edit(item)"></edit-button>
                    <!-- <v-btn x-small @click="remove(item)" class="mx-1" color="error">
                        <v-icon small>mdi-trash-can</v-icon> Xóa
                    </v-btn> -->
                </template>
                <template v-slot:[`item.isCurrent`]="{item}" >
                    <v-icon v-if="item.isCurrent">mdi-check</v-icon>
                </template>
                
                <template v-slot:[`item.department`]="{ item }">
                    <v-chip class="ma-2 caption font-weight-bold" color="orange" label text-color="white" >
                        <v-icon left>
                            mdi-store
                        </v-icon>
                        {{item.department}}
                    </v-chip>
                </template>
                
                <template v-slot:[`item.time`]="{ item }" >
                    {{ formatTime(item.time) }}
                </template>
                <template v-slot:[`item.attachmentUrl`]="{ item }">
                    <v-btn v-if="item.attachmentUrl" color="red" icon :href="endpoint + item.attachmentUrl.replace('wwwroot/', '')" target="_blank"> <v-icon>mdi-file</v-icon></v-btn>
                    <!-- <a :href="endpoint + (item.attachmentUrl?item.attachmentUrl.replace('wwwroot/', ''):'')" target="#">Xem file PDF</a> -->
                </template>
            </v-data-table>
        </v-card>
        <!-- ************** DELETE CONFIRMATION *************** -->
        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                        <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="importPrompt" max-width="500px">
            <v-card>
                <v-card-title class="headline">Import dữ liệu</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-file-input v-model="template"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn @click="importing" color="teal">Nhập dữ liệu</v-btn>
                        <!-- <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                        <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn> -->
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import * as moment from "moment/moment";
import http from '@/components/services/http-common';
import ContractCreate from './Create.vue';
import DataFilter from '@/components/DataFilter.vue';
import DataFilter2 from '@/components/DataFilter2.vue';
import ImportUpload from '@/components/ImportUpload';
import ExcelButton from '../../../components/Crud/ExcelButton.vue';

export default {
    name: 'Contract',
    components: {
        "create-dialog": ContractCreate,
        'data-filter': DataFilter,
        'data-filter2': DataFilter2,
        'import-upload': ImportUpload,
        ExcelButton,
    },
    watch: {
        // options: {
        //     handler (val) {
        //         this.fetchData();
        //     },
        //     deep: true,
        // },
        dialog(val) {
            if(!this.dialog)
                this.item=null;
        },
        tableParams: {
            handler (val) {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler (val) {
                this.tableParams.page=1;
                this.fetchData();
            },
            deep: true,
        },
        dateRange:{
            handler(val){
                if(val.length=2){
                    this.filters.from = val[0];
                    this.filters.to = val[1];
                }
            }
        }
    },
    computed: {
        params: function() {
            return {...this.tableParams, ...this.filters}
        }
    },

    data() {
        return {
            CONTRACT_URL: "contracts",
            DEPARTMENT_URL: "departments",
            IMPORT_URL: "contracts/import",
            EXPORT_URL: "contracts/export",
            //HEADER DATA
            breadcrumbs: [
                {
                text: 'Trang chủ',
                disabled: false,
                to: {
                    name: "Home",
                    }
                },
                {
                text: 'Quản lí hợp đồng ',
                disabled: true,
                to: {
                    name: "Contract",
                    }
                },
            ],
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            endpoint: process.env.VUE_APP_BASE_URL,
            //TABLE DATA
            filterState:[],
            
            //filter
            tableParams: {
                sortBy: [],
                sortDesc: [],
                page: 1,
            },
            dateRange:[moment("1995-01-01", "YYYY-MM-DD").format("YYYY-MM-DD"), moment().endOf("month").format("YYYY-MM-DD")],
            filters:{
                code: null,
                employeeCode: null,
                employeeName: null,
                departmentCode: null,
                from: moment("1995-01-01").format("YYYY-MM-DD"),
                to: moment().endOf("month").format("YYYY-MM-DD"),
                expire: null,
            },
            departmentSearch:null,
            departments: [],
            rowCount: 0,
            warning: 0,

            headers: [
                {text:"", value: "actions", width:"7rem"},
                {text: "Số HĐ", value: "code", width: "9rem"},
                {text: "PDF", value: "attachmentUrl", },
                {text: "Mã nhân viên", value: "employeeAcode", width: "9rem"},
                {text: "Tên nhân viên", value: "employeeAName", width: "15rem"},
                {text: "Phòng/Chi nhánh", value: "department", width: "15rem"},
                {text: "Người ký", value: "createdByName", width: "9rem"},
                {text: "Ngày ký", value: "signDateStr", width: "9rem"},
                {text: "Ngày hết hạn", value: "expireDateStr", width: "9rem"},
                {text: "Loại hạn hợp đồng", value: "lengthType", width: "9rem"},
                {text: "Hiện tại", value: "isCurrent"},
                {text: "Ghi chú", value: "note", width: "15rem"},
            ],
            items: [],
            options: {},
            totalItem: 0,
            isLoading: true,

            //DIALOG
            dialog: false, item: null,
            dialogDelete: false,
            targetItem: {},

            exportPrompt: false,
            importPrompt:false,
            template: undefined,

        }
    },
    methods: {
        importing() {
            var formData = new FormData();
            formData.append("files", this.template);
            return http.post("contracts/import", formData);
        },
        formatDate(value) {
            return moment(value).format("DD/MM/YYYY");
        },
        //DATA JS
        fetchDepartment() {
            return http.get("departments/fetch", {
                params: {
                    search: this.departmentSearch
                }
            }).then(res => {
                this.departments = res.data;
            })
        },
        fetchData (e) {
            return http.get(this.CONTRACT_URL, {
                params: this.params
            })
            .then(res => {
                this.isLoading = false;
                this.items = res.data.items.results;
                this.rowCount = res.data.items.rowCount;
                this.warning = res.data.warning;
            }).catch(err => {})
        },

        //DIALOG METHOD
        openDialog() {
            this.params = {

            }
        },
        edit(item){
            this.item = item;
            this.dialog = true;
        },
        deleteItem (item) {
            this.targetItem = item;
            this.dialogDelete = true;
        },
        cfmDeleteItem() {
            http.delete(this.CONTRACT_URL + "/" +this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                switch (err.response.status)
                {
                    case 403:{
                        this.unauthorizeAlert();
                        this.dialogDelete = false;
                        break;
                    }
                    default:
                        break;
                }
            })
        },

        exportExcel() {
            let params =  { ...this.filterState, 
                                sortBy: this.options.sortBy, //array
                                sortDesc: this.options.sortDesc, //array
                                page: this.options.page,
                                };
            return http.get(this.EXPORT_URL, {
                params: params,
                responseType: 'arraybuffer',
            }).then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'danh_sach_ho_so.xlsx');
                document.body.appendChild(link);
                link.click();
            })
        },
        warningDate() {
            this.filters.expire=[moment().add(-7,"days").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")];
        }

    },
    created() {
        this.fetchDepartment();
        this.fetchData();
    }
}
</script>