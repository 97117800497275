import http from "./http-common";

export const BANK_GETS = 'banks';
export const MONTHLY_EMPLOYEE_GETS = "monthlyemployees";
export const CURRENT_EMPLOYEE = "CurrentEmployees";
export const ETHNICITY_GETS = "ethnicities";
export const ORIGIN_GETS = "origins";
export const RELIGION_GETS = "religions";

// global api
class ApiServices {
    fetchDepartment(value) {
        return http.get("departments/fetch", {
            params: {
                search: value
            }
        })
    }
    fetchPosition(value="") {
        return http.get("positions/fetch", {
            params: {
                search: value
            }
        })
    }

    getPosition(){
        return http.get("positions/fetch").then(r =>r.data)
    }
    
    getPositionState(id){
        return http.get("positions/fetch-position-state/"+id).then(r =>r.data)
    }
    
    fetchProperty() {
        return http.get("properties")
    }
    
    fetchEmployeeInDepartment(value) {
        const url = `departments/${value}/fetch-employee`
        return http.get(url, {
            params: {
                search: value
            }
        })
    }
    fetchEmployee(value){
        return http.get("employees/search", {
            params: {
                search: value
            }
        });
    }
    fetchLeavePlan(){
        return http.get("leaveplans");
    }
    
    fetchEducationLevel(){
        return http.get('educationLevels/fetch')
    }
    fetchUniversity(){
        return http.get('universities/fetch')
    }
    fetchEducationMajor(){
        return http.get('educationMajors/fetch')
    }
    fetchBank(){
        return http.get(BANK_GETS)
    }
    
    fetchEthnicity(){
        return http.get(ETHNICITY_GETS)
    }
    fetchOrigin(){
        return http.get(ORIGIN_GETS)
    }
    fetchReligion(){
        return http.get(RELIGION_GETS)
    }

    fetchMonthlyEmployee(month) {
        return http.get(MONTHLY_EMPLOYEE_GETS, {
            params: {
                month:month
            }
        });
    }
    
    fetchCurrentEmployee(filter) {
        return http.get(CURRENT_EMPLOYEE, {
            params: filter
        });
    }
}

export default new ApiServices();