<template>
    <div class="department">
        <!-- <myBreadCrumb :urls="JSON.stringify(breadcrumbs)" :icon="icon" @createForm="openDialog"></myBreadCrumb> -->
        <create-dialog ref="createDialog" @needFetch="fetchData"></create-dialog>
        <v-card>
            <v-card-title>
                Danh sách phòng ban
            <v-spacer></v-spacer>
            <excel-button @click="exportPrompt=true" :timeLimit="true"/>
            <export-prompt url="departments" fileName="PHONG_BAN" v-model="exportPrompt"></export-prompt>
            <!-- <import-upload :url="IMPORT_URL"></import-upload> -->
            <data-filter v-model="params" :filter="filter" @fetchData="fetchData">
            </data-filter>
            <create-button @click="openDialog"></create-button>
            </v-card-title>
            
            <v-data-table height="600px"
            :headers="headers"
            :items="departments"
            :loading="isLoading"
            loading-text="Đang tải dữ liệu..."
            >
                <template v-slot:[`body.prepend`]="">
                    <tr class="filter">
                        <td></td>
                        <td><v-select :items="departmentTypes" item-text="name" item-value="value" dense outlined hide-details v-model="filterDeptType"></v-select></td>
                        <td>
                            <v-text-field dense outlined hide-details v-model="filterCode"></v-text-field>
                        </td>
                        <td>
                            <v-text-field dense outlined hide-details v-model="filterName"></v-text-field>
                        </td>
                        <td>
                            <v-autocomplete item-text="name" item-value="code" :items="cities" clearable dense outlined hide-details v-model="filterCityCode"></v-autocomplete>
                        </td>
                        
                        <td>
                            <v-text-field dense outlined hide-details v-model="filterAsm"></v-text-field>
                        </td>
                    </tr>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <edit-button @click="editItem(item)"></edit-button>
                    <delete-button @click="deleteItem(item)"></delete-button>
                </template>
            </v-data-table>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Bạn có chắc muốn xoá dữ liệu này?</v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete=false">Thoát</v-btn>
                    <v-btn color="blue darken-1" text @click="cfmDeleteItem">OK</v-btn>
                    <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
// import myBreadCrumb from '@/components/BreadCrumb';
import DataFilter from '@/components/DataFilter';
import http from '@/components/services/http-common';
import DepartmentCreateDialog from './Create';
import ImportUpload from '@/components/ImportUpload';

export default {
    name: 'Department',
    components: {
        'data-filter': DataFilter,
        'create-dialog': DepartmentCreateDialog,
        'import-upload': ImportUpload,
    },
    data () {
        return {
            ROOT_URL: "departments",
            DEPARMENTTYPE_URL: "DepartmentTypes",
            IMPORT_URL: "departments/import",
            CITY_URL: "departments/fetch-city",

            //filter
            params: {
                sortBy: [],
                sortDesc: [],
                page: "",
                itemsPerPage: "",
                searchs: [],
                searchAttrs: [],
            },

            filter: {
                menu: false,
                search: "",
                searchAttr: "name",
                searchAttrs: undefined,
                FilterAttrs: [
                    {text: "Khối", items: [] },
                    {text: "Thành phố", items: [] },

                ],
                FilterOn:["DepartmentTypeCode", "City"],
                
                from: undefined,
                to: undefined,
                fromOns: [
                ],
                fromOn: "",
            },

            // VIEW DATA
            filterOpen: 1,
            icon:{
                icon: "mdi-plus-thick",
                to: ""
            },

            exportPrompt: false,
            //filter
            filterDeptType:null,
            cities:[],
            filterCode:null,
            filterName:null,
            filterLaunchDate:null,
            filterCityCode:null,
            filterAsm:null,
            departmentTypes : [],
            
            //datatable config
            totalItem: 0,
            departments: [],
            search:"",
            options: {},
            isLoading: true,
            dialog: false,
            dialogDelete: false,
            targetItem: undefined,
            headers: [
                { text: '', value: 'actions' },
                { text: 'Khối', align: 'start', value: 'deptType', filter: value => {
                    if (!this.filterDeptType) return true
                    return value.toUpperCase().indexOf(this.filterDeptType.toUpperCase()) > -1
                    }
                },
                { text: 'Mã phòng', align: 'start', sortable: false, value: 'code', filter: value => {
                    if (!this.filterCode) return true
                    return value.indexOf(this.filterCode) > -1
                    } },
                { text: 'Tên phòng ban', align: 'start', value: 'name', filter: value => {
                    if (!this.filterName) return true
                    return value.toUpperCase().indexOf(this.filterName.toUpperCase()) > -1
                    } 
                },
                { text: 'Vùng', value: 'city' },
                { text: '', value: 'cityCode', class: "d-none", cellClass:"d-none", filter: value => {
                    if (!this.filterCityCode) return true
                    return value == this.filterCityCode;
                    }
                },
                { text: 'Asm', value: 'asm', filter: value => {
                    if (!this.filterAsm) return true
                    return value.toUpperCase().indexOf(this.filterAsm.toUpperCase()) > -1
                    }
                },
                { text: 'Ngày khai trương', align: 'start', value: 'launchDate', filter: value => {
                    if (!this.filterCode) return true
                    return true;
                    } 
                },
            ],
        }
    },
    methods:{
        openDialog(){
            this.$refs.createDialog.dialog = true;
        },
        fetchData () {
            return http.get(this.ROOT_URL).then(res => {
                this.departments = res.data;
            }).finally(() => {
                this.isLoading = false;
            });
        },
        
        fetchDepartmentType() {
            return http(this.DEPARMENTTYPE_URL, {
                params: {dropDown: 1}
            })
            .then(res => {
                // this.filter.FilterAttrs[0]={text: "Khối", items: res.data };
                this.departmentTypes = res.data;
            })
            .catch(err => console.log(err))
        },
        
        // fetchCity() {
        //     return http(this.CITY_URL)
        //     .then(res => {
        //         this.filter.FilterAttrs[1]={text: "Thành phố", items: res.data };
        //     })
        //     .catch(err => console.log(err))
        // },
        fetchCity() {
            return http(this.CITY_URL)
            .then(res => {
                this.cities=res.data;
            })
            .catch(err => console.log(err))
        },

        editItem(item) {
            this.$refs.createDialog.isUpdate = true;
            this.$refs.createDialog.item = item;
            this.$refs.createDialog.fetchUpdate(item.code);
            this.$refs.createDialog.dialog = true;
        },
        deleteItem(item) {
            this.dialogDelete = true;
            this.targetItem = item;
        },
        cfmDeleteItem() {
            http.delete(this.ROOT_URL + "/"+this.targetItem.code).then(res => {
                this.fetchData();
                this.dialogDelete = false;
            }).catch(err => {
                console.log(err)
            })
        },
    },
    watch: {
      options: {
        handler (val) {
            this.fetchData();
        },
        deep: true,
      },
      search:{
          handler() {
            this.options.page = 1;
          }
      }
    },
    created() {
        this.fetchDepartmentType();
        this.fetchCity();
    },
    mounted () {
        this.fetchData();
    }
}
</script>

<style lang="stylus" scoped>

</style>