<template>
    <v-sheet class="chart-container" :style="`position: relative; height:${height}; width:${width}; max-width:${maxWidth}`">
        <canvas ref="chart"></canvas>
    </v-sheet>
</template>
<script>
import {Chart} from "chart.js";
export default {
    props:{
        chartType:String,
        chartData:Object,
        chartOptions:Object,
        scales:Object,
        width: String,
        height: String,
        maxWidth: String,
        label: String,
        ratio: Number,
        indexAxis: String
    },
    data(){
        return {
            chart:undefined
        }
    },
    methods:{
        chartConstructor(chartType, chartData, chartOptions) {
            const chartElement = this.$refs.chart;
            this.chart = new Chart(chartElement, {
                type: chartType,
                data: chartData,
                options: {
                    indexAxis: this.indexAxis??"x",
                    responsive: true,
                    aspectRatio: this.ratio,
                    scales: this.scales,
                    plugins: {
                        title: {
                            display: true,
                            text: this.label,
                            position: "bottom",
                            center:"center",
                            fullSize: true
                        },
                        legend: {
                            position: "left",
                        },
                    },
                    scales: this.scales
                },
            });
        },
        update(){
            this.chart.update();
        }
    },
    mounted(){
        let {chartType,chartData,chartOptions} = this;
        this.chartConstructor(chartType, chartData, chartOptions);
    }
}
</script>