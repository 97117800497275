<template>
<div>
    <v-main>
      <v-container style="min-height:calc(100vh - 124px)" class="mt-3">
        <v-fade-transition mode="out-in">
          <!--The Main Content component go here-->
          <router-view></router-view>
        </v-fade-transition>
      </v-container>
    </v-main>
</div>
</template>
<script>
import SideBar from "@/components/SideBar.vue";
export default {
    components:{
        SideBar
    },
    
    data(){
      return {
        overlay: true,
        items: [
            { title: "Tổng quan", icon: "mdi-chart-bell-curve", route:{name: "CandidateOverview"}, auth: "module:recruit"},
            { title: "Danh sách", icon: "mdi-bulletin-board", route:{ name: 'Candidate'}, auth: "module:recruit"},
            { title: "Công việc ứng tuyển", icon: "mdi-database", route:{name: "Job"}, auth: "module:recruit"},
            { title: "Yêu cầu tuyển dụng", icon: "mdi-database", route:{name: "HiringRequests"}, auth: "module:recruit"},
            { title: "Tiêu chí tuyển dụng theo asm", icon: "mdi-database", route:{name: "AsmBasePlan"}, auth: "module:recruit"},
        ],
      }
    },
    created(){
      this.$emit('updateSidebar', this.items);
    },
}
</script>