<template>
    <v-dialog v-model="dialog" max-width="600px">
        <v-card>
            <v-form v-model="isValid" ref="form" @submit.prevent="saveData" id="form" >
                <v-toolbar color="secondary" flat dark>
                    <v-toolbar-title>{{isEdit ? "Thay đổi" :"Thêm"}} ghi nhận công</v-toolbar-title>
                    <v-spacer></v-spacer> <v-icon small @click="dialog=false"> mdi-close</v-icon>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete item-text="name" item-value="code" :items="employees" v-model="form.employeeCode" :search-input.sync="employeeSearch"
                            label="Nhân viên" hide-no-data @change="fetchEmployee" :rules='[v=>!!v||"Chọn dữ liệu"]'>
                                <template v-slot:selection="data">
                                    <strong>{{ data.item.code }}</strong> {{`: ${data.item.name} - ${data.item.department}`}}
                                </template>
                                <template v-slot:item="data">
                                    <strong>{{ data.item.code }} </strong> {{`: ${data.item.name} - ${data.item.department}`}}
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete item-text="name" item-value="code" :items="departmentTypes" v-model="form.departmentTypeCode"
                            label="Khối" hide-no-data :rules='[v=>!!v||"Chọn dữ liệu"]'></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete item-text="name" item-value="code" :items="departments" v-model="form.departmentCode"
                            label="Phòng" hide-no-data :rules='[v=>!!v||"Chọn dữ liệu"]'></v-autocomplete>
                        </v-col>
                        <!-- VI TRI LAM VIEC -->
                        <v-col cols="12" md="6">
                            <v-autocomplete item-text="name" item-value="code" :items="positions" v-model="form.positionCode"
                            label="Vị trí" hide-no-data :rules='[v=>!!v||"Chọn dữ liệu"]'></v-autocomplete>
                        </v-col>
                        <!-- BAC NHAN VIEN -->
                        <v-col cols="12" md="6">
                            <v-autocomplete item-text="name" item-value="code" :items="positionStates" v-model="form.positionStateCode"
                            label="Bậc" :rules='[v=>!!v||"Chọn dữ liệu"]'></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete item-text="name" item-value="code" :items="shifts" v-model="form.shiftCode"
                            label="Ca" hide-no-data :rules='[v=>!!v||"Chọn dữ liệu"]'></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-autocomplete item-text="name" item-value="code" :items="scales" v-model="form.scaleCode"
                            label="Hệ số" hide-no-data :rules='[v=>!!v||"Chọn dữ liệu"]'></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-checkbox v-model="form.isCurrent" label="Hiện tại"></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="form.note" label="Ghi chú" outlined>
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="success" type="submit"> Lưu</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>

import http from '@/components/services/http-common';
import moment from 'moment/moment';
export default {
    props:["value", "item", "employee"],
    watch:{
        employee(val) {
            console.log("has employee");
        },
        employeeSearch:{
            handler(val){
                this.fetchEmployee(val);
            }
        },
        departmentSearch:{
            handler(val){
                this.fetchDepartment(val);
            }
        },
        value: {
            handler(val){
                if(val)
                {
                    this.fetchDepartmentType();
                    this.fetchScale();
                    if(this.employee){
                        this.fetchEmployee(this.employee);
                        this.form.employeeCode = this.employee;
                    }
                    if(this.isEdit)
                        this.fetch().then(()=>{
                            this.fetchDepartment(this.form.departmentTypeCode);
                            this.fetchPosition(this.form.departmentCode);
                            this.fetchPositionState(this.form.positionCode);
                            this.fetchShift(this.form.positionStateCode);
                            this.fetchData = false;
                        });
                }
                else{
                    this.close();
                }
            }
        },

        // FETCHING DATA
        "form.departmentTypeCode": function (val) {
            this.fetchDepartment(this.form.departmentTypeCode);
        },
        "form.departmentCode": function(val){
            this.fetchPosition(this.form.departmentCode);
        },
        "form.positionCode": function(val){
            this.fetchPositionState(this.form.positionCode);
        },
        "form.positionStateCode": function(val) {
            this.fetchShift(this.form.positionStateCode);
        },
    },
    computed: {
        dialog: {
            get: function () {
                return this.value;
            },
            set: function (val){
                this.$emit("input", val)
            }
        },
        isEdit() {
            return !!this.item;
        }
    },
    data() {
        return {
            ROOT_URL: "assignments/",
            EMPLOYEE_FETCH: "employees/search",
            DEPARTMENT_FETCH: "departmentTypes/fetch-department/",
            DEPARTMENT_TYPE_FETCH: "departmentTypes/fetch",
            POSITION_STATE_FETCH: "positions/fetch-position-state",
            SHIFT_FETCH: "positionStates/fetch-shift/",
            POSITION_FETCH: "departments/fetch-position/",
            SCALE_FETCH: "scales/fetch",

            isValid: true,
            departmentSearch: null,
            departmentTypes: [],
            positionStates: [],
            departments: [],
            shifts: [],
            positions: [],
            scales: [],
            employeeSearch: null,
            employees: [],
            fetchData: true,
            form: {
                date: moment().format("YYYY-MM-DD"),
                employeeCode:null,
                departmentTypeCode: null,
                positionStateCode: null,
                departmentCode: null,
                shiftCode: null,
                positionCode: null,
                scaleCode: null,
                isCurrent: true,
            },
            defaultForm:{
                date: moment().format("YYYY-MM-DD"),
                employeeCode:null,
                departmentTypeCode: null,
                positionStateCode: null,
                departmentCode: null,
                shiftCode: null,
                positionCode: null,
                scaleCode: null,
                isCurrent: true,
            }
        }
    },
    methods: {
        fetchEmployee(val) {
            return http.get(this.EMPLOYEE_FETCH).then(res => {
                this.employees = res.data;
            });
        },
        fetchDepartmentType() {
            console.log("fetch1");
            return http.get(this.DEPARTMENT_TYPE_FETCH).then(res => {
                this.departmentTypes = res.data;
            });
        },
        fetchDepartment(val) {
            console.log("fetch2", this.fetchData);
            if(!this.fetchData){
                this.departments = [];
                this.form.departmentCode=null;
                // this.positions = [];
                // this.positionCode=null;
            }
            return http.get(this.DEPARTMENT_FETCH+val).then(res => {
                this.departments = res.data;
            });
        },
        fetchPosition(val) {
            console.log("fetch3", this.fetchData);
            if(!this.fetchData)
            {
                this.positions=[];
                this.form.positionCode=null;
            }
            return http.get(this.POSITION_FETCH+val).then(res => {
                this.positions = res.data;
            });
        },
        
        fetchPositionState(val) {
            if(!this.fetchData)
            {
                this.positionStates=[];
                this.form.positionStateCode=null;
            }
            let url = `${this.POSITION_STATE_FETCH}/${val}`;
            return http.get(url).then(res => {
                this.positionStates = res.data;
            });
        },

        fetchShift(val) {
            if(!this.fetchData)
            {
                this.shifts=[];
                this.form.shiftCode=null;
            }
            return http.get(this.SHIFT_FETCH+val).then(res => {
                this.shifts = res.data;
            });
        },
        fetchScale() {
            return http.get(this.SCALE_FETCH).then(res => {
                this.scales = res.data;
            });
        },
        
        fetch(){
            return http.get(this.ROOT_URL+this.item.code).then(res => {
                this.form = Object.assign({}, res.data);
                this.fetchEmployee(this.form.employeeCode);
            })
        },
        saveData() {
            this.$refs.form.validate();
            if(this.isValid)
            {
                if(!this.isEdit){
                    return http.post(this.ROOT_URL, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }else {
                    return http.put(this.ROOT_URL+ this.item.code, this.form).then(res =>{
                        this.$emit("fetch");
                        this.dialog = false;
                    })
                }
            }
        },
        close(){
            this.form = Object.assign({}, this.defaultForm);
            this.$refs.form.resetValidation();
            this.fetchData=true;
        }
    },
    created() {
    }
    
}
</script>