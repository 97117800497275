<template>
    <div class="job-create">
        <v-dialog :value="value.state" @click:outside="close" @keydown.esc="close" max-width="700px">
            <v-form @submit.prevent="saveData" v-model="valid" ref="form">
                <v-card>
                    <v-card-title> {{titleText}} công việc ứng tuyển </v-card-title>
                    <v-card-text>
                        <v-row style="width: 70%" class="mx-auto">
                            <v-col>
                                <label for="name">Tên công việc</label>
                                <v-text-field id="name" v-model="form.name" solo outlined flat dense>
                                </v-text-field>
                                
                                <label for="note">Ghi chú</label>
                                <v-textarea id="note" v-model="form.note" solo outlined flat dense>
                                </v-textarea>
                                
                                <v-checkbox v-model="form.isActive" label="Kích hoạt"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn  color="primary" type="submit" dark small>Lưu dữ liện</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import http from '@/components/services/http-common';
export default {
    name: "Create",
    props: ["value"],
    computed:{
        titleText () {
            return this.value.item ? "Cập nhật" : "Tạp mới";
        }
    },
    watch: {
        value: {
            handler(val){
                if(val.item != undefined)
                    this.fetchData();
                if(val.state)
                    return val || this.close();
            },
            deep: true,
        },
    },
    data () {
        return {
            ROOT_URL: "Jobs",

            //FORMDATA
            valid: false,
            formData: { },
            form: {
                // code:"",
                name: "",
                note: "",
                isActive: true,
            },

            defaultForm: {
                // code:"",
                name: "",
                note: "",
                isActive: true,
            },
        }
    },
    methods: {
        close () {
            this.$emit("CloseDialog");
            this.$refs.form.resetValidation();
            this.form = Object.assign({}, this.defaultForm);
        },
        
        fetchData () {
            return http.get(this.ROOT_URL + "/" + this.value.item.code)
            .then(res => {
                let item = res.data;
                console.log(item);
                this.form = Object.assign({}, item);
            })
            .catch(err => {
                console.log(err);
            });
        },
        saveData() {
            let  isValid = this.$refs.form.validate();
            if(isValid)
            {
                if(!this.value.item)
                {
                    http.post(this.ROOT_URL, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                    });
                } else {
                    http.put(this.ROOT_URL + "/" + this.value.item.code, this.form)
                    .then(res => {
                        this.$emit("fetchData");
                    })
                    .catch(err => {
                        throw "Đã có lỗi xảy ra"
                    });
                }
                this.close();
            }
        },
    }
    
}
</script>