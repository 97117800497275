<template>
    <v-menu
    ref="menu"
    v-model="menu"
    :open-on-click="true"
    :close-on-content-click="false"
    :return-value.sync="inDate" offset-y bottom
    transition="scale-transition" nudge-bottom="0px"
    min-width="auto" max-width="290px" attach
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field :style="`max-width:${width}px;`" :disabled="disabled" :rules="rules" :class="inputClass"
            v-model="formatDate" :dense="dense" :outlined="outlined" clearable @click:clear="clear" color="teal"
            :label="label" :prepend-inner-icon="icon" :filled="filled" :readonly="readonlyInput"
             v-bind="attrs" v-on="on" :hide-details="hideDetails" placeholder="dd/mm/yyyy"
            ></v-text-field>
        </template>
        <v-date-picker :min="min" v-model="inDate" no-title :range="range" locale="vi" color="teal" show-adjacent-months dense z-index="0"
        :active-picker.sync="activePicker">
            <v-spacer></v-spacer>
            <v-btn text color="secondary" @click="menu = false" >
                Cancel
            </v-btn>
            <v-btn text color="orange"
            @click="apply" >
                OK
            </v-btn>
        </v-date-picker>
    </v-menu>
</template>
<script>
import * as moment from "moment/moment";
export default {
    name: "DatePickerRange",
    watch:{
        value: function (newVal, oldVal) {
            this.inDate = newVal;
        },
        
        menu (val) {
            if(this.birthDay)
                val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
    },
    props:{
        range:{
            default:true,
            type: Boolean
        },
        label:{
            type:[String, Boolean],
            default:"Chọn ngày"
        },
        value:[String, Array],
        icon:{
            type: String,
            default: "mdi-calendar"
        },
        inputClass:{
            type: String,
            default: ""
        },
        dense:Boolean,
        outlined:Boolean,
        hideDetails: Boolean,
        readonlyInput: {
            type:Boolean,
            default: false,
        },
        birthDay: {
            type:Boolean,
            default: false,
        },
        width: {
            type:Number,
            default: 200,
        },
        disabled:{
            type: Boolean,
            default: false,
        },
        filled:{
            type: Boolean,
            default: false,
        },
        rules:{
            type: Array,
        },
        readonly:{
            type: Boolean
        },
        min: {
            type: String,
            default: undefined
        }
    },
    data() {
        return {
            activePicker: false,
            menu:false,
            inDate: null,
        }
    },
    computed: {
        formatDate: {
            get: function () {
                if(Array.isArray(this.inDate))
                {
                    if(this.inDate.length==1)
                    {
                        var result = [];
                        result.push(moment(this.inDate[0]).format("DD/MM/YYYY"));
                        return result;
                    }
                    if(this.inDate.length==2)
                    {
                        var result = [];
                        result.push(moment(this.inDate[0]).format("DD/MM/YYYY"));
                        result.push(moment(this.inDate[1]).format("DD/MM/YYYY"));
                        return result;
                    }
                }else{
                    if(this.inDate==null)
                        return null;
                    else
                    {
                        if(this.inDate.length>=10){
                            return moment(this.inDate).format("DD/MM/YYYY")
                        }
                    }

                }
            },
            set: function (val){
                if(!this.range)
                {
                    let standardFormat = moment(val,"DD/MM/YYYY").format("YYYY-MM-DD");
                    if(val.length>=10)
                    {
                        this.$emit("input", standardFormat);
                        // this.inDate = standardFormat;
                    }
                }else {
                    let standardFormat = [];
                    if(val.length>=21)
                    {
                        standardFormat[0]= moment(val.split(",")[0],"DD/MM/YYYY").format("YYYY-MM-DD");
                        standardFormat[1]= moment(val.split(",")[1],"DD/MM/YYYY").format("YYYY-MM-DD");
                        this.$emit("input", standardFormat);
                        // this.inDate = standardFormat;
                    }
                }
                // this.$emit("input", val);
            }
        }
    },
    methods:{
        apply(){
            this.$refs.menu.save(this.inDate);
            this.$emit("input", this.inDate);
        },
        clear(){
            this.inDate=null
            this.$refs.menu.save(this.inDate);
            this.$emit("input", this.inDate);
        }
    },
    mounted(){
        this.inDate = this.value
    }
}
</script>
<style scoped>
::v-deep [type="date"]::-webkit-inner-spin-button {
  display: none;
}
::v-deep [type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}
</style>