<template>
<div>
    <!-- <side-bar ref="sideBar" :items="items"></side-bar> -->
    <v-main>
      <protocol-banner></protocol-banner>
      <v-container style="min-height:calc(100vh - 172px)" class="mt-3">
        <v-fade-transition mode="out-in">
          <!--The Main Content component go here-->
          <router-view></router-view>
        </v-fade-transition>
            <v-fab-transition>
              <v-menu top :offset-y="true" :close-on-content-click="false" :nudge-top="10">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="orange"
                    dark fixed
                    v-bind="attrs"
                    v-on="on"
                    bottom right fab >
                    <v-icon>mdi-message-reply</v-icon>
                  </v-btn>
                </template>
                <v-card width="200px">
                  <v-card-text>
                    <v-form @submit.prevent="sendFeedback">
                      <v-textarea label="Góp ý" color="orange" filled hide-details class="mb-3" v-model="feedBack.body">
                      </v-textarea>
                      <v-btn small right class="ml-auto" depressed color="teal" type="submit" dark> Gởi</v-btn>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-fab-transition>
      </v-container>
    </v-main>
</div>
</template>
<script>
import SideBar from "@/components/SideBar.vue";
import Protocol from "@/components/Banner/Protocol.vue";
import http from '@/components/services/http-common';
export default {
    components:{
        SideBar,
        "protocol-banner": Protocol
    },
    computed:{
      webAllowed() {
          let user = localStorage.getItem("user");
          let webAllowed = localStorage.getItem("WebAllowed");
          user = JSON.parse(user);
        return webAllowed.includes(user.employeeCode);
      },
      items(){
          
          let data= [
            { title: "Dashboard", subheader:true},
            { title: "Trang chính", icon: "mdi-view-dashboard-outline", route:{name:'Home'}, auth:'module:base' },
            { title: "Truyền thông", subheader:true},
            { title: "Tin tức", icon: "mdi-bullhorn-outline", route:{name:'News'}, auth:'module:base' },
            { title: "Khảo sát", icon: "mdi-vote-outline", route:{name:'SurveyEmployee'}, auth:'module:base'},
            { title: "Danh sách hỗ trợ", icon: "mdi-help-circle-outline", route:{name:"Support"}, auth:['module:base'], webAllowed: true },
            { title: "Thư viện tài liệu", icon: "mdi-bookshelf", route:{name:'ExternalFileManager'}, auth:'module:base'},
            // { title: "Hồ sơ", icon: "mdi-book-account", route:{name:'Profile'}, auth:'module:base'},
            { title: "Điểm danh & chấm công", subheader:true},
            { title: "Chấm công", icon: "mdi-briefcase-clock", route:{name:"Enrollment"}, auth:['module:base'], webAllowed: this.webAllowed},
            // { title: "Phiếu lương", icon: "mdi-bank", route:{name:'EmployeeSalary'}, auth:'module:base'},
            
            { title: "Đơn phiếu yêu cầu", subheader:true},
            // { title: "Tăng ca tối đa", icon: "mdi-clock-alert-outline", route:{name:"OvertimeBooking"}, auth:['module:base', 'otmax:rq'], webAllowed: this.webAllowed},
            { title: "Làm thêm", icon: "mdi-note-text-outline", route:{name:"OvertimeRequest"}, auth:'module:base', webAllowed: true},
            { title: "Công tác", icon: "mdi-note-text-outline", route:{name:"BusinessRequest"}, auth:'module:base', webAllowed: true },
            { title: "Nghỉ phép", icon: "mdi-note-text-outline", route:{name:"LeaveRequest"}, auth:'module:base', webAllowed: true },
            // { title: "Nghỉ dài hạn", icon: "mdi-car-estate", route:{name:"LongLeaveRequest"}, auth:'module:base', webAllowed: true },
            // { title: "Nghỉ việc", icon: "mdi-feather", route:{name:"QuitRequest"}, auth:'module:base', webAllowed: true },
            
            { title: "Mục khác", subheader:true},
            { title: "Đặt hàng", icon: "mdi-package-variant", route:{name:"BaseDatHang"}, auth:['cm:dathang'], webAllowed: true , badge: 'new'},
            { title: "Phiếu công cụ, dụng cụ", icon: "mdi-toolbox-outline", route:{name:"PropertyRequest"}, auth:['module:base'], webAllowed: true },
            { title: "Tiếp nhận phiếu cc/dc", icon: "mdi-car-estate", route:{name:"PropertyRequestConfirm"}, auth:['module:base', 'prop:receive'], webAllowed: true },
            { title: "B/C hình ảnh", icon: "mdi-camera-outline", route:{ name: 'BaseImageReport'}, auth: [ 'module:base', 'imgRep:index']},
            
          ]
        return data
      }
    },
    data() {
        return {
          feedBack: {
            body:"",
          }
        }
    },
    methods: {
      sendFeedback() {
        return http.post("feedbacks", this.feedBack).then(res => {
          console.log("feedback", res)
        })
      }
    },
    created(){
      this.$emit('updateSidebar', this.items);
    }
}
</script>